import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Grid, Typography, Box } from "@mui/material";
import "./index.css";
import BlogLogo from "./iottBlogLogo.png"; // Import the image file

const BlogHeader = () => {
  const Tabs = ["Movies", "Webseries", "Release Dates", "Contact Us"];
  const navigate = useNavigate();

  const handleClick = (tab) => {
    switch (tab) {
      case "Movies":
        navigate(`/blog/Movies`);
        break;
      case "Webseries":
        navigate(`/blog/Webseries`);
        break;
      case "Release Dates":
        navigate("/blog/recentreleases");
        break;
      case "Contact Us":
        navigate("/help");
        break;
      default:
        navigate("/");
    }
  };

  return (
    <Grid
      container
      xs={12}
      justifyContent={"space-between"}
      alignItems={"center"}
      borderBottom={"2px solid #000"}
    >
      <Grid item>
        <Box
          boxSizing={"border-box"}
          display={"flex"}
          alignItems="center"
          p={0}
        >
          <img
            src={BlogLogo}
            alt="Blog Logo"
            style={{ marginRight: "10px", height: "100px", width: "50px" }}
          />
          <Link to="/" className="logo-link">
            <Typography variant="h6" fontWeight={700}>
              IOTT
            </Typography>
            <Typography pl={2} variant="h6" fontWeight={700}>
              BLOG
            </Typography>
          </Link>
        </Box>
      </Grid>
      <Grid item display="flex">
        {Tabs.map((tab, index) => (
          <Typography
            key={index}
            onClick={() => handleClick(tab)}
            style={{ cursor: "pointer", margin: "0 10px" }}
          >
            {tab}
          </Typography>
        ))}
      </Grid>
    </Grid>
  );
};

export default BlogHeader;
