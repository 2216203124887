
import { useState, useEffect } from "react";
import logo from "./logo.png";
import appstore from "./appstore.png";
import playstore from "./playstore.png";
import youtube from "./youtube.png";
import twitter from "./twitter.png";
import telegram from "./telegram.png";
import instagram from "./instagram.jpg";
import facebook from "./facebook.png";
import { Box, Grid, Typography } from "@mui/material";
 
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
 
import "./index.css";
import { useNavigate } from "react-router-dom";
import {
  clearAllGeners,
  fetchByGenereDashboardBanners,
} from "../../Redux/reducers/bannersByGenereReducer";
import { useDispatch } from "react-redux";
import axios from "axios";
 
const Footer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [contactUs, setContactUs] = useState("");
 
  const onClickBlog = () => {
    const url = `${window.location.origin}/blog/Movies`;
    window.open(url, "_blank");
  };
  const onClickRefund = () => {
    window.scroll(0, 0);
    navigate("/refund");
  };
  const onClickAboutUs = () => {
    // window.scroll(0,0)
    navigate("/aboutUs");
  };
  const onClickFeedback = () => {
    window.scroll(0, 0);
    navigate("/feedback");
  };
  const onClickHelp = () => {
    window.scroll(0, 0);
    navigate("/help");
  };
  const onClickTermsAndConditions = () => {
    window.scroll(0, 0);
    navigate("/t&c");
  };
 
  const onClickPrivacyPolicy = () => {
    window.scroll(0, 0);
    navigate("/privacy&policy");
  };
 
  const handleGenerMovies = (gener) => {
    window.scrollTo(0, 0);
    dispatch(clearAllGeners());
    dispatch(fetchByGenereDashboardBanners(gener));
    navigate(`/category/${gener}`, { state: "generes" });
  };
 
  const onClickMvWatchBtn = (each) => { 
    const formattedMovieName = each?.MovieName.replace(/\s+/g, "_"); 
    navigate(`/details/${formattedMovieName}`, { state: { id: each?.Id }, replace: true }); 
  };
 
  function footerApi() {
    axios.get('https://api.iott.co.in/api/KnowIOTT/getContactUs')
      .then((response) => { if (response.status) {
        console.log(response.data)
        setContactUs(response.data);
      }})
      .catch((error) => {
        console.error("There was an error fetching the contact info!", error);
      });
  };
 
 
  useEffect(() => {
    footerApi();
  }, []);
 
  return (
    <>
      <Grid
        container
        borderTop={30}
        bgcolor={"#22263E"}
        borderColor={"#242A4A"}
      >
        <Grid
          item
          xs={12}
          display={"flex"}
          flexDirection={"row"}
          // justifyContent={"space-between"}
          alignItems={"flex-start"}
          sx={{ p: { xs: 0, sm: "none" }}}
        >
          <Grid
            item
            xs={6}
            sm={3}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            sx={{
              justifyContent: { xs: "space-between", sm: "space-evenly" },
              height: { xs: "20%" },
              mt:{xs:2}
            }}
            pl={{sm:'10%',md:0}}
          >
            <img
              src={"https://bucketblob.iott.co.in/Icons/logo.png" || logo}
              alt="logo"
              className="footer-logo"
            />
            <Typography
              pt={{xs:0,sm:2}}
              fontFamily={"Quicksand"}
              fontSize={{ xs: "12px", sm: "18px" }}
              fontWeight={700}
              textAlign={"center"}
              color={"#FFDA78"}
            >
              {" "}
              Follow Us
            </Typography>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mt={{xs:0,sm:1}}
              sx={{ gap: { xs: 0.5, sm: 2.5 } }}
            >
              <a
                href="https://www.facebook.com/IOTTIN"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={
                    "https://bucketblob.iott.co.in/Icons/facebook.png" ||
                    facebook
                  }
                  alt="facebook"
                  className="footer-facebook"
                />
              </a>
              <a
                href="https://www.instagram.com/iottapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={
                    "https://bucketblob.iott.co.in/Icons/instagram.jpg" ||
                    instagram
                  }
                  alt="instagram"
                  className="footer-instagram"
                />
              </a>
              {/* <a
                href="https://t.me/joinchat/EuVKNFZFfyVhMmE1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={
                    "https://bucketblob.iott.co.in/Icons/telegram.png" ||
                    telegram
                  }
                  alt="telegram"
                  className="footer-telegram"
                />
              </a> */}
              <a
                href="https://www.youtube.com/channel/UC1HpRtcUwDVuQ_KHSJFlw6Q"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={
                    "https://bucketblob.iott.co.in/Icons/youtube.png" || youtube
                  }
                  alt="youtube"
                  className="footer-youtube"
                />
              </a>
              {/* <a
                href="https://twitter.com/IOTTAPP"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={
                    "https://bucketblob.iott.co.in/Icons/twitter.png" || twitter
                  }
                  alt="twitter"
                  className="footer-twitter"
                />
              </a> */}
            </Box>
            <Box display={"flex"} flexDirection={"column"}>
              <Typography
                fontFamily="Quicksand"
                fontWeight={700}
                // fontSize={16}
                color="#FFDA78"
                mt={1}
                mb={{xs:0.5,sm:2}}
                textAlign="left"
                sx={{
                  fontSize: { xs: "12px", sm: "14px", md: "16px", lg: "16px" },
                }} 
                pl={{xs:1,sm:0}}
              >
                Download Mobile App
              </Typography>
 
              <Box
                className="footer-cards"
                display="flex"
                // justifyContent="space-between"
                alignItems="center"
                sx={{
                  flexDirection: { xs: "row" },
                  // gap: { sm: 2},
                  justifyContent: { xs: "center" },
                }} 
              >
                <a
                  href="https://play.google.com/store/apps/details?id=iott.co.in"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ cursor: "pointer", marginRight: 3 }}
                >
                  <img
                    src={
                      "https://bucketblob.iott.co.in/Icons/playstore.png" ||
                      playstore
                    }
                    alt="Play Store"
                    className="store-image"
                  />
                </a>
 
                {/* <a
                  href=""
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={
                      "https://bucketblob.iott.co.in/Icons/appstore.png" ||
                      appstore
                    }
                    alt="App Store"
                    className="store-image"
                  />
                </a> */}
              </Box>
            </Box>
          </Grid>
 
          <Grid
            item
            xs={3}
            sm={4}
            // display={"flex"}
            sx={{ display: { xs: "none", md: "flex" } }}
            flexDirection={"column"}
            // justifyContent={"center"}
            // alignItems={"center"}
            // m={'auto'}
            pl={{ sm: "7%" }}
          >
            <Typography
            pt={3}
              fontFamily={"Quicksand"}
              fontWeight={700}
              fontSize={20}
              mb={0.5}
              color={"#fff"}
            >
              Popular Genres
            </Typography>
 
            <Box mb={2} pl={{sm:'8%'}}>
              <Typography
                fontFamily={"Quicksand"}
                fontWeight={600}
                fontSize={16}
                color={"#FFDA78"}
                sx={{ cursor: "pointer" }}
                onClick={() => handleGenerMovies("Action")}
              >
                Action
              </Typography>
              <Typography
                fontFamily={"Quicksand"}
                fontWeight={600}
                fontSize={16}
                color={"#FFDA78"}
                sx={{ cursor: "pointer" }}
                onClick={() => handleGenerMovies("Devotional")}
              >
                Devotional
              </Typography>
              <Typography
                fontFamily={"Quicksand"}
                fontWeight={600}
                fontSize={16}
                color={"#FFDA78"}
                sx={{ cursor: "pointer" }}
                onClick={() => handleGenerMovies("Drama")}
              >
                Drama
              </Typography>
              <Typography
                fontFamily={"Quicksand"}
                fontWeight={600}
                fontSize={16}
                color={"#FFDA78"}
                sx={{ cursor: "pointer" }}
                onClick={() => handleGenerMovies("Sci-Fi")}
              >
                Sci-Fi
              </Typography>
              <Typography
                fontFamily={"Quicksand"}
                fontWeight={600}
                fontSize={16}
                color={"#FFDA78"}
                sx={{ cursor: "pointer" }}
                onClick={() => handleGenerMovies("Adventure")}
              >
                Adventure
              </Typography>
            </Box>
 
            <Box
              pt={6}
              width={"60%"}
              display={"flex"}
              flexDirection={"column"}
              // alignItems={"center"}
              pl={{sm:'8%'}}
            >
              <Typography
                fontFamily={"Quicksand"}
                fontWeight={700}
                // fontSize={14}
                color={"#FFDA78"}
                sx={{ fontSize: { xs: 13, sm: 16 } }}
                pb={1}
              >
                Contact Us
              </Typography>   
              <Box
                width={"30%"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                style={{ cursor: "pointer" }}
                gap={1}
               
              >
                <a href={`tel:${contactUs?.CallUs}`}>
                <FaPhoneAlt color="#fff" fontSize={{xs:'15px',sm:'20px'}} />
                </a>
                <a href={`mailto:${contactUs?.Email}`}>
                <IoMdMail color="#fff" fontSize={{xs:'18px',sm:'30px'}}/>
                </a>
              </Box>
            </Box>
          </Grid>
 
 
          <Grid sm={12} md={5}>
            <Grid
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
           
            >
              <Grid
                item
                // display={"flex"}
                sx={{ display: { xs: "none", sm: "flex" } }}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                // m={'auto'}
                pl={{sm:'18%',md:0}}
              >
                <Typography
                  fontFamily={"Quicksand"}
                  fontWeight={700}
                  fontSize={20}
                  mb={{sm:8,md:2}} 
                  color={"#fff"}
                >
                  Popular Movies
                </Typography>
 
                <Box mb={2}>
                  <Typography
                    fontFamily={"Quicksand"}
                    fontWeight={600}
                    fontSize={16}
                    color={"#FFDA78"}
                    onClick={() =>
                      onClickMvWatchBtn({ MovieName: "Premalu", Id: 3606 })
                    }
                    sx={{ cursor: "pointer" }}
                  >
                    Premalu
                  </Typography>
                  <Typography
                    fontFamily={"Quicksand"}
                    fontWeight={600}
                    fontSize={16}
                    color={"#FFDA78"}
                    onClick={() =>
                      onClickMvWatchBtn({ MovieName: "Indian 2", Id: 4059 })
                    }
                    sx={{ cursor: "pointer" }}
                  >
                    Indian 2
                  </Typography>
                  <Typography
                    fontFamily={"Quicksand"}
                    fontWeight={600}
                    fontSize={16}
                    color={"#FFDA78"}
                    onClick={() =>
                      onClickMvWatchBtn({ MovieName: "Lover", Id: 1995 })
                    }
                    sx={{ cursor: "pointer" }}
                  >
                    Lover
                  </Typography>
                  <Typography
                    fontFamily={"Quicksand"}
                    fontWeight={600}
                    fontSize={16}
                    color={"#FFDA78"}
                    onClick={() =>
                      onClickMvWatchBtn({ MovieName: "Fighter", Id: 1986 })
                    }
                    sx={{ cursor: "pointer" }}
                  >
                    Fighter
                  </Typography>
                  <Typography
                    fontFamily={"Quicksand"}
                    fontWeight={600}
                    fontSize={16}
                    color={"#FFDA78"}
                    onClick={() =>
                      onClickMvWatchBtn({
                        MovieName: "The Family Star",
                        Id: 3614,
                      })
                    }
                    sx={{ cursor: "pointer" }}
                  >
                    The Family Star
                  </Typography>
                </Box>
              </Grid>
 
              <Grid
                item
                // m={'auto'}
                sx={{ display: "flex" }}
                flexDirection={"column"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                pl={{sm:'10%',md:0}}
              >
                <Typography
                pt={3}
                  width={"100%"}
                  fontFamily={"Quicksand"}
                  fontWeight={700}
                  fontSize={{ xs: '15px', sm: 20 }}
                  mb={{xs:2}}
                  color={"#fff"}
                  textAlign={"center"}
                  mr={{ xs: 0, sm: 16 }}
                >
                  Learn More
                </Typography>
                <Box ml={{ xs: 5, sm: 0 }} mr={{sm:'10%'}}>
                  <Typography
                    fontFamily={"Quicksand"}
                    fontWeight={600}
                    // fontSize={16}
                    color={"#FFDA78"}
                    onClick={() => navigate(`/help`)}
                    sx={{ cursor: "pointer", fontSize: { xs: 13, sm: 16 } }}
                  >
                    Help
                  </Typography>
                  <Typography
                    fontFamily={"Quicksand"}
                    sx={{ cursor: "pointer", fontSize: { xs: 13, sm: 16 } }}
                    fontWeight={600}
                    // fontSize={16}
                    color={"#FFDA78"}
                    onClick={onClickFeedback}
                  >
                    Feedback
                  </Typography>
                  <Typography
                    fontFamily={"Quicksand"}
                    sx={{ cursor: "pointer", fontSize: { xs: 13, sm: 16 } }}
                    fontWeight={600}
                    // fontSize={16}
                    color={"#FFDA78"}
                    onClick={onClickTermsAndConditions}
                  >
                    Terms & Conditions
                  </Typography>
                  <Typography
                    fontFamily={"Quicksand"}
                    sx={{ cursor: "pointer", fontSize: { xs: 13, sm: 16 } }}
                    fontWeight={600}
                    // fontSize={16}
                    color={"#FFDA78"}
                    onClick={onClickPrivacyPolicy}
                  >
                    Privacy and Policy
                  </Typography>
                  <Typography
                    fontFamily={"Quicksand"}
                    sx={{ cursor: "pointer", fontSize: { xs: 13, sm: 16 } }}
                    fontWeight={600}
                    // fontSize={16}
                    color={"#FFDA78"}
                    onClick={onClickAboutUs}
                  >
                    About Us
                  </Typography>
                  <Typography
                    fontFamily={"Quicksand"}
                    sx={{ cursor: "pointer", fontSize: { xs: 13, sm: 16 } }}
                    fontWeight={600}
                    // fontSize={16}
                    color={"#FFDA78"}
                    onClick={onClickBlog}
                  >
                    Blog
                  </Typography>
                  <Typography
                    fontFamily={"Quicksand"}
                    sx={{ cursor: "pointer", fontSize: { xs: 13, sm: 16 } }}
                    fontWeight={600}
                    // fontSize={16}
                    color={"#FFDA78"}
                    onClick={onClickRefund}
                  >
                    Refund
                  </Typography>
                  <Box display={{xs:'block', md:'none'}}>
                  <Typography
                fontFamily={"Quicksand"}
                fontWeight={700}
                // fontSize={14}
                color={"#FFDA78"}
                sx={{ fontSize: { xs: 13, sm: 16 } }}
                pt={{xs:1.5, sm:0}}
                pb={1}
              >
                Contact Us
              </Typography>

              <Box
                width={"30%"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                style={{ cursor: "pointer" }}
                gap={1} 
              >
                <a href={`tel:+91${contactUs?.CallUs}`}>
                <FaPhoneAlt color="#fff" fontSize={{xs:'15px',sm:'20px'}} />
                </a>
                <a href={`mailto:${contactUs?.Email}`}>
                <IoMdMail color="#fff" fontSize={{xs:'18px',sm:'30px'}}/>
                </a>
              </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box pt={4} sx={{display:{xs:'none',md:'block'}}}>
              <Typography
                fontFamily={"Quicksand"}
                fontWeight={600}
                fontSize={14}
                sx={{ fontSize: { xs: 10, sm: 14 }, pt: { xs: 1 } }}
                color={"#FFDA78"}
                textAlign={{ xs: "center", sm: "left" }}
              >
                Copyright @2022 All rights reserved by Nithish Software
                Soluctions pvt.ltd
              </Typography>
            </Box>
          </Grid> 
        </Grid>
      </Grid>
    </>
  );
};
 
export default Footer;
 
 