import React from "react";
import {
  Container,
  Grid,
  Typography,
  Button,
  TextField,
  Box,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Link, useParams, useNavigate } from "react-router-dom";
import BlogHeader from "./BlogHeader";
import SearchIcon from "@mui/icons-material/Search";
import BlogFooter from "./BlogFooter";

export const posts = [
  {
    id: 1,
    title: "Review of Sitaramam",
    date: "June 1, 2024",
    content:
      "An in ‘Sita Ramam’ It is not wrong to love your country, but you don’t need to harbour such hatred for a  neighbouring country, a wise man tells a young woman in the film. In another scene, when she is given a few references, with all the names pertaining to one religion, she asks in exasperation if there isn’t anyone from her community. ",
    image:
      "https://s3-alpha-sig.figma.com/img/487f/fcbe/1654e456a62d8b2af3f6c74419b769ac?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=eqRSbYC5cXgxDu3ecGnDwarLzm1OeOWBfY8rQ-ubldZVtvXQiDmQGK1UuIyqCD2FghtcYPkoZvuvWgjRyaM3EZFwjiadKtb1MlQ~bmktywQEH2PTHq7I9ypz3ppt-99vP56GzwSlDi3me1pyOtesnqdX5X0onbSGYvh~BLuubkfYy3Gtz~frqnzKy5QDVQoVNeJ3osm~AR2vnkfZcLEqHImh0aoJR957pVEj~eTtFvCvItqv4WlFLt3eYqi4f2~-35JeTE4e2a3a1yK9PPZTnkIpZryuVqqf9tCg~sEuL8N-CAJTooADvk6jhncSd7tcMx-rHDCEBRFTMcVKOJX5Eg__",
  },
  {
    id: 2,
    title: "Review of LastPeg",
    date: "June 1, 2024",
    content:
      "Last Peg is a action thriller movie directed by Sanjay Vadat S. The movie cast includes Bharath Sagar and Yashaswini Ravindra are in the main lead roles along with Shankar Murthy S R, Kuri Pratap, Bank Janardhan, Vijay Chandur, Dharshan Varnekar are seen in supporting roles. The Music composed by Dr. K Lokesh while cinematography done by Kranthi Kumar Konidela and it is...",
    image:
      "https://s3-alpha-sig.figma.com/img/3196/24e8/869860fb406498752deba6490b10d67b?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=DTag~FB~lWzb0lokXZoEU2Jqxnjp-uYqwkCp01CGaFpDdxZ7f6WjvGg0RTvZq2p5Ta5Z3qrMnskYqC9T8kGaikEA4FwtcXuUa-9DmG0x0fDrG3XN3H~b7azQbvQ2gHSJL6sGdabHoA4Pj5GaW8RDe-cvUaLM7za-C9UWkW4trlLOXAOiRBwVNLXoo9dmTiahxhUrLxkYM2DiCsNLDA0DMF7i43oAdljLaBjmAShqwhL1yyGZvk0Smi89CBjs1HAHXBdBXa~pgHN~vVhZ1BTrRxuoRNw4GsvxVjDEiJN68uFjS21TeaLBltvIDe69xDnMtIxITDzdzrgADJtUFCE4wg__",
  },
  {
    id: 3,
    title: "Review of LastPeg",
    date: "June 1, 2024",
    content:
      "An in ‘Sita Ramam’ It is not wrong to love your country, but you don’t need to harbour such hatred for a  neighbouring country, a wise man tells a young woman in the film. In another scene, when she is given a few references, with all the names pertaining to one religion, she asks in exasperation if there isn’t anyone from her community. ",
    image:
      "https://s3-alpha-sig.figma.com/img/487f/fcbe/1654e456a62d8b2af3f6c74419b769ac?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=eqRSbYC5cXgxDu3ecGnDwarLzm1OeOWBfY8rQ-ubldZVtvXQiDmQGK1UuIyqCD2FghtcYPkoZvuvWgjRyaM3EZFwjiadKtb1MlQ~bmktywQEH2PTHq7I9ypz3ppt-99vP56GzwSlDi3me1pyOtesnqdX5X0onbSGYvh~BLuubkfYy3Gtz~frqnzKy5QDVQoVNeJ3osm~AR2vnkfZcLEqHImh0aoJR957pVEj~eTtFvCvItqv4WlFLt3eYqi4f2~-35JeTE4e2a3a1yK9PPZTnkIpZryuVqqf9tCg~sEuL8N-CAJTooADvk6jhncSd7tcMx-rHDCEBRFTMcVKOJX5Eg__",
  },
  {
    id: 4,
    title: "Review of LastPeg",
    date: "June 1, 2024",
    content:
      "Last Peg is a action thriller movie directed by Sanjay Vadat S. The movie cast includes Bharath Sagar and Yashaswini Ravindra are in the main lead roles along with Shankar Murthy S R, Kuri Pratap, Bank Janardhan, Vijay Chandur, Dharshan Varnekar are seen in supporting roles. The Music composed by Dr. K Lokesh while cinematography done by Kranthi Kumar Konidela and it is...",
    image:
      "https://s3-alpha-sig.figma.com/img/3196/24e8/869860fb406498752deba6490b10d67b?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=DTag~FB~lWzb0lokXZoEU2Jqxnjp-uYqwkCp01CGaFpDdxZ7f6WjvGg0RTvZq2p5Ta5Z3qrMnskYqC9T8kGaikEA4FwtcXuUa-9DmG0x0fDrG3XN3H~b7azQbvQ2gHSJL6sGdabHoA4Pj5GaW8RDe-cvUaLM7za-C9UWkW4trlLOXAOiRBwVNLXoo9dmTiahxhUrLxkYM2DiCsNLDA0DMF7i43oAdljLaBjmAShqwhL1yyGZvk0Smi89CBjs1HAHXBdBXa~pgHN~vVhZ1BTrRxuoRNw4GsvxVjDEiJN68uFjS21TeaLBltvIDe69xDnMtIxITDzdzrgADJtUFCE4wg__",
  },
];

const BlogHome = () => {
  const { category } = useParams();
  const navigate = useNavigate();
  // console.log(category);
  const handleClick = () => {
    navigate("/blog/details");
  };
  return (
    <Grid
      container
      xs={12}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      flexDirection={"column"}
      // p={2}
    >
      <Grid container xs={11} justifyContent={"space-between"}>
        <BlogHeader />
      </Grid>
      <Grid
        item
        display={"flex"}
        justifyContent={"space-between"}
        flexDirection={"column"}
        xs={10}
      >
        <Grid
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid>
            <Typography variant="h4" gutterBottom>
              {category} Reviews
            </Typography>
          </Grid>
          <Grid>
            <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-search">
                Enter Movie Name
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-search"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="Enter Movie Name"
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "20px" }}>
          <Grid item xs={12}>
            <img
              src={
                category === "Movies"
                  ? "https://s3-alpha-sig.figma.com/img/1d1f/18cd/bb4aff8143ed72e7d46271f34c8c428f?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=pofglF82gIWawMS7vfeqZOtL0B14tjLU3a7mQ3foLBQS-D5qKQs2ZHzbepnYptphNQKYRMpHH~KQJ9uYQYATUPgCcO5sOFoOmbVsNuv0FOs~c5kGytgGGwaGLLUV8a1CIGt0Dr35lEP0oRPFQdR69kzW87phx1OT2LrIiLa3a5AxQjkxUGKaDpvjuHBflkZ8spPN~cpflEDhfoKaLMFZAhZacc0dBqCG0XjVNCP7mlT~YQI0hLozrLs-Rn807x~gRSMoul94kZhILf0T~eIcwpsEt-HHKRJVIIy8sMbGOpNN1PsnWZdikJ0LTdotjSu9Ny56EGb1izzafEvW~7LTOA__"
                  : "https://s3-alpha-sig.figma.com/img/f5fc/0fa6/050783e90f0b0f988956a9c220c1f461?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=LdfScSnK0jqSNbIU4S~KutSf3O~rnd5fcc7sdARZ1mTWjHDa89HO-2OuYPRLGYcC8sgw6Rcm6p~DGXclBX~Yyxvh1fEU7zigg82vpa2jkHICjgRU7H4u1rGDdmmtOpIcv5WT0TqvMsGppDWkM~5Mn2SyuzhM~~xDDT4Yzn~ly09lbkdAsoe7PnU6qJlaCYi~itLvOg~rj2wNhIG4GRoQkzRx6kqxERVMyLK5MIIQFziH~W5adQK4a1p-6ixdUdHnPSZ50OtYu7NxnQjgrOO1S2Be~d6V6DawhtZ819~nsOrY306aivkN3Ybj-pYgWEX2jKgVLHQ0vBciMuX6ModYuw__"
              }
              alt="Main movie"
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "450px",
                objectFit: "fill",
                cursor: "pointer",
              }}
            />

            <Typography
              variant="h5"
              sx={{ cursor: "pointer", color: "#526CD0" }}
              component="div"
              my={5}
              onClick={() => handleClick()}
            >
              Review of Balagam Movie
            </Typography>
          </Grid>
          {posts.map((post) => (
            <Grid
              item
              display={"flex"}
              flexDirection={"column"}
              xs={12}
              mb={5}
              sx={{ cursor: "pointer" }}
              key={post.id}
            >
              <Grid
                container
                xs={12}
                justifyContent={"space-between"}
                height={"30%"}
              >
                <Grid item xs={3.5}>
                  <img
                    src={post.image}
                    alt={post.title}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                  />
                </Grid>
                <Grid item xs={8} height={"inherit"}>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant="h6" fontSize={"22px"} component="div">
                      {post.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      gutterBottom
                    >
                      {post.date}
                    </Typography>
                  </Box>
                  <Box pt={2}>
                    <Typography
                      fontSize={"20px"}
                      color="textSecondary"
                      display="block"
                      gutterBottom
                      style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: 3, // Limit to 4 lines
                      }}
                    >
                      {post.content}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <BlogFooter />
    </Grid>
  );
};

export default BlogHome;
