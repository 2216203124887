import React from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import BlogHeader from "./BlogHeader";
import { posts } from ".";
import BlogLogo from "./iottBlogLogo.png";
import BlogFooter from "./BlogFooter";

import { IoMdShare } from "react-icons/io";

const BlockDetailsPage = () => {
  return (
    <Grid
      container
      xs={12}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      // justifyContent={"space-between"}
      // flexDirection={"column"}
    >
      <Grid container xs={11}>
        <BlogHeader />
      </Grid>

      <Grid
        xs={10}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid>
          <Typography variant="h4" gutterBottom>
            Movie Reviews
          </Typography>
        </Grid>
        <Grid>
          <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-search">
              Search For Movie
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-search"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="Enter Movie Name"
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <img
          src="https://s3-alpha-sig.figma.com/img/1d1f/18cd/bb4aff8143ed72e7d46271f34c8c428f?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=pofglF82gIWawMS7vfeqZOtL0B14tjLU3a7mQ3foLBQS-D5qKQs2ZHzbepnYptphNQKYRMpHH~KQJ9uYQYATUPgCcO5sOFoOmbVsNuv0FOs~c5kGytgGGwaGLLUV8a1CIGt0Dr35lEP0oRPFQdR69kzW87phx1OT2LrIiLa3a5AxQjkxUGKaDpvjuHBflkZ8spPN~cpflEDhfoKaLMFZAhZacc0dBqCG0XjVNCP7mlT~YQI0hLozrLs-Rn807x~gRSMoul94kZhILf0T~eIcwpsEt-HHKRJVIIy8sMbGOpNN1PsnWZdikJ0LTdotjSu9Ny56EGb1izzafEvW~7LTOA__"
          alt="Main movie"
          style={{
            width: "100%",
            height: "auto",
            maxHeight: "500px",
            objectFit: "cover",
          }}
        />

        {/* </Grid> */}
      </Grid>
      <Grid
        item
        xs={10}
        display={"flex"}
        justifyContent={"space-between"}
        my={5}
        alignItems={"center"}
      >
        <Typography variant="h5">Review of Balagam Movie</Typography>
        <Typography fontSize={'30PX'}>
          {" "}
          <IoMdShare />
        </Typography>
      </Grid>
      <Grid xs={"10"}>
        <Typography fontSize={"16px"} fontFamily={"jost"}>
          Love Me, starring Ashish and Vaishnavi Chaitanya, has actually hit the
          displays today. This romantic thriller is guided by debutant Arun
          Bhimavarapu. Allow’s see just how the movie is. Watch Love me if you
          dare movie on IOTT.
        </Typography>
      </Grid>
      {/* </Grid> */}
      <Grid xs={"10"} marginTop={5} marginBottom={20}>
        <Typography fontSize={"16px"} fontFamily={"jost"}>
          Arjun (Ashish) is a YouTuber. He constantly looks for response to
          unsolved questions and does things that others ask him not to do.
          Arjun finds out there is a ghost named Divyavathi, and whoever tried
          to go near her lost their lives. Arjun loves the ghost and in the
          process of discovering what took place to Divyavathi, he encounters
          many surprises. That is this Divyavathi? What occurred to her? How did
          Prathap (Ravi Krishna) and Priya (Vaishnavi Chaitanya) assist Arjun in
          untangling the enigma surrounding Divyavathi? This is what the film
          has to do with.If your horror thriller lover. You should definelty
          watch this movie this weekend only on IOTT App.
        </Typography>
      </Grid>

      <BlogFooter />
    </Grid>
  );
};

export default BlockDetailsPage;
