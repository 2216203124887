import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes";
import endpoints from "../../../Endpoints/endpoints";

const initial_walletAmount = {
  wallet_amount: "",
  walletAmountFetchStatus: statusCodes.IDLE,
};

const getWalletAmountReducer = createSlice({
  name: "getWalletAmountReducer",
  initialState: initial_walletAmount,

  extraReducers: (builder) => {
    builder
      .addCase(fetchWalletAmount.pending, (state, action) => {
        state.walletAmountFetchStatus = statusCodes.LOADING;
        // console.log("wallet Amount data pending");
      })
      .addCase(fetchWalletAmount.fulfilled, (state, action) => {
        state.wallet_amount = action.payload.data.Amount;
        state.walletAmountFetchStatus = statusCodes.SUCCESS;
        // console.log(state.wallet_amount);
      })
      .addCase(fetchWalletAmount.rejected, (state, action) => {
        state.walletAmountFetchStatus = statusCodes.ERROR;
        // console.log("wallet Amount data error");
      });
  },
});

export const fetchWalletAmount = createAsyncThunk(
  "walletAmount/get",
  async (userId) => {
    // console.log("hiii");
    try {
      const walletAmount = await axios.get(`${endpoints.getWalletAmount}?userId=${userId}`);
      return walletAmount;
    } catch (error) {
      const errorPayload = {
        message: error.response.data.message,
      };
      throw errorPayload;
    }
  }
);

export default getWalletAmountReducer.reducer;
