import {
  Dialog,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { IoCloseCircleOutline } from "react-icons/io5";

const FollowersListDialog = ({
  followersDialogStatus,
  handleClickFollowers,
}) => {
  return (
    <Dialog
      open={followersDialogStatus}
      onClose={handleClickFollowers}
      maxWidth="sm"
      fullWidth
    >
      <TableContainer>
        <Table>
          <TableHead
            sx={{
              bgcolor: "#FFDA78",
              color: "#0A0A23",
              position: "sticky",
              top: 0,
              zIndex: 1000,
            }}
          >
            <TableRow>
              <TableCell sx={{ textAlign: "center", fontWeight: 800 }}>
                Followers
              </TableCell>
              <Grid container justifyContent={"center"}>
                <TableCell
                  sx={{
                    textAlign: "center",
                    fontWeight: 800,
                    marginLeft: "35%",
                  }}
                >
                  Date
                </TableCell>
                <IconButton
                  onClick={handleClickFollowers}
                  sx={{ marginLeft: "auto" }}
                >
                  <IoCloseCircleOutline />
                </IconButton>
              </Grid>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from({ length: 20 }, (_, index) => (
              <TableRow key={index}>
                <TableCell
                  sx={{
                    textAlign: "center",
                    color: "#000000",
                    fontWeight: "500",
                  }}
                >
                  Saurab22
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    color: "#000000",
                    fontWeight: "500",
                  }}
                >
                  15 May 2022
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Dialog>
  );
};

export default FollowersListDialog;
