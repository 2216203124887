import { Grid, Typography } from "@mui/material";
import React from "react";

const Refund = () => {
  return (
    <Grid p={4}>
      <Typography fontWeight={700}>Refunds (if applicable)</Typography>
      <Typography>
         Once your return is received and inspected, we
        will send you an email or call to notify you that we have received your
        returned item. We will also notify you of the approval or rejection of
        your refund. If approved, then your refund will be processed, and a
        credit will automatically be applied to your credit card or original
        method of payment, within 10 days.
      </Typography>
    </Grid>
  );
};

export default Refund;
