import React, { useState, useEffect, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import FitScreenOutlinedIcon from "@mui/icons-material/FitScreenOutlined";
import IconButton from "@mui/material/IconButton";
import { RiReplay10Line, RiForward10Line } from "react-icons/ri";
import { HiPlay } from "react-icons/hi";
import { BsCameraVideo } from "react-icons/bs";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import Popover from "@mui/material/Popover";
import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import PauseIcon from "@mui/icons-material/Pause";
import VolumeOffRoundedIcon from "@mui/icons-material/VolumeOffRounded";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ScreenRotationIcon from "@mui/icons-material/ScreenRotation";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import "./index.css";
import { MdOutlineSettings } from "react-icons/md";
import { CircularProgress } from "@mui/material"; 

const theme = createTheme();

theme.typography.subtitle2 = {
  fontSize: "1rem",
  color: "#ffda78",
  "@media (min-width:400px)": {
    fontSize: "1rem",
  },
  "@media (min-width:600px)": {
    fontSize: "1.4rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.6rem",
  },
};

const useStyles = makeStyles({
  controlsWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0,0,0,0.9)",

    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    zIndex: 1,
  },

  iconStyles: {
    color: "#ffda78",
    fontSize: 34,
  },

  bottomIcons: {
    color: "#ffffff",
    "&:hover": {
      color: "#777777",
    },
  },

  volumeSlider: {
    color: "#ffda78",
    width: 60,
  },
});

const PrettoSlider = styled(Slider)({
  color: "#ffda78",
  height: 4,
  width: "96%",
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#52af77",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

const PlayerControls = (props, ref) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorResEl, setAnchorResEl] = useState(null);
  const [resolution, setResolution] = useState(null);
  const [loading, setLoading] = useState(false); // State for the loader   
  const [selectedResolution, setSelectedResolution] = useState("");


  const handleRewind = () => {
    setLoading(true);
    // console.log('loader')
    onRewind();
    // Simulate a network delay
    setTimeout(() => setLoading(false), 2000);
  };

  const handleFastForward = () => {
    setLoading(true);
    onFastForward();
    // Simulate a network delay
    setTimeout(() => setLoading(false), 2000);
  };


  const [windowSize, setWindowSize] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const {
    onPlayPause,
    playing,
    onRewind,
    onFastForward,
    muted,
    onMute,
    onVolumeChange,
    onVolumeSeekUp,
    volume,
    playbackRate,
    onPlaybackRateChange,
    onToggleFullScreen,
    played,
    onSeek,
    onSeekMouseDown,
    onSeekMouseUp,
    elapsedTime,
    totalDuration,
    onChangeDisplayFormat,
    onClickResolution,
    screenRotation,
    data,
    movieIds, 
  } = props;

  const { MovieName, Id } = data;

  

  const handleWindowSize = () => {
    setWindowSize({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSize);

    return () => {
      window.removeEventListener("resize", handleWindowSize);
    };
  }, []);

  const handleBackArrow = (Id) => {
    const formattedMovieName = MovieName.replace(/\s+/g, "_");
    navigate(`/details/${formattedMovieName}`, { state: Id });
  };

  const onclickPlayBack = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "playBack-popover" : undefined;

  const onclickRes = (event) => {
    setAnchorResEl(event.currentTarget);
  };

  const handleCloseRes = () => {
    setAnchorResEl(null);
  };

  const openRes = Boolean(anchorResEl);
  const idRes = openRes ? "res-popover" : undefined;


  const playbackIcons = [0.5, 1, 1.5, 2]; 

  const handleResolutionClick = (resolution, event,value) => { 

    setSelectedResolution(value);
    setSelectedResolution(resolution);
 
    event.stopPropagation(); // Prevent the popover from closing immediately
    if (onClickResolution) {
      onClickResolution(resolution); // Call the resolution change handler
    } else {
      console.error('onClickResolution is not defined');
    }
    handleCloseRes(); // Close the Popover
  };

  const renderResolutionOptions = () => {
    const resolutions = [
      { label: "480p", value: "480p" },
      { label: "720p", value: "720p" },
      { label: "1080p", value: "1080p" },
      { label: "4K", value: "4K" },
    ];
 
    return resolutions.map((res) => (
      <Button
        key={res.value}
        variant="outlined"
        onClick={(event) => handleResolutionClick(res.value, event)}
        sx={{ 
          color: "#000", 
          bgcolor: selectedResolution === res.value ? "yellow" : "transparent",
          "&:hover": {
            bgcolor: selectedResolution === res.value ? "yellow" : "rgba(0, 0, 0, 0.1)"
          }
        }}
      >
        {res.label}
      </Button>
    ));
  };
 

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className={classes.controlsWrapper} ref={ref}>
          <Grid
            container
            direction="row"
            bgcolor={"#000"}
            alignItems="flex-start"
            justifyContent="space-between"
            style={{ padding: 16 }}
          >
            <IconButton className="icons" onClick={() => handleBackArrow(Id)}>
              <ArrowBackIosNewIcon
                fontSize="small"
                className={classes.iconStyles}
              />
            </IconButton>

            <Typography variant="h6" sx={{ color: "#ffffff", fontSize: {xs:'20px'} }}>
              {MovieName}
            </Typography>

            <Grid item display={"flex"} flexDirection={"column"}>
              {windowSize.winWidth < 992 && (
                <IconButton className="icons">
                  <ScreenRotationIcon className="screen-rotation-icon" />
                </IconButton>
              )}
              <IconButton onClick={onToggleFullScreen} className="icons">
                <FitScreenOutlinedIcon
                  fontSize="large"
                  className={classes.iconStyles}
                />
              </IconButton>
              <img
                src={"https://bucketblob.iott.co.in/Icons/logo.png"}
                alt="iott-logo"
                className="logo-video-player"
              />
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              minWidth={200}
              maxWidth={300}
            >
              <IconButton
                onClick={onRewind}
                aria-label="rewind"
                className="icons"
              >
                 {loading ? (
                  <CircularProgress size={24} className={classes.loader} />
                ) : (
                  <RiReplay10Line className={classes.iconStyles} />
                )}
              </IconButton>
              <IconButton onClick={onPlayPause} className="icons">
                {playing ? (
                  <PauseCircleOutlineOutlinedIcon
                    style={{ fontSize: 40 }}
                    className={classes.iconStyles}
                  />
                ) : (
                  <HiPlay className={classes.iconStyles} />
                )}
              </IconButton>
              <IconButton
                onClick={onFastForward}
                aria-label="forward"
                className="icons"
              >
                 {loading ? (
                  <CircularProgress size={24} className={classes.loader} />
                ) : (
                  <RiForward10Line className={classes.iconStyles} />
                )}
              </IconButton>
            </Grid>
          </Grid>

          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                marginBottom: 0,
                justifyContent: "center",
                display: "flex",
                padding: "10px",
              }}
            >
              <Slider
                min={0}
                max={100}
                size="small"
                value={played * 100}
                aria-label="Small"
                valueLabelDisplay="auto"
                valueLabelFormat={elapsedTime} // Show elapsed time in label
                onChange={onSeek}
                onMouseDown={onSeekMouseDown}
                onChangeCommitted={onSeekMouseUp}
                sx={{ color: "#ffda78" }}
              />
            </Grid>

            <Grid
              container
              bgcolor={"#000"}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ padding: 16  }}
              sx={{ marginTop: 0 }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid item xs={2} md={6}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton onClick={onPlayPause} className="icons">
                      {playing ? (
                        <PauseIcon
                          fontSize="small"
                          className={classes.iconStyles}
                        />
                      ) : (
                        <PlayArrowIcon
                          fontSize="small"
                          className={classes.iconStyles}
                        />
                      )}
                    </IconButton>
                    <IconButton onClick={onMute} className="icons">
                      {muted ? (
                        <VolumeOffRoundedIcon
                          fontSize="small"
                          className={classes.iconStyles}
                        />
                      ) : (
                        <VolumeUpIcon
                          fontSize="small"
                          className={classes.iconStyles}
                        />
                      )}
                    </IconButton>
                    <Grid item>
                      <div className={classes.volumeSlider}>
                        <Slider
                          min={0}
                          max={100}
                          size="small"
                          value={volume * 100}
                          aria-label="Small"
                          valueLabelDisplay="auto"
                          onChange={onVolumeChange}
                          onChangeCommitted={onVolumeSeekUp}
                          sx={{ color: "#ffda78" }}
                        />
                      </div>
                    </Grid>
                    <Button
                      onClick={onChangeDisplayFormat}
                      variant="text"
                      sx={{ color: "#fff", flexShrink: 0 }}
                    >
                      <Typography variant="subtitle1">
                        {elapsedTime}/{totalDuration}
                      </Typography>
                    </Button>
                  </Box>
                </Grid>
{/* 
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    // marginLeft: "auto",
                  }}
                > */}
                  <Grid  display={'flex'} flexDirection={'row'} >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="text"
                        onClick={onclickRes}
                        sx={{ color: "#ffffff" }}
                      >
                        <BsCameraVideo className="videoIcon icons" />
                      </Button>
                      <Popover
                        id={idRes}
                        open={openRes}
                        anchorEl={anchorResEl}
                        onClose={handleCloseRes}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        style={{ zIndex: 1300 }}
                        transition
                        // className={classes.popoverContainer} 
                      >
                        <Grid container direction="column" style={{ padding: 0 }}>
                          {renderResolutionOptions()}
                        </Grid>
                      </Popover>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        onClick={onclickPlayBack}
                        variant="text"
                        className={classes.bottomIcons}
                        sx={{ color: "#ffffff" }}
                      >
                        <MdOutlineSettings className="icons" />
                      </Button>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        style={{ zIndex: 1300 }}
                        transition
                        className={classes.popoverContainer}
                      >
                        <Grid
                          container
                          bgcolor={"#000"}
                          direction="column"
                          style={{ padding: 16 }}
                        >
                          {playbackIcons.map((icon) => (
                            <Button
                              onClick={() => onPlaybackRateChange(icon)}
                              variant="text"
                              key={icon}
                              className={classes.bottomIcons}
                            >
                              <Typography
                                color={
                                  icon === playbackRate
                                    ? "secondary"
                                    : "default"
                                }
                                className={classes.rateText}
                              >
                                {icon}X
                              </Typography>
                            </Button>
                          ))}
                        </Grid>
                      </Popover>
                    </Box>
                  </Grid>

                  {/* <Grid item  md={6}>
                    
                  </Grid> */}

                {/* </Box> */}
              </Grid>
            </Grid> 
          </Grid>
        </div>
      </ThemeProvider>
    </>
  );
};

export default forwardRef(PlayerControls);
