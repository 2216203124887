import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../utils/statusCodes";
import endpoints from "../../Endpoints/endpoints"; 

const getBannerImagesApi = endpoints.getBannerImages

const initial_banners = { dashboard_banners: [], dashboardBannerFetchStatus: statusCodes.IDLE };

const dashboardBannersReducer = createSlice({
  name: "dashboardBannersReducer",
  initialState: initial_banners,
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeDashboardBanners.pending, (state, action) => {
        state.dashboardBannerFetchStatus = statusCodes.LOADING;
        // console.log("dashboard banners data pending");
      })
      .addCase(fetchHomeDashboardBanners.fulfilled, (state, action) => {
        state.dashboard_banners = action.payload.data;
        state.dashboardBannerFetchStatus = statusCodes.SUCCESS;
        // console.log(state.dashboard_banners);
      })
      .addCase(fetchHomeDashboardBanners.rejected, (state, action) => {
        state.dashboardBannerFetchStatus = statusCodes.ERROR;
        // console.log("dashboard banners data error");
      });
  },
});


export const fetchHomeDashboardBanners = createAsyncThunk("homeDashboardBanners/get", async () => {

 
  try {
    const banners = await axios.get(getBannerImagesApi);
    return banners;
  } catch (error) {
    const errorPayload = {
      message: error.response.data.message,
    };
    throw errorPayload;
  }


});

export default dashboardBannersReducer.reducer;
