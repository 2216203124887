import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes"; 
import endpoints from "../../../Endpoints/endpoints"; 

const addFavoriteApi = endpoints.addFavorite

const initial_addFavorite = {
  addFavorite: [],
  addFavoriteFetchStatus: statusCodes.IDLE,
  
};

export const fetchAddFavorite = createAsyncThunk(
  "addFavorite/post",
  async (addFavoriteJson) => {
    try {
      const addFavoriteMovie = await axios.post(
        addFavoriteApi,
        addFavoriteJson
      );
      return addFavoriteMovie;
    } catch (error) {
      const errorPayload = {
        message: error.response.data,
      };
      // console.log(error.response.data);
      throw errorPayload; // Throwing the error payload
    }
  }
);

const addFavoriteReducer = createSlice({
  name: "addFavoriteReducer",
  initialState: initial_addFavorite,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddFavorite.pending, (state, action) => {
        state.addFavoriteFetchStatus = statusCodes.LOADING;
       })
      .addCase(fetchAddFavorite.fulfilled, (state, action) => {
        state.addFavorite = action.payload.data;
        state.addFavoriteFetchStatus = statusCodes.SUCCESS;
       })
      .addCase(fetchAddFavorite.rejected, (state, action) => {
         state.addFavoriteFetchStatus = statusCodes.ERROR;
       });
  },
});

export default addFavoriteReducer.reducer;
