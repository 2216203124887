import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes"; 
import endpoints from "../../../Endpoints/endpoints"; 

const addWalletAmountApi = endpoints.addWalletAmount

const initial_addWalletAmount = {
  add_wallet_amount: {},
  addWalletAmountFetchStatus: statusCodes.IDLE,
  
};

export const fetchAddWalletAmount = createAsyncThunk(
  "addWalletAmount/post",
  async (addWalletAmountJSON) => {
    try {
      const addWalletAmount = await axios.post(
        addWalletAmountApi,
        addWalletAmountJSON
      );
      return addWalletAmount;
    } catch (error) {
      const errorPayload = {
        message: error.response.data,
      };
      // console.log(error.response.data);
      throw errorPayload; // Throwing the error payload
    }
  }
);

const addWalletAmountReducer = createSlice({
  name: "addWalletAmountReducer",
  initialState: initial_addWalletAmount,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddWalletAmount.pending, (state, action) => {
        state.addWalletAmountFetchStatus = statusCodes.LOADING;
       })
      .addCase(fetchAddWalletAmount.fulfilled, (state, action) => {
        state.add_wallet_amount = action.payload.data;
        state.addWalletAmountFetchStatus = statusCodes.SUCCESS;
       })
      .addCase(fetchAddWalletAmount.rejected, (state, action) => {
         state.addWalletAmountFetchStatus = statusCodes.ERROR;
       });
  },
});

export default addWalletAmountReducer.reducer;


 