import { Box, Button, Container, Grid, List, ListItem } from "@mui/material";
import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";
 
const HowtoEarn = () => {

const navigate = useNavigate();


 const handleClosebtn = ()=>{
  navigate(-1)
 }
  return (
    <>
      <Header
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
        }}
      />
      <Grid pt={5} bgcolor={"#010113"} xs={10} sx={{ p: { xs: 0 } }}>
        <Container>
          <Grid
            sx={{
              height: "auto",
              width: "90vw",
              padding: "10%",
            }}
          >

            <Box
              sx={{
                bgcolor: "#FFDA78",
                border: "2px solid white",
                padding: "15px",
                borderRadius: "5px",
              }}
            >
              <Button onClick={handleClosebtn}
              sx={{
                position: "absolute", 
                // top: "20px",
                right: "200px", 
                minWidth: "auto", 
                padding: "5px", 
                fontWeight:"800",
                fontSize:"15px",
              }}
              
              >x</Button>
              <h2>How to Earn?</h2>
              <List sx={{ marginLeft: "10px" }}>
                <ListItem sx={{ display: "list-item", fontWeight: 500 }}>
                  Welcome to the IOTT Refer and Earn Program! Here’s how you can
                  earn money by referring others to our subscription plans:
                </ListItem>
              </List>
 
              <h3>How It Works</h3>
              <List sx={{ marginLeft: "10px", paddingLeft: "20px" }}>
                <ListItem sx={{ display: "list-item", fontWeight: 500 }}>
                  <b>1. Refer Someone:</b>
                  <br />
                  <p sx={{ paddingLeft: "20px", listStyleType: "circle" }}>
                    Share your referral link with friends, family, or anyone
                    interested in our subscription plans.
                  </p>
                </ListItem>
                <ListItem sx={{ display: "list-item", fontWeight: 500 }}>
                  <b>2. Earn at Three Levels:</b>
                  <List sx={{ marginLeft: "20px", listStyleType: "circle" }}>
                    <ListItem sx={{ display: "list-item", fontWeight: 500 }}>
                      <b>Level 1 Earnings:</b> When someone subscribes using
                      your referral link, you earn 20% of the subscription plan
                      amount they pay. The person you referred also benefits
                      from a discount or offer based on our referral terms.
                    </ListItem>
                    <ListItem sx={{ display: "list-item", fontWeight: 500 }}>
                      <b>Level 2 Earnings:</b> When the person you referred
                      refers someone else, you earn 20% of the 20% commission
                      you got previously in level 1. The new referrer earns 20%
                      of the subscription plan amount they referred.
                    </ListItem>
                    <ListItem sx={{ display: "list-item", fontWeight: 500 }}>
                      <b>Level 3 Earnings:</b> When the second-level referrer
                      refers another person, you earn 20% of the 20% commission
                      you earned in level 2. The third-level referrer earns 20%
                      of the subscription plan amount they referred.
                    </ListItem>
                  </List>
                </ListItem>
              </List>
 
              <h3>Example:</h3>
              <List sx={{ marginLeft: "10px", paddingLeft: "20px" }}>
                <ListItem sx={{ display: "list-item", fontWeight: 500 }}>
                  <b>1. You Refer a Friend (Level 1):</b> Your friend
                  subscribes, and you earn 20% of their subscription amount.
                </ListItem>
                <ListItem sx={{ display: "list-item", fontWeight: 500 }}>
                  <b>2. Your Friend Refers Another Person (Level 2):</b> You
                  earn 20% of the 20% commission you earned in the previous
                  level.
                </ListItem>
                <ListItem sx={{ display: "list-item", fontWeight: 500 }}>
                  <b>3. The Second Person Refers Yet Another Person (Level 3):</b>{" "}
                  You earn 20% of the 20% commission that you earned in the
                  previous level.
                </ListItem>
              </List>
 
              <h3>Withdrawal Process:</h3>
              <List sx={{ marginLeft: "10px", paddingLeft: "20px" }}>
                <ListItem sx={{ display: "list-item", fontWeight: 500 }}>
                  <b>Minimum Balance:</b> You can only withdraw money once your
                  balance reaches ₹100.
                </ListItem>
                <ListItem sx={{ display: "list-item", fontWeight: 500 }}>
                  <b>Active Subscription:</b> To withdraw funds, you need to be
                  an active subscriber of any of our subscription plans.
                </ListItem>
                <ListItem sx={{ display: "list-item", fontWeight: 500 }}>
                  <b>Withdrawal:</b> Go to the ‘Withdraw’ section in the app to
                  request your withdrawal.
                </ListItem>
              </List>
 
              <h3>Important Notes:</h3>
              <List sx={{ marginLeft: "10px" }}>
                <ListItem sx={{ display: "list-item", fontWeight: 500 }}>
                  Main Referrer: You do not need to maintain an active
                  subscription to earn referral commissions, but you must have
                  an active subscription to withdraw your earnings.
                  <p>
                    Referrals: Each level of referrer must have an active
                    subscription plan for the referral chain to continue.
                  </p>
                </ListItem>
              </List>
            </Box>
          </Grid>
        </Container>
      </Grid>
      <Footer />
    </>
  );
};
 
export default HowtoEarn;
 
 