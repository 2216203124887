import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";

const SeoMetaDetails = ({ movieId, movieName }) => {
  const [seoData, setSeoData] = useState({
    title: "",
    description: "",
    keywords: "",
  });

  useEffect(() => {
    const fetchSeoData = async () => {
      try {
        const response = await axios.get(
          `https://api.iott.co.in/api/videos/getSEOMetaData?movieId=${movieId}`
        ); 
        const { Title, Description, Keywords } = response.data;
        setSeoData({
          title: Title || `${movieName} - IOTT`,
          description: Description,
          keywords: Keywords,
        });
      } catch (error) {
        console.error("Error fetching SEO data:", error);
      }
    };

    fetchSeoData();
  }, [movieId, movieName]);

  return (
    <Helmet>
      <title>{seoData.title || `${movieName} - IOTT`}</title>
      {seoData.description && (
        <meta name="description" content={seoData.description} />
      )}
      {seoData.keywords && <meta name="keywords" content={seoData.keywords} />}
    </Helmet>
  );
};

export default SeoMetaDetails;
