import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes";
import endpoints from "../../../Endpoints/endpoints";

const initial_favorites = {
  get_all_favorites: [],
  getAllFavoritesFetchStatus: statusCodes.IDLE,
};

const getAllFavoritesReducer = createSlice({
  name: "getAllFavoritesReducer",
  initialState: initial_favorites,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllFavorites.pending, (state, action) => {
        state.getAllFavoritesFetchStatus = statusCodes.LOADING;
        // console.log("get all favorites data pending");
      })
      .addCase(fetchAllFavorites.fulfilled, (state, action) => {
        state.get_all_favorites = action.payload.data;
        state.getAllFavoritesFetchStatus = statusCodes.SUCCESS;
        // console.log(state.get_all_favorites);
      })
      .addCase(fetchAllFavorites.rejected, (state, action) => {
        state.getAllFavoritesFetchStatus = statusCodes.ERROR;
        // console.log("get all favorites  data error");
      });
  },
});

export const fetchAllFavorites = createAsyncThunk(
  "getAllFavorites/get",
  async (userid) => {
    try {
      const allFavorites = await axios.get(
        `${endpoints.getCollection}?userid=${userid}`
      );
      return allFavorites;
    } catch (error) {
      const errorPayload = {
        message: error.response.data.message,
      };
      throw errorPayload;
    }
  }
);

export default getAllFavoritesReducer.reducer;
