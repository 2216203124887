import React from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import BlogHeader from "./BlogHeader";
import { posts } from ".";
import BlogLogo from "./iottBlogLogo.png";
import BlogFooter from "./BlogFooter";

const RecentReleases = () => {
  return (
    <Grid
      container
      xs={12}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      flexDirection={"column"}
      // p={2}
    >
      <Grid container xs={11} justifyContent={"space-between"}>
        <BlogHeader />
      </Grid>
      <Grid
        item
        display={"flex"}
        justifyContent={"space-between"}
        flexDirection={"column"}
        xs={10}
      >
        <Grid
          container
          justifyContent={"space-between"}
          py={4}
          alignItems={"center"}
        >
          <Grid>
            <Typography variant="h4" gutterBottom>
              Movie Reviews
            </Typography>
          </Grid>
          <Grid>
            <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-search">
                Enter Movie Name
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-search"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="Enter Movie Name"
              />
            </FormControl>
          </Grid>
        </Grid>

        {posts.map((post) => (
          <Grid
            item
            display={"flex"}
            flexDirection={"column"}
            xs={12}
            mb={5}
            key={post.id}
          >
            <Grid container xs={12} justifyContent={"space-between"}>
              <Grid item xs={4}>
                <img
                  src={post.image}
                  alt={post.title}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "10px",
                  }}
                />
              </Grid>
              <Grid item xs={7}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography variant="h6" fontSize={"22px"} component="div">
                    {post.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    {post.date}
                  </Typography>
                </Box>
                <Box pt={2}>
                  <Typography
                    fontSize={"20px"}
                    color="textSecondary"
                    display="block"
                    gutterBottom
                  >
                    {post.content}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <BlogFooter />
    </Grid>
  );
};

export default RecentReleases;
