import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes";
import endpoints from "../../../Endpoints/endpoints";


const getAllLanguageReducer = createSlice({
  name: "getAllLanguageReducer",
  initialState: { allLanguage: [] },
  extraReducers: (builder) => {
    builder
      .addCase(getAllLanguageFunction.pending, (state, action) => {
        // console.log("subscriptionCheck pending");
      })
      .addCase(getAllLanguageFunction.fulfilled, (state, action) => {
        state.allLanguage = action.payload.data;
        // console.log(state.allLanguage);
      })
      .addCase(getAllLanguageFunction.rejected, (state, action) => {
        state.suggestedMoviesFetchStatus = statusCodes.ERROR;
        // console.log("subscriptionCheck error");
      });
  },
});

export const getAllLanguageFunction = createAsyncThunk(
  "getAllLanguage/get",
  async (userId) => {
    try {
      const response = await axios.get(
        `${endpoints.getAllLanguages}`
      );
      return response;
    } catch (error) {
      const errorPayload = {
        message: error.response.data.message,
      };
      throw errorPayload;
    }
  }
);

export default getAllLanguageReducer.reducer;
