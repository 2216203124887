import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import { useEffect, useRef, useState } from "react";
import { fetchVideoById, setMovieState } from "../../../Redux/reducers/videos/getVideoByIdReducer";
import { AppBar, CircularProgress, Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PlayerControls from "../PlayerControls";
import screenfull from "screenfull";
import { useLocation } from "react-router-dom";
import "./index.css";
import statusCodes from "../../../utils/statusCodes";
import { fetchAddWatchHistory } from "../../../Redux/reducers/watchHistory/addWatchHistoryReducer";
import { getAuthData } from "../../../utils/auth";
 
const useStyles = makeStyles({
  // playerWrapper: {
  //     width: "100%",
  //     display: "flex",
  //     justifyContent: "center",
  //     alignItems: "center",
  //     height: "100vh",
  //     backgroundColor:"#000"
  // },
 
  playerWrapper: {
    width: "100%",
  },
 
});
 
const format = (seconds) => {
  if (isNaN(seconds)) {
    return "00:00";
  }
 

  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
 
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  return `${mm.toString().padStart(2, "0")}:${ss}`;
};
 
let count = 0;
 
const VideoPlayer = () => {
  const authData = getAuthData();
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [isBuffering, setIsBuffering] = useState(false);
 
  const playerState = useSelector(
    (state) => state.rootReducer.playerStateReducer
  );
  // console.log(playerState);
 
  const {  videoById, videoByIdFetchStatus } = useSelector(
    (state) => state.rootReducer.getVideoByIdReducer
  );
 
  // const categoryValue = movieState?.Category;
  // const movieId = movieState?.Id;
 
  // const categoryValue = videoById[0]?.Category;
  // const movieId = videoById[0]?.Id;
  const categoryValue = videoById.length > 0 ? videoById[0]?.Category : null;
  const movieId = videoById.length > 0 ? videoById[0]?.Id : null;
 
  // useEffect(() => {
  //   if (videoById.length === 0) {
  //     const fetchVideoByIdJSON = { fetchedMovieId: id, categoryValue: categoryValue };
  //     dispatch(fetchVideoById(fetchVideoByIdJSON));
  //   }
  // }, [dispatch, id, categoryValue, videoById]);
 
  


////////////////// anil update this functionality ////////////////////////

useEffect(() => {
  const fetchData = async () => {
    try {
      const fetchVideoByIdJSON = { fetchedMovieId: id, categoryValue: categoryValue };
      setLoading(true); // Show loader while fetching
      await dispatch(fetchVideoById(fetchVideoByIdJSON));
      setLoading(false);
    } catch (error) {
      console.error("Network error:", error);
      setLoading(false); // Hide loader if there is an error
    }
  };

  if (videoById.length === 0) {
    fetchData();
  }
}, [dispatch, id, categoryValue, videoById]);

///////////////////////////////////////////////////////////////







  // useEffect(() => {
  //   const fetchVideoByIdJSON = {
  //     fetchedMovieId: movieId,
  //     categoryValue: categoryValue,
  //   };
  //   dispatch(fetchVideoById(fetchVideoByIdJSON));
  // }, [dispatch, id]);
 
  // useEffect(() => {
  //   // Initialize state from localStorage if available
  //   const storedVideoById = JSON.parse(localStorage.getItem("videoById"));
  //   if (storedVideoById) {
  //     dispatch(setMovieState({ videoById: storedVideoById }));
  //   } else {
  //     const fetchVideoByIdJSON = { fetchedMovieId: movieId, categoryValue: categoryValue };
  //     dispatch(fetchVideoById(fetchVideoByIdJSON));
  //   }
  // }, [dispatch, id, movieId, categoryValue]);
 
  useEffect(() => {
    const addWatchHistoryJson = {
      UserId: authData?.auth?.Id,
      MovieId: id,
    };
    dispatch(fetchAddWatchHistory(addWatchHistoryJson));
  }, [id]);
 
  const classes = useStyles();
  const playerRef = useRef(null);
  const playerContainerRef = useRef(null);
  const controlsRef = useRef(null);
 
  const movieData =
    playerState &&
    playerState.seasonNo !== null &&
    playerState.episodeNo !== null
      ? videoById[0]?.Seasons[playerState.seasonNo]?.Episodes[
          playerState.episodeNo
        ]?.EpisodeUrl
      : videoById[0]?.Id === 1884
      ? videoById[0]?.Quality1080
      : videoById[0]?.Quality720;
 
  const [state, setState] = useState({
    playing: false,
    muted: false,
    volume: 0.5,
    playbackRate: 1.0,
    played: 0,
    seeking: false,
    screenRotation: false,
  });
 
  const [timeDisplayFormat, setTimeDisplayFormat] = useState("normal");
 
  const [volumeValue, setVolumeValue] = useState(null);
 
  useEffect(() => {
    document.addEventListener("keydown", triggeringKeys, true);
    return () => document.removeEventListener("keydown", triggeringKeys);
  });
 
  const handleVolumeChange = (e, newValue) => {
    setState({
      ...state,
      volume: parseFloat(newValue / 100),
      muted: newValue === 0 ? true : false,
    });
    setVolumeValue(parseFloat(newValue / 100));
  };
 
  const { playing, muted, volume, playbackRate, played, seeking } = state;
 
  const triggeringKeys = (e) => {
    if (e.key === "ArrowLeft") {
      playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
    } else if (e.key === "ArrowRight") {
      playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
    }
  };
 
  const handlePlayPause = () => {
    setState({ ...state, playing: !state.playing });
  };
 
  const handleRewind = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
  };
 
  const handleFastForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
  };
 
  const handleMute = () => {
    setState({ ...state, muted: !state.muted });
  };
 
  const handleVolumeSeekUp = (e, newValue) => {
    setState({
      ...state,
      volume: parseFloat(newValue / 100),
      muted: newValue === 0 ? true : false,
    });
  };
 
  const handlePlaybackRateChange = (rate) => {
    setState({ ...state, playbackRate: rate });
  };
 
  const handleOnToggleFullScreen = () => {
    screenfull.toggle(playerContainerRef.current);
  };
 
  const handleProgress = (changeState) => {
    if (count > 1) {
      controlsRef.current.style.visibility = "hidden";
      count = 0;
    }
 
    if (controlsRef.current.style.visibility === "visible") {
      count += 1;
    }
 
    if (!state.seeking) {
      setState({ ...state, ...changeState });
    }

    if (playerRef.current) {
      const duration = playerRef.current.getDuration();
      if (duration === 0 || isNaN(duration)) {
        setLoading(true);
      } else {
        setLoading(false);
      }
    }
  };
 
  const handleSeekChange = (e, newValue) => {
    setState({ ...state, played: parseFloat(newValue / 100) });
  };
 
  const handleSeekMouseDown = (e) => {
    setState({ ...state, seeking: true });
  };
 
  const handleSeekMouseUp = (e, newValue) => {
    setState({ ...state, seeking: false });
    playerRef.current.seekTo(
      (newValue / 100) * playerRef.current.getDuration()
    );
    // setLoading(true); // Show loader when seeking
  };
 
  const handleChangeDisplayFormat = () => {
    setTimeDisplayFormat(
      timeDisplayFormat === "normal" ? "remaining" : "normal"
    );
  };
 
  //   const handleMouseMove = () => {
  //     controlsRef.current.style.visibility = "visible";
  //     count = 0;
  //   };
  const handleMouseMove = () => {
    if (controlsRef.current) {
      controlsRef.current.style.visibility = "visible";
      count = 0;
    }
  };
  // const currentTime = playerRef.current ? playerRef.current.getCurrentTime() : "00:00";
  // const duration = playerRef.current ? playerRef.current.getDuration() : "00:00";
 
  // const elapsedTime = timeDisplayFormat === "normal"
  //     ? format(currentTime)
  //     : `-${format(duration - currentTime)}`;
  // const totalDuration = format(duration);
 
  const currentTime = playerRef.current
    ? playerRef.current.getCurrentTime()
    : "00:00";
  const duration = playerRef.current
    ? playerRef.current.getDuration()
    : "00:00";
 
  const elapsedTime =
    timeDisplayFormat === "normal"
      ? format(currentTime)
      : `-${format(duration - currentTime)}`;
  const totalDuration = format(duration);

  const handleResolutionChange = (resolution) => {
    // Handle the resolution change logic here
    // console.log('Selected resolution:', resolution);
  };
 
  return (
    <div className="video-container">
      <AppBar position="fixed"></AppBar>
      <Container maxWidth="lg">
        <div
          ref={playerContainerRef}
          className={classes.playerWrapper}
          onMouseMove={handleMouseMove}
        >
          {/* {videoByIdFetchStatus === statusCodes.LOADING && ( */}
          {loading && (
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              bgcolor={"#000"}
              minHeight={"100vh"}
            >
              <CircularProgress color="warning" />
            </Grid>
            )}
          {/* )} */}
          {videoByIdFetchStatus === statusCodes.SUCCESS && !loading && (
            <>
              <div className="video-player">
                <ReactPlayer
                  ref={playerRef}
                  width={"100%"}
                  height="100%"
                  url={movieData}
                  muted={muted}
                  playing={playing}
                  volume={volume}
                  playbackRate={playbackRate}
                  onProgress={handleProgress}
                  onBuffer={() => setIsBuffering(true)} // Handle buffering
                  onBufferEnd={() => setIsBuffering(false)} 
                />
                  {isBuffering && (
                  <div className="buffering-overlay">
                    <CircularProgress color="warning" />
                  </div>
                )}
 
              </div>
              <PlayerControls
                data={videoById[0]}
                ref={controlsRef}
                onPlayPause={handlePlayPause}
                playing={playing}
                onRewind={handleRewind}
                onFastForward={handleFastForward}
                muted={muted}
                onMute={handleMute}
                onVolumeChange={handleVolumeChange}
                onVolumeSeekUp={handleVolumeSeekUp}
                volume={volume}
                playbackRate={playbackRate}
                onPlaybackRateChange={handlePlaybackRateChange}
                onToggleFullScreen={handleOnToggleFullScreen}
                played={played}
                onSeek={handleSeekChange}
                onSeekMouseDown={handleSeekMouseDown}
                onSeekMouseUp={handleSeekMouseUp}
                elapsedTime={elapsedTime}
                totalDuration={totalDuration}
                onChangeDisplayFormat={handleChangeDisplayFormat}
                onClickResolution={handleResolutionChange}
              />
            </>
          )}
        </div>
      </Container>
    </div>
  );
};
 
export default VideoPlayer;
 
 