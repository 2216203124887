import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes";
import endpoints from "../../../Endpoints/endpoints";

const initial_video_by_languages = {
  videoByLanguage: [],
  videoByLanguageFetchStatus: statusCodes.IDLE,
};

const getVideoByLanguageReducer = createSlice({
  name: "getVideoByLanguageReducer",
  initialState: initial_video_by_languages,
  reducers: {
    clearAllLanguages(state) {
      state.videoByLanguage = [];
      state.videoByLanguageFetchStatus = statusCodes.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVideoByLanguage.pending, (state, action) => {
        state.videoByLanguageFetchStatus = statusCodes.LOADING;
        // console.log("getvideo by language data pending");
      })
      .addCase(fetchVideoByLanguage.fulfilled, (state, action) => {
        state.videoByLanguage = action.payload.data;
        state.videoByLanguageFetchStatus = statusCodes.SUCCESS;
        // console.log(state.videoByLanguage);
      })
      .addCase(fetchVideoByLanguage.rejected, (state, action) => {
        state.videoByLanguageFetchStatus = statusCodes.ERROR;
        // console.log("getvideo by language data error");
      });
  },
});

export const fetchVideoByLanguage = createAsyncThunk(
  "getVideoByLanguage/get",
  async (Language) => {
    try {
      const videoByLanguage = await axios.get(
        `${endpoints.getByLanguage}?Language=${Language}`
      );
      // console.log(videoByLanguage);
      return videoByLanguage;
    } catch (error) {
      // console.log(error);
      const errorPayload = {
        message: error.response.data.message,
      };
      throw errorPayload;
    }
  }
);

export const { clearAllLanguages } = getVideoByLanguageReducer.actions;
export default getVideoByLanguageReducer.reducer;
