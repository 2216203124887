import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes";
import endpoints from "../../../Endpoints/endpoints";
 
const subscriptionCheckApi = `${endpoints.subscriptionCheck}?userId=`;
 
const initialState = {
  subscribeddata: [],
  checkIsSubscriptionStatus: "",
  errorMessage: null,
};
 
export const subscriptionCheckFunction = createAsyncThunk(
  "subscriptionCheck/subscriptionCheckFunction",
  async (userId) => {
    console.log("Fetching subscription data for userId:", userId);
    const watchHistory = await axios.get(`${subscriptionCheckApi}${userId}`);
    console.log(watchHistory);
    return watchHistory;
  }
);
 
const checkSubscriptionReducer = createSlice({
  name: "checkSubscriptionReducer",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(subscriptionCheckFunction.pending, (state) => {
        state.loading = true;
      })
      .addCase(subscriptionCheckFunction.fulfilled, (state, action) => {
        state.loading = false;
        const responseData = action.payload.data;
        console.log(action.payload.data)
 
        console.log(responseData);
       
        if (responseData.message) {
          state.message = responseData.message;
          state.checkIsSubscriptionStatus = responseData.message;
          console.log(state.checkIsSubscriptionStatus);
          state.subscribeddata = [];
        } else {
          console.log("Subscription check succeeded:", action.payload);
          state.checkIsSubscriptionStatus = action.payload.data;
          console.log(state.checkIsSubscriptionStatus);
          console.log(state.subscribeddata);
          state.subscribeddata = action.payload.data;
        }
      })
      .addCase(subscriptionCheckFunction.rejected, (state, action) => {
        state.loading = false;
        console.log("Subscription check failed:", action.payload);
        state.checkIsSubscriptionStatus = "failed";
        state.errorMessage = action.payload;
        console.log(state.errorMessage)
      });
  },
});
 
export default checkSubscriptionReducer.reducer;
 
 