import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../utils/statusCodes";
import endpoints from "../../Endpoints/endpoints";
import qs from "qs";

const data = {
  UserName: "nssaecorresponds@gmail.com",
  Password: "Gkraocp@1972",
  grant_type: "password",
};

const options = {
  method: "POST",
  headers: { "Content-Type": "application/x-www-form-urlencoded" },
  data: qs.stringify(data),
  url: `https://api.iott.co.in/toke`,
};

const initial_token = {
  token: {},
  tokenFetchStatus: statusCodes.IDLE,
};

export const fetchAccessToken = createAsyncThunk(
  "accessToken/post",
  async () => {
    try {
      const accessToken = await axios(options);
      return accessToken;
    } catch (error) {
      const errorPayload = {
        message: error.response.data,
      };
      // console.log(error.response.data);
      throw errorPayload;
    }
  }
);

const tokenReducer = createSlice({
  name: "tokenReducer",
  initialState: initial_token,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccessToken.pending, (state, action) => {
        state.tokenFetchStatus = statusCodes.LOADING;
      })
      .addCase(fetchAccessToken.fulfilled, (state, action) => {
        const { data } = action.payload; // Extracting the response data
        const { access_token } = data; // Extracting the access token
        // localStorage.setItem("iottToken", JSON.stringify(access_token));
        state.token = access_token;
        state.tokenFetchStatus = statusCodes.SUCCESS;
      })

      .addCase(fetchAccessToken.rejected, (state, action) => {
        state.tokenFetchStatus = statusCodes.ERROR;
      });
  },
});

export default tokenReducer.reducer;
