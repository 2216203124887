import React, { useEffect } from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchTermsAndConditions } from "../../Redux/reducers/termsAndConditionsReducer";
import statusCodes from "../../utils/statusCodes";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./index.css";

const TermsAndConditions = () => {
  const dispatch = useDispatch();
  const { termsAndCondition, termsAndConditonFetchStatus } = useSelector(
    (state) => state.rootReducer.termsAndConditonsReducer
  );
  // console.log(termsAndConditonFetchStatus);
  useEffect(() => {
    dispatch(fetchTermsAndConditions());
  }, [dispatch]);

  return (
    <>
      <Grid container bgcolor={"#000"} Height={"500vh"}>
        <Header />
        <Grid item xs={12}>
          <Box mt={4} mb={4}>
            <Typography
              fontFamily={"Quicksand"}
              fontWeight={700} 
              color={"#FFDA78"}
              textAlign={"center"}
              sx={{ fontSize: { xs: 20, sm: 30 } }}
            >
              Terms and Conditions
            </Typography>
          </Box>

          {termsAndConditonFetchStatus === statusCodes.LOADING && (
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              height={"40vh"}
            >
              <CircularProgress color="warning" />
            </Grid>
          )}

          {termsAndConditonFetchStatus === statusCodes.ERROR && (
            <Typography variant="h5" color="error" textAlign="center">
              Failed to load Terms and Conditions. Please try again later.
            </Typography>
          )}

          {termsAndConditonFetchStatus === statusCodes.SUCCESS && (
            <>
              <Box p={1} sx={{ pr: { xs: 3, sm: 8 }, pl: { xs: 3, sm: 8 } }}>
                <Typography
                  color={"#fff"}
                  fontFamily={"Quicksand"}
                  fontWeight={400}
                  // fontSize={10}
                  sx={{ fontSize: { xs: 10, sm: 15 } }}
                  textAlign={"justify"}
                >
                  <span className="sub-headings">Home:</span>
                  {termsAndCondition[0].Home}
                </Typography>
              </Box>
              <Box p={1} sx={{ pr: { xs: 3, sm: 8 }, pl: { xs: 3, sm: 8 } }}>
                <Typography
                  color={"#fff"}
                  fontFamily={"Quicksand"}
                  fontWeight={400}
                  sx={{ fontSize: { xs: 10, sm: 15 } }}
                  textAlign={"justify"}
                >
                  <span className="sub-headings">IOTT Services:</span>
                  {termsAndCondition[0].IOTT_Service}
                </Typography>
              </Box>
              <Box p={1} sx={{ pr: { xs: 3, sm: 8 }, pl: { xs: 3, sm: 8 } }}>
                <Typography
                  color={"#fff"}
                  fontFamily={"Quicksand"}
                  fontWeight={400}
                  sx={{ fontSize: { xs: 10, sm: 15 } }}
                  textAlign={"justify"}
                >
                  <span className="sub-headings">Account Access:</span>
                  {termsAndCondition[0].Account_Access}
                </Typography>
              </Box>
              <Box p={1} sx={{ pr: { xs: 3, sm: 8 }, pl: { xs: 3, sm: 8 } }}>
                <Typography
                  color={"#fff"}
                  fontFamily={"Quicksand"}
                  fontWeight={400}
                  sx={{ fontSize: { xs: 10, sm: 15 } }}
                  textAlign={"justify"}
                >
                  <span className="sub-headings">Unsolicited Materials:</span>
                  {termsAndCondition[0].Unsolicited_Materials}
                </Typography>
              </Box>
              <Box p={1} sx={{ pr: { xs: 3, sm: 8 }, pl: { xs: 3, sm: 8 } }}>
                <Typography
                  color={"#fff"}
                  fontFamily={"Quicksand"}
                  fontWeight={400}
                  sx={{ fontSize: { xs: 10, sm: 15 } }}
                  textAlign={"justify"}
                >
                  <span className="sub-headings">Customer Support:</span>
                  {termsAndCondition[0].Customer_Support}
                </Typography>
              </Box>
              <Box p={1} sx={{ pr: { xs: 3, sm: 8 }, pl: { xs: 3, sm: 8 } }}>
                <Typography
                  color={"#fff"}
                  fontFamily={"Quicksand"}
                  fontWeight={400}
                  sx={{ fontSize: { xs: 10, sm: 15 } }}
                  textAlign={"justify"}
                >
                  <span className="sub-headings">
                    Electronic Communications:
                  </span>
                  {termsAndCondition[0].Electronic_Communications}
                </Typography>
              </Box>
              <Box p={1} sx={{ pr: { xs: 3, sm: 8 }, pl: { xs: 3, sm: 8 } }}>
                <Typography
                  color={"#fff"}
                  fontFamily={"Quicksand"}
                  fontWeight={400}
                  sx={{ fontSize: { xs: 10, sm: 15 } }}
                  textAlign={"justify"}
                >
                  <span className="sub-headings">Survival:</span>
                  {termsAndCondition[0].Survival}
                </Typography>
              </Box>
              <Box p={1} sx={{ pr: { xs: 3, sm: 8 }, pl: { xs: 3, sm: 8 } }}>
                <Typography
                  color={"#fff"}
                  fontFamily={"Quicksand"}
                  fontWeight={400}
                  sx={{ fontSize: { xs: 10, sm: 15 } }}
                  textAlign={"justify"}
                >
                  <span className="sub-headings">
                    Changes To Terms Of Use And Assignment:
                  </span>
                  {termsAndCondition[0].Changes_to_Terms_of_Use_and_Assignment}
                </Typography>
              </Box>
              <Box p={1} sx={{ pr: { xs: 3, sm: 8 }, pl: { xs: 3, sm: 8 } }}>
                <Typography
                  color={"#fff"}
                  fontFamily={"Quicksand"}
                  fontWeight={400}
                  sx={{ fontSize: { xs: 10, sm: 15 } }}
                  textAlign={"justify"}
                >
                  <span className="sub-headings">Payment Methods:</span>
                  {termsAndCondition[0].Payment_Methods}
                </Typography>
              </Box>
              <Box p={1} sx={{ pr: { xs: 3, sm: 8 }, pl: { xs: 3, sm: 8 } }}>
                <Typography
                  color={"#fff"}
                  fontFamily={"Quicksand"}
                  fontWeight={400}
                  sx={{ fontSize: { xs: 10, sm: 15 } }}
                  textAlign={"justify"}
                >
                  <span className="sub-headings">
                    Warranties And Limitations On Liability:
                  </span>
                  {termsAndCondition[0].Warranties_and_Limitations_on_Liability}
                </Typography>
              </Box>
              <Box p={1} sx={{ pr: { xs: 3, sm: 8 }, pl: { xs: 3, sm: 8 } }}>
                <Typography
                  color={"#fff"}
                  fontFamily={"Quicksand"}
                  fontWeight={400}
                  sx={{ fontSize: { xs: 10, sm: 15 } }}
                  textAlign={"justify"}
                >
                  <span className="sub-headings">
                    Miscellaneous Governing Law:
                  </span>
                  {termsAndCondition[0].Miscellaneous_Governing_Law}
                </Typography>
              </Box>
              <Box p={1} sx={{ pr: { xs: 3, sm: 8 }, pl: { xs: 3, sm: 8 } }}>
                <Typography
                  color={"#fff"}
                  fontFamily={"Quicksand"}
                  fontWeight={400}
                  sx={{ fontSize: { xs: 10, sm: 15 } }}
                  textAlign={"justify"}
                >
                  <span className="sub-headings">You Also Agree Not To:</span>
                  {termsAndCondition[0].You_also_agree_not_to}
                </Typography>
              </Box>
            </>
          )}
        </Grid>
        <Footer />
      </Grid>
    </>
  );
};

export default TermsAndConditions;
