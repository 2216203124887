import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isActive: true,
};

// Create a slice
const searchActiveReducer = createSlice({
  name: "searchActive",
  initialState,
  reducers: {
    // Define reducers
    isActiveSearchBar(state) {
      state.isActive = !state.isActive;
    },
    deactiveSearchBar(state) {
      state.isActive = true;
    },
  },
});

// Export actions
export const { isActiveSearchBar,deactiveSearchBar } = searchActiveReducer.actions;

// Export reducer
export default searchActiveReducer.reducer;
