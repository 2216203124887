import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import endpoints from "../../Endpoints/endpoints"; 
// import statusCodes from "../../utils/statusCodes"; // If needed, import it
// import endpoints from "../../hoc/config/endpoints"; // If needed, import it

 
export const authLogout = createAsyncThunk(
  "authLogout/patch",
  async (logoutJSON) => {
    try {
      const response = await axios.patch(
        `${endpoints.logout}/${logoutJSON.userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${logoutJSON.auth_token}`,
          },
        }
      );
      // console.log(response);
      return response.data; // Return response data instead of the entire response
    } catch (error) {
      console.error("Error during logout:", error);
      throw error; // Throwing the error directly
    }
  }
);

const authLogoutReducer = createSlice({
  name: "authLogout",
  initialState: {
    authLogoutStatus: "",
    authLogoutSnack: false,
    authLogoutLoader: false,
  },
  reducers: {
    closeLogoutSnack(state, action) {
      state.authLogoutSnack = false;
      state.authLogoutStatus = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authLogout.pending, (state, action) => {
        state.authLogoutLoader = true;
      })
      .addCase(authLogout.fulfilled, (state, action) => {
        state.authLogoutStatus = action.payload;
        // console.log(state.authLogoutStatus);
        localStorage.removeItem("loginUser");
        localStorage.removeItem("isSignIn");
        state.authLogoutSnack = true;
        state.authLogoutLoader = false;
      })
      .addCase(authLogout.rejected, (state, action) => {
        state.authLogoutStatus = "Logout failed";
        // console.log(state.authLogoutStatus);
        state.authLogoutLoader = false;
      });
  },
});

export const { closeLogoutSnack } = authLogoutReducer.actions;
export default authLogoutReducer.reducer;
