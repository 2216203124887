

 
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
 
 
export const subscribeUser = createAsyncThunk(
  "subscription/subscribeUser",
  async ({ userId, planId }, { rejectWithValue }) => {
    try {
      console.log("SubscribeUSer"+userId,planId);
      const response = await axios.post(
        "https://api.iott.co.in/api/videos/SubscribeUser",
        {
          UserId: userId,
          SubscriptionId: planId,
        }
      );
      console.log(response.data)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
 
 
const subscriptionUserReducer = createSlice({
  name: "subscription",
  initialState: {
    status: "",
    message: "",
  },
 
  extraReducers: (builder) => {
    builder
      .addCase(subscribeUser.pending, (state) => {
        state.status = "Subscribing to Plan... Wait a moment !";
      })
      .addCase(subscribeUser.fulfilled, (state, action) => {
        console.log("Subscribed from sur");
        state.status = "user Subscribed Sucessfully";
        state.message=action.payload.message
        console.log(state.message)
      })
      .addCase(subscribeUser.rejected, (state, action) => {
        state.status = "failed";
        state.message = action.payload;
        console.log(state.message)
      });
  },
});
 
export default subscriptionUserReducer.reducer;
 
 
 
 
 
 