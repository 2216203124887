
import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  Fade,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./index.css";
 
const style = {
  position: "absolute",
  top: "55%",
  left:"50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 200, sm: 500 },
  bgcolor: "#0A0A23",
  border: "2px solid #FFDA78",
  boxShadow: 24,
  borderRadius: 3,
  p: 3,
};
 
const ShareModal = ({ openModal, closeIcon }) => {
  const [link, setLink] = React.useState(window.location.href);
  const [buttonText, setButtonText] = React.useState("CopyLink");
 
  const handleOpen = () => {
    setLink(window.location.href);
    setButtonText("CopyLink");
    // setOpen(true);
  };
 
  // const handleClose = () => setOpenModal(false);
 
  const handleCopyLink = () => {
    navigator.clipboard.writeText(link).then(() => {
      alert("Link copied to clipboard");
      setButtonText("Link Copied");
      setTimeout(() => {
        setButtonText("CopyLink");
      }, 2000); // Reset button text after 2 seconds
    });
  };
  const handleShare = (url) => {
    navigator.clipboard.writeText(link).then(() => {
      window.open(url, "_blank");
    });
  };
 
 
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={closeIcon}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        {/* <Fade in={openModal}> */}
        <Box sx={style}>
          <Box display={"flex"} justifyContent={"flex-end"}>
            <IconButton onClick={closeIcon} sx={{ color: "#FFDA78" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              id="transition-modal-title"
              color={"#FFDA78"}
              fontFamily={"Quicksand"}
              fontWeight={700}
              fontSize={25}
            >
              Share
            </Typography>
          </Box>
          <Box
            display={"flex"}
            sx={{ flexDirection: { xs: "column", sm: "row" } }}
            pt={2}
            pb={2}
            gap={2}
          >
            <form noValidate autoComplete="off">
              <FormControl sx={{ width: { xs: "21ch", sm: "40ch" } }}>
                <InputLabel
                  sx={{
                    color: "#fff",
                    "&.Mui-focused": {
                      color: "#FFDA78",
                    },
                  }}
                >
                  Link
                </InputLabel>
                <OutlinedInput
                  label="Link"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFDA78",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFDA78",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFDA78",
                    },
                    "& .MuiInputBase-input": {
                      color: "#FFDA78",
                    },
                  }}
                />
              </FormControl>
            </form>
            <Button
              sx={{ borderRadius: 1, height: { xs: "5vh", sm: "8vh" } }}
              onClick={handleCopyLink}
            >
               {buttonText}
            </Button>
          </Box>
          <Typography
            fontFamily={"Quicksand"}
            fontWeight={700}
            fontSize={15}
            color={"#FFDA78"}
          >
            Or Share Via
          </Typography>
          <Box display={"flex"} gap={1.5} alignItems={"center"} pt={2} pb={4}>
          <a
              onClick={() => handleShare(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`)}
            >
              <img
                src={"https://bucketblob.iott.co.in/Icons/facebook.png"}
                alt="facebook"
                className="footer-facebook"
              />
            </a>
            <a
              onClick={() => handleShare(`https://www.instagram.com/?url=${encodeURIComponent(link)}`)}
            >
              <img
                src={"https://bucketblob.iott.co.in/Icons/instagram.jpg"}
                alt="instagram"
                className="footer-instagram"
              />
            </a>
            <a
              onClick={() => handleShare(`https://t.me/share/url?url=${encodeURIComponent(link)}`)}
            >
              <img
                src={"https://bucketblob.iott.co.in/Icons/telegram.png"}
                alt="telegram"
                className="footer-telegram"
              />
            </a>
            <a
              onClick={() => handleShare(`https://twitter.com/intent/tweet?url=${encodeURIComponent(link)}`)}
            >
              <img
                src={"https://bucketblob.iott.co.in/Icons/twitter.png"}
                alt="twitter"
                className="footer-twitter"
              />
            </a>
            <a
              onClick={() => handleShare(`https://api.whatsapp.com/send?text=${encodeURIComponent(link)}`)}
            >
              <img
                src={"https://bucketblob.iott.co.in/Icons/whatsapp.png"}
                alt="whatsapp"
                className="whatsapp"
              />
            </a>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
 
export default ShareModal; 
 