 import { initializeApp } from "firebase/app";

import {getAuth} from "firebase/auth" ;


const firebaseConfig = {

  apiKey: "AIzaSyDFa0Gr-1DVIMyjxUqOx1fJj6kpQ21lthI",
  authDomain: "iottdevelopment.firebaseapp.com",
  databaseURL: "https://iottdevelopment-default-rtdb.firebaseio.com",
  projectId: "iottdevelopment",
  storageBucket: "iottdevelopment.appspot.com",
  messagingSenderId: "976232761762",
  appId: "1:976232761762:web:efff03b5bda2b5b1944afb",
  measurementId: "G-689D4KE87M"
};

 

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

