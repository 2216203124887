import React, { useState } from "react";

import Footer from "../Footer";
import { Box, Grid, InputAdornment, Typography } from "@mui/material";
import Input from "@mui/material/Input";
import { TbShoppingBagSearch } from "react-icons/tb";
import { FaRegFaceSadCry } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import "./index.css";
import Header from "../Header";

function PageNotFound() {
  const [showPassword, setShowPassword] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const navigateIconStyle = {
    color: "#FF5733", // Change color of the icon
    fontSize: "42px",
  };

  const handleSearch = () => {
    if (!searchValue) {
      navigate("/");
    }
    // else{
    //     navigate to matched keyword
    // }
  };
  return (
    <>
      <Grid container bgcolor={"#000"}>
        <Header />
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          className="page-info"
        >
          <Box flexDirection={"column"} pb={5}>
            <Typography className="not-found" marginBottom={"20px"}>
              Oops!
            </Typography>
            <Typography className="error-txt" marginBottom={"30px"}>
              404 - Page Not Found
            </Typography>
            <Typography variant="h6" className="para-txt" marginBottom={"40px"}>
              The page your are looking for might have been removed <br /> or is
              temporarily unavailable.
            </Typography>
          </Box>
        </Grid>

        <Footer />
      </Grid>
    </>
  );
}

export default PageNotFound;
