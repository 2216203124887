import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes";
import endpoints from "../../../Endpoints/endpoints";

const addWatchHistoryApi = endpoints.addWatchHistory;

const initial_addWatchHistory = {
  addWatchHistory: [],
  addWatchHistoryFetchStatus: statusCodes.IDLE,
};

export const fetchAddWatchHistory = createAsyncThunk(
  "addFavorite/post",
  async (addWatchHistoryJson) => {
    try {
      const addWatchHistoryMovie = await axios.post(
        addWatchHistoryApi,
        addWatchHistoryJson
      );
      return addWatchHistoryMovie;
    } catch (error) {
      const errorPayload = {
        message: error.response.data,
      };
      // console.log(error.response.data);
      throw errorPayload; // Throwing the error payload
    }
  }
);

const addWatchHistoryReducer = createSlice({
  name: "addWatchHistoryReducer",
  initialState: initial_addWatchHistory,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddWatchHistory.pending, (state, action) => {
        state.addWatchHistoryFetchStatus = statusCodes.LOADING;
      })
      .addCase(fetchAddWatchHistory.fulfilled, (state, action) => {
        state.addWatchHistory = action.payload.data;
        state.addWatchHistoryFetchStatus = statusCodes.SUCCESS;
      })
      .addCase(fetchAddWatchHistory.rejected, (state, action) => {
        state.addWatchHistoryFetchStatus = statusCodes.ERROR;
      });
  },
});

export default addWatchHistoryReducer.reducer;
