import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
 
// Async thunk for purchasing a subscription plan
export const
purchaseSubscriptionPlan = createAsyncThunk(
  "subscription/purchasePlan",
  async ({ userId, plan, wallet_amount, bonus_amount }, { rejectWithValue }) => {
    try {
      // console.log(userId, plan, wallet_amount, bonus_amount);
      const response = await axios.post(
        "https://api.iott.co.in/api/payment/NewPurchaseSubscription",
        {
          UserId: userId,
          PurchaseAmount: plan.Cost,
          Wallet_BalAmount: wallet_amount,
          BonusAmount: bonus_amount,
          SubscriptionId: plan.Id,
        }
      );
      // console.log("SubscribeUserPurchased");
      // console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
 
// Slice for handling subscription purchase state
const purchaseSubscriptionReducer = createSlice({
  name: "subscription",
  initialState: {
    presentpurchaseStatus: "", // Changed from status to purchaseStatus
    loading: false,
    error: null,
  },
  reducers: {
    resetPurchaseStatus: (state) => {
      state.presentpurchaseStatus = "";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(purchaseSubscriptionPlan.pending, (state) => {
        state.loading = true;
        state.presentpurchaseStatus = "please wait a moment";
        state.error = null;
      })
      .addCase(purchaseSubscriptionPlan.fulfilled, (state, action) => {
        state.loading = false;
        state.presentpurchaseStatus = action.payload;
        // console.log(state.presentpurchaseStatus )
      })
      .addCase(purchaseSubscriptionPlan.rejected, (state, action) => {
        state.loading = false;
        state.presentpurchaseStatus = action.payload;
        state.error = action.payload;
        // console.log(state.error)
        // console.log(state.presentpurchaseStatus )
      });
  },
});
 
// Exporting the resetPurchaseStatus action creator
export const { resetPurchaseStatus } = purchaseSubscriptionReducer.actions;
 
// Exporting the reducer to be used in the store
export default purchaseSubscriptionReducer.reducer;
 
 