import {
  Alert,
  Button,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { BsTwitter, BsTwitterX } from "react-icons/bs";
import { useState } from "react";

const BlogFooter = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [helperText, setHelperText] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setHelperText("Please enter a valid email");
      return;
    }
    setSuccess(true);
    setHelperText("");
    setEmail("");
  };

  const handleClose = () => {
    setSuccess(false);
  };

  return (
    <>
      <Grid container xs={12} bgcolor={"#E2AC5B"} bottom={0}pb={'30px'} justifyContent={'space-between'}>
        <Grid xs={8.5} p={3}>
          <Box display={"flex"} flexDirection={"row"}>
            <Typography
              fontFamily={"Jost"}
              fontWeight={500}
              fontSize={20}
              color={"#000000"}
              sx={{ cursor: "pointer" }}
            >
              About IOTT
            </Typography>{" "}
            <span className="divider">|</span>
            <Typography
              fontFamily={"Jost"}
              fontWeight={500}
              fontSize={20}
              color={"#000000"}
              sx={{ cursor: "pointer" }}
              onClick={()=>{ navigate('/t&c')}}
            >
              Terms&Conditions
            </Typography>{" "}
            <span className="divider">|</span>
            <Typography
  fontFamily={"Jost"}
  fontWeight={500}
  fontSize={20}
  color={"#000000"}
  sx={{ cursor: "pointer" }}
  onClick={() =>  navigate('/privacy&policy') }
>
  Privacy Policy
</Typography>
            <span className="divider">|</span>
            <Typography
              fontFamily={"Jost"}
              fontWeight={500}
              fontSize={20}
              color={"#000000"}
              sx={{ cursor: "pointer" }}
              onClick={() =>  navigate('/help') }
            >
              Contact Us
            </Typography>{" "}
            <span className="divider">|</span>
          </Box>
        </Grid>
        <Grid xs={2.5} display={"flex"} flexDirection={"column"}>
          <Box p={3}>
            <Typography
              fontFamily={"Jost"}
              fontWeight={500}
              fontSize={20}
              color={"#000000"}
              onClick={() =>  navigate('/Follow') }
            >
              Follow Us On
            </Typography>
          </Box>
          <Box display={"flex"} gap={3} alignItems={"center"} ml={2.8}>
      <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
        <Box
          bgcolor={"#fff"}
          borderRadius={8}
          height={40}
          width={40}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ cursor: "pointer" }}
        >
          <img src={"https://bucketblob.iott.co.in/Icons/facebook.png"} alt="facebook" className="footer-facebook" />
        </Box>
      </a>
      <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
        <Box
          bgcolor={"#fff"}
          borderRadius={8}
          height={40}
          width={40}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ cursor: "pointer" }}
        >
          <img src={"https://bucketblob.iott.co.in/Icons/instagram.jpg"} alt="instagram" className="footer-instagram" />
        </Box>
      </a>
      <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
        <Box
          bgcolor={"#fff"}
          borderRadius={8}
          height={40}
          width={40}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ cursor: "pointer" }}
        >
          <img src={"https://bucketblob.iott.co.in/Icons/youtube.png"} alt="youtube" className="footer-youtube" />
        </Box>
      </a>
      <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
        <Box
          bgcolor={"#fff"}
          borderRadius={8}
          height={40}
          width={40}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ cursor: "pointer" }}
        >
          <BsTwitterX fontSize={22} />
        </Box>
      </a>
    </Box>
        </Grid>
      </Grid>
      <Grid
        xs={8}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        p={2}
      >
        <Typography fontSize={30} mr={2} fontFamily={"Jost"}>
          Subscribe
        </Typography>
        <TextField
          variant="outlined"
          placeholder="Enter your Email"
          size="small"
          value={email}
          onChange={handleEmailChange}
          helperText={helperText}
          error={Boolean(helperText)}
          sx={{
            mr: 2,
            width: "500px",
            color: "#000",
            "& .MuiOutlinedInput-root": {
              color: "#000",
              "&::placeholder": {
                color: "#808080",
              },
            },
            "& .MuiInputBase-input::placeholder": {
              color: "#808080",
              opacity: 1,
              fontSize: 18,
              fontFamily: "Jost",
            },
          }}
        />
        <Button
          variant="outlined"
          size="large"
          disabled={!email}
          sx={{
            fontFamily: "Jost",
            fontWeight: 500,
            borderRadius: 1,
            height: 39,
          }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
        <Snackbar open={success} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            You have subscribed successfully!
          </Alert>
        </Snackbar>
      </Grid>
    </>
  );
};
export default BlogFooter;
