import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export default function ProtectedRoute() {
  const location = useLocation();
  const auth = JSON.parse(localStorage.getItem("loginUser"));
  // localStorage.setItem("intendedPath", location.pathname);

  return auth ? <Outlet /> : <Navigate to="/login" />;
}
