import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import Grid from "@mui/material/Grid";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./index.css";

import { useDispatch, useSelector } from "react-redux";
import { fetchHomeDashboardBanners } from "../../Redux/reducers/dashboardBannersReducer";
import { Button, Dialog, IconButton, Tooltip, Typography } from "@mui/material";
import { FaPlay } from "react-icons/fa";

import debounce from "lodash.debounce";
import {
  fetchVideoById,
  setMovieState,
} from "../../Redux/reducers/videos/getVideoByIdReducer";

import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOff from "@mui/icons-material/VolumeOff";
import {
  fetchByGenereDashboardBanners,
  generBannerCache,
} from "../../Redux/reducers/bannersByGenereReducer";
import { useLocation, useNavigate } from "react-router-dom";

const HomeGenerSlider = (props) => {
  const location = useLocation();
  const { gener, title } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [modalPosition, setModalPosition] = useState({
    x: 0,
    y: 0,
    height: null,
    width: null,
  });
  const [hoveredMovieId, setHoveredMovieId] = useState(null);

  const videoRef = useRef(null);

  const [videoRimg, setVideoRimg] = useState(true);
  const [autoplayAllowed, setAutoplayAllowed] = useState(false);
  const [videoMuted, setVideoMuted] = useState(false);

  const handlePlayButtonClick = () => {
    // console.log("clicking");
    setAutoplayAllowed(true);
    setVideoMuted(!videoMuted);
    // setTooltipOpen(false);
  };

  const handleDelayedAutoplay = () => {
    setVideoRimg(false); // Hide the image
    setTimeout(() => {
      setAutoplayAllowed(true); // Allow autoplay after a delay
    }, 500); // Adjust the delay as needed
  };

  const handleItemHoverRelatedPage = debounce((event, index, eachItem) => {
    // dispatch(fetchVideoById(eachItem.Id));

    const fetchVideoByIdJSON = {
      fetchedMovieId: eachItem?.Id,
      categoryValue: eachItem?.Category,
    };
    dispatch(fetchVideoById(fetchVideoByIdJSON));
    setHoveredMovieId(eachItem.Id);
    // // console.log(event.target);
    const slickItem = event.target.getBoundingClientRect();
    // // console.log(slickItem);
    setModalPosition({
      x: slickItem.left,
      y: slickItem.top,
      height: slickItem.height * 2.48,
      width: slickItem.width,
    });
    setSelectedItemIndex(index);
    setIsModalOpen(true);
    setVideoRimg(false);
    setAutoplayAllowed(true);
    handleDelayedAutoplay();
  }, 500); // 200 milliseconds debounce delay

  const handleItemHover = debounce((event, index, eachItem) => {
    // dispatch(fetchVideoById(eachItem.Id));

    // dispatch(setMovieState(eachItem));
    const fetchVideoByIdJSON = {
      fetchedMovieId: eachItem?.Id,
      categoryValue: eachItem?.Category,
    };
    dispatch(fetchVideoById(fetchVideoByIdJSON));
    setHoveredMovieId(eachItem.Id);
    // // console.log(event.target);
    const slickItem = event.target.getBoundingClientRect();
    // // console.log(slickItem);
    setModalPosition({
      x: slickItem.left,
      y: slickItem.top,
      height: slickItem.height * 2.48,
      width: slickItem.width,
    });
    setSelectedItemIndex(index);
    setIsModalOpen(true);
    setVideoRimg(false);
    setAutoplayAllowed(true);
    handleDelayedAutoplay();
  }, 500); // 200 milliseconds debounce delay

  const playFromSpecificDuration = () => {
    if (videoRef.current) {
      const startTime = 22 * 60; // 22 minutes
      const endTime = 24 * 60; // 30 minutes
      videoRef.current.currentTime = startTime;
      videoRef.current.play();
      videoRef.current.addEventListener("timeupdate", function () {
        if (this.currentTime >= endTime) {
          this.pause();
        }
      });
    }
  };

  useEffect(() => {
    if (autoplayAllowed) {
      playFromSpecificDuration();
    }
  }, [autoplayAllowed]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItemIndex(null);
  };

  const handleModalMouseLeave = () => {
    // Close the modal only when the cursor leaves the modal container
    setIsModalOpen(false);
    setSelectedItemIndex(null);
    // setIsCursorInsideModal(false);
    setVideoMuted(!videoMuted);
  };

  const handleModalMouseEnter = () => {
    // Prevent the modal from closing when the cursor enters the modal container
    setIsModalOpen(true);
    // setIsCursorInsideModal(true);
    setVideoMuted(!videoMuted);
  };

  // useEffect(() => {
  //     const clearMv = setTimeout(() => {
  //         setVideoRimg(false);
  //         // console.log("Clearing");
  //         setAutoplayAllowed(true);
  //     }, 1000);

  //     return () => clearTimeout(clearMv);
  // }, []);

  useEffect(() => {
    const handleScroll = () => {
      const modal = document.getElementById("modal");
      // if (modal && !isCursorInsideModal) {
      if (modal) {
        const rect = modal.getBoundingClientRect();
        setModalPosition({
          x: rect.left,
          y: rect.top + window.scrollY,
          height: rect.height,
        });
      }
    };

    if (isModalOpen) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isModalOpen]);
  // }, [isModalOpen, isCursorInsideModal]);

  const customModalStyle = {
    width: modalPosition.width,
    height: modalPosition.height,
    position: "fixed",
    top: modalPosition.y - 1.5,
    left: modalPosition.x - 1.5,
    backgroundColor: "#D3B463",
    // padding: '20px',
    border: "2px solid #ffda78",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
    borderRadius: "7px",

    zIndex: 9999, // Ensure modal appears on top of everything
  };

  // const videoStyle = {
  //     height: modalPosition.height/2
  // }

  // if(generBannerCache.has(gener)){
  //     // console.log(`cached Data ${gener}`, generBannerCache.get(gener))
  // }

  // const { genere_banners, genereBannerFetchStatus } = useSelector((state) => state.rootReducer.bannersByGenereReducer);

  // // console.log(genere_banners)

  let { videoById, videoByIdFetchStatus } = useSelector(
    (state) => state.rootReducer.getVideoByIdReducer
  );
  // // console.log(videoById);

  useEffect(() => {
    dispatch(fetchByGenereDashboardBanners(gener));
  }, []);

  const banners = useSelector((state) => {
    if (gener === "Comedy") {
      return state.rootReducer.bannersByGenereReducer.comedyBanners;
    } else if (gener === "Romance") {
      return state.rootReducer.bannersByGenereReducer.romanceBanners;
    } else if (gener === "Action") {
      return state.rootReducer.bannersByGenereReducer.actionBanners;
    } else if (gener === "Drama") {
      return state.rootReducer.bannersByGenereReducer.dramaBanners;
    } else if (gener === "Devotional") {
      return state.rootReducer.bannersByGenereReducer.devotionalBanners;
    } else if (gener === "Sci-Fi") {
      return state.rootReducer.bannersByGenereReducer.sciFiBanners;
    } else if (gener === "Adventure") {
      return state.rootReducer.bannersByGenereReducer.adventureBanners;
    } else if (gener === "Horror") {
      return state.rootReducer.bannersByGenereReducer.horrorBanners;
    } else if (gener === "Thriller") {
      return state.rootReducer.bannersByGenereReducer.thrillerBanners;
    } else if (gener === "Sports") {
      return state.rootReducer.bannersByGenereReducer.sportsBanners;
    } else if (gener === "Family") {
      return state.rootReducer.bannersByGenereReducer.familyBanners;
    }
  });
  // // console.log(banners);

  let slidesToShow = 5;

  function SampleNextArrow(props) {
    const { className, style, onClick, currentSlide, slideCount } = props;
    return (
      <>
        {currentSlide !== slideCount - slidesToShow && (
          <div
            className={className}
            style={{
              ...style,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#151829",
              height: "84%",
              width: "20px",
              fontSize: "12px",
              color: "#fff",
              borderRadius: "3px",
              marginRight: "20px !important",
            }}
            onClick={onClick}
          >
            <ArrowForwardIosIcon sx={{ fontSize: { xs: "8px", sm: "14px" } }} />
          </div>
        )}
      </>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick, currentSlide } = props;
    return (
      <>
        {currentSlide !== 0 && (
          <div
            className={className}
            style={{
              ...style,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#151829",
              height: "84%",
              width: "20px",
              fontSize: "12px",
              color: "#fff",
              borderRadius: "4px",
            }}
            onClick={onClick}
          >
            <ArrowBackIosNewIcon sx={{ fontSize: { xs: "8px", sm: "14px" } }} />
          </div>
        )}
      </>
    );
  }

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow:
      banners?.length < slidesToShow ? banners?.length : slidesToShow,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const movieDuration = videoById[0] && videoById[0].Duration;

  const year = videoById[0] && videoById[0].ReleasedYear;

  function formatTime(timeStr) {
    const [hours, minutes] = timeStr.split(":").map(Number);

    let formattedTime = "";
    if (hours > 0) {
      formattedTime += `${hours} hrs  `;
    }
    formattedTime += ` ${minutes} min`;

    return formattedTime;
  }

  function formatYear(relesedYear) {
    const date = new Date(relesedYear);
    const year = date.getFullYear();

    return year;
  }
  const onClickMvWatchBtn = (each) => {
    const formattedMovieName = each?.MovieName.replace(/\s+/g, "_");

    dispatch(setMovieState(each));
    navigate(`/details/${formattedMovieName}`);
  };

  //below is for modal watch button (Each indicates whole movie object)
  const onClickMvWatchBtnInDailog = (each) => {
    const formattedMovieName = each?.MovieName.replace(/\s+/g, "_");
    // console.log(each);
    const movieJson = {
      Category: each?.Category,
      Id: each?.Id,
      MovieName: each?.MovieName,
      MoviePoster: each?.MoviePoster,
    };
    dispatch(setMovieState(movieJson));
    navigate(`/details/${formattedMovieName}`);
  };

  return (
    <>
      {banners?.length > 0 && (
        <Grid item width={{ xs: "92%", sm: "98%" }} pl={3}>
          <Typography
            fontFamily={"Quicksand"}
            color={"#FFDA78"}
            fontWeight={700}
            fontSize={{ xs: 10, sm: 20 }}
          >
            {title}
          </Typography>
          <Grid item width={"100%"}>
            <Slider {...settings}>
              {banners?.map((each, index) => {
                "/";
                return (
                  <div key={index} className="image-container">
                    {location.pathname === "/" ? (
                      <div
                        className="movie-contianer"
                        onMouseEnter={(e) => handleItemHover(e, index, each)}
                        onClick={() => onClickMvWatchBtn(each)}
                      >
                        <img
                          src={each.MoviePoster}
                          className="slide-images"
                          alt={index}
                        />
                      </div>   
                    ) : (
                      <div
                        className="movie-contianer"
                        // onMouseEnter={(e) =>
                        //   handleItemHoverRelatedPage(e, index, each)
                        // }
                        onClick={() => onClickMvWatchBtn(each)}
                      >
                        <img
                          src={each.MoviePoster}
                          className="slide-images"
                          alt={index}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </Slider>

            {isModalOpen &&
              videoById.length > 0 &&
              videoById[0].Id === hoveredMovieId &&
              selectedItemIndex !== null && (
                <div
                  id="modal"
                  style={customModalStyle}
                  onMouseEnter={handleModalMouseEnter}
                  onMouseLeave={handleModalMouseLeave}
                  className="movie-layer-contianer"
                >
                  <div>
                    {videoRimg ? (
                      <img
                        src={videoById[0].MoviePoster}
                        alt="hovered-item"
                        className="slide-images"
                      />
                    ) : (
                      <div>
                        <img
                          src={videoById[0].MoviePoster}
                          alt="hovered-item"
                          className="slide-images"
                        />
                        {/* <video
                          ref={videoRef}
                          autoPlay={autoplayAllowed}
                          muted={videoMuted}
                          width="100%"
                          height="100%"
                        >
                          <source
                            src={videoById[0].Quality720}
                            type="video/mp4"
                          />
                        </video> */}
                      </div>
                    )}
                    <div className="content-container">
                      <h1 className="card-movie-name">
                        {videoById[0].MovieName}
                        <span className="movie-rating">
                          IMDB {videoById[0].IMDbRating}
                        </span>
                      </h1>

                      <h4 className="card-movie-duration">
                        {formatYear(year)} |{" "}
                        <span>{formatTime(movieDuration)}</span>
                      </h4>

                      <p className="card-movie-desc">
                        {videoById[0].Description}
                      </p>

                      <Button
                        variant="contained"
                        className="card-button"
                        startIcon={<FaPlay className="card-btn-icon" />}
                        onClick={() => onClickMvWatchBtnInDailog(videoById[0])}
                        // onClick={() => {
                        //   navigate(`/details/${videoById[0].MovieName}`);
                        // }}
                      >
                        Watch
                      </Button>

                      <Tooltip
                        title={videoMuted ? "Volume Off" : "Volume On"}
                        placement="left"
                      >
                        <IconButton
                          aria-label="volumeUp"
                          onClick={handlePlayButtonClick}
                        >
                          {videoMuted ? (
                            <VolumeOff
                              style={{ fontSize: "30px", color: "#151829" }}
                            />
                          ) : (
                            <VolumeUpIcon
                              style={{ fontSize: "30px", color: "#151829" }}
                            />
                          )}
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default HomeGenerSlider;
