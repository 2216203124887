import {
  BorderColor,
  Padding,
  PaddingOutlined,
  PaddingRounded,
} from "@mui/icons-material";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Quicksand",
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            color: "#FFDA78", // Text color
            fontFamily: "Quicksand, sans-serif",
          },
          "& .MuiInputBase-input": {
            caretColor: "#FFDA78", // Caret color (cursor)
          },
          "& .MuiFormLabel-root": {
            color: "#FFDA78", // Placeholder color
          },
          "& .MuiInput-underline": {
            "&:before": {
              borderBottomColor: "white", // Bottom border color when unfocused
            },
            "&:after": {
              borderBottomColor: "white", // Bottom border color when focused
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottomColor: "white", // Bottom border color on hover
            },
          },
          "& .MuiFormHelperText-root": {
            color: "#FE0606", // Helper text color
          },
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          width: "0%",

          "& .MuiSvgIcon-root": {
            fontSize: 20,
            color: "#FFDA78",
            transition: "none",
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontFamily: "Quicksand",
          color: "#000000",
          fontWeight: 400,
          height: 30,
          borderRadius: 15,
          backgroundColor: "#FFDA78",
          "&:hover": {
            backgroundColor: "#FFDA78",
          },
        },
      },
    },
  },
});

export default theme;
