import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes";
import endpoints from "../../../Endpoints/endpoints";
 
let initial_video_by_id = {
  movieState: {},//sesha added
  videoByIdBanner: [],
  videoByIdBannerFetchStatus: statusCodes.IDLE,
};
 
const getVedioByIdBannerReducer = createSlice({
  name: "getVideoByIdBanerReducer",
  initialState: initial_video_by_id,
  reducers: {
    setMovieState(state, action) {
      state.movieState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVideoByIdBanner.pending, (state) => {
        state.videoByIdBannerFetchStatus = statusCodes.LOADING;
        // console.log("getvideo by id data pending");
      })
      .addCase(fetchVideoByIdBanner.fulfilled, (state, action) => {
        state.videoByIdBanner = action.payload.data;
        console.log(state.videoByIdBanner)
        state.videoByIdBannerFetchStatus = statusCodes.SUCCESS;
        console.log(state.videoById);
      })
      .addCase(fetchVideoByIdBanner.rejected, (state, action) => {
        state.videoByIdBannerFetchStatus = statusCodes.ERROR;
        // console.log("getvideo by id data error");
      });
  },
});
 
export const fetchVideoByIdBanner = createAsyncThunk(
  "videoByIdBanner/get",
  async (fetchVideoByIdJSON) => {
    console.log("categoryValue:", fetchVideoByIdJSON);
    if (
      fetchVideoByIdJSON.categoryValue == "WebSeries" ||
      fetchVideoByIdJSON.categoryValue == "Anime"
    ) {
      try {
        const videoById = await axios.get(
          `${endpoints.getWebSeriresById}?id=${fetchVideoByIdJSON.fetchedMovieId}`
        );
        // console.log(videoById);
        return videoById;
      } catch (error) {
        const errorPayload = {
          message: error.response.data.message,
        };
        throw errorPayload;
      }
    } else {
      try {
        const videoById = await axios.get(
          `${endpoints.getVideoByid}?id=${fetchVideoByIdJSON.fetchedMovieId}`
        );
        console.log(videoById);
        return videoById;
      } catch (error) {
        const errorPayload = {
          message: error.response.data.message,
        };
        throw errorPayload;
      }
    }
  }
);
 
export const { setMovieState } = getVedioByIdBannerReducer.actions;
export default getVedioByIdBannerReducer.reducer;
 
 