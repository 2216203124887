let HOSTNAME = '';
let PROTO = '';
let BASE = '';
let MEDIA_BASE = '';

if (typeof window !== 'undefined') {
  const host = window.location.hostname;
  if (host === 'iott.co.in') {
    HOSTNAME = 'api.iott.co.in/api';
    PROTO = 'https';
    BASE = `${PROTO}://${HOSTNAME}`;
    MEDIA_BASE = BASE;
  } else if (host === 'demo.iott.co.in') {
    HOSTNAME = 'api.iott.co.in/api';         //    // 'api.dev.iott.co.in/api'
    PROTO = 'https';
    BASE = `${PROTO}://${HOSTNAME}`;
    MEDIA_BASE = BASE;
  } else {
    //*For hitting local directly*////////////
    HOSTNAME = 'api.iott.co.in/api';
    PROTO = 'https';
    BASE = `${PROTO}://${HOSTNAME}`;
  }
}

const endpoints = {
  //   BASE,
  //   base: `${BASE}`,
    getAllVideos: `${BASE}/videos/getallvideos`,
    getWebSeriresById: `${BASE}/videos/getwebseriesbyid`,
  //   getAllImages: `${BASE}/images/getallimages`,
  getAllLanguages: `${BASE}/language/getalllanguages`,
  getAllCategory: `${BASE}/category/getallcategory`,
  getVideoByid: `${BASE}/videos/GetVideoByid`,
  getByCategory: `${BASE}/videos/getbycategory`,
  privacyPoilcy: `${BASE}/knowiott/getaboutiott`,
  termAndCondition: `${BASE}/knowIOTT/GetTermsAndConditions`,
  aboutUs: `${BASE}/knowiott/getaboutiott`,
  contactUs: `${BASE}/knowiott/getcontactus`,
  addFavorite: `${BASE}/user/addfavorite`,
  getFavoriteById: `${BASE}/user/getfavoritebymovieid`,
  getTrailerById: `${BASE}/videos/gettrailerbyid`,
  getByLanguage: `${BASE}/videos/getbylanguage`,
  //   register: `${BASE}/user/signupuser`,
  Register: `${BASE}/user/Register`,
  login: `${BASE}/user/login`,
  VerifyUser: `${BASE}/user/verifyuser`,
  forced_login: `${BASE}/user/forced_login`,
  ForgotPassword: `${BASE}/user/ForgotPassword`,
  RegisterEmailVerification:`${BASE}/user/RegisterEmailVerification`,
  PasswordSave:`${BASE}/user/PasswordSave`,
   
  
  getByGenere: `${BASE}/videos/getbygenre`,
  getCollection: `${BASE}/user/getfavorite`,
  searchCollection: `${BASE}/videos/getMoviebyName`,
  getAllNotifications: `${BASE}/notification/getallnotifications`,
  feedBackSubmit: `${BASE}/user/feedback`,
  //  uploadProfile: `${BASE}/user/editprofile`,  
  helpUser: `${BASE}/useroperations/userhelp`,
  getBannerImages: `${BASE}/videos/getdashboardbanners`,
  getWatchHistory: `${BASE}/user/getwatchhistory`,
  clearSingleWatchHistory: `${BASE}/user/clearwatchhistory`,
  clearAllWatchHistory: `${BASE}/user/clearallwatchhistory`,
  addWatchHistory: `${BASE}/user/addwatchhistory`,


  getOrderId: `${BASE}/payment/get_orderid`,
  initiateTransaction: `${BASE}/payment/InitiateTransaction`,
  getPurchaseHistory: `${BASE}/payment/get_purchase_history`,
  getWalletAmount: `${BASE}/payment/get_wallet`,
  addWalletAmount: `${BASE}/payment/wallet`,

  subscriptionCheck: `${BASE}/videos/CheckSubscriptionStatus`,
  getTransactionDetails: `${BASE}/payment/get_transactiondetails`,
  getSuggestedMovies: `${BASE}/user/get_suggestedmovies`,
  getBonusAmount: `${BASE}/payment/Get_BonusAmnt`,
  addPurchaseMovie: `${BASE}/payment/purchase_movie`,
  verifyPurchaseMovie: `${BASE}/payment/is_purchase`,
  //   verifyUserSignUp: `${BASE}/user/VerifyUserSignUp`,
  //   verifyUserTest: `${BASE}/user/VerifyUserTest`,

  logout: `${BASE}/user/logout`,
  //   getTrailer: `${BASE}/videos/gettrailerbyid`,
};
export default endpoints;