 
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
 
// Define the async thunk for fetching bonus withdraw history
export const fetchBonusWithdrawHistory = createAsyncThunk(
  'bonusWithdraw/fetchBonusWithdrawHistory',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `https://api.iott.co.in/api/payment/GetBonusWithDrawHistoryByUserId?userId=${userId}`
      );
      return response.data;
    } catch (error) {
      // Handle errors and return them to be handled in the reducer
      return rejectWithValue(error.response.data || 'An error occurred');
    }
  }
);
 
const getBonusWithDrawHistoryByUserIdReducer = createSlice({
  name: 'bonusWithdraw',
  initialState: {
    history: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBonusWithdrawHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBonusWithdrawHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.history = action.payload;
      })
      .addCase(fetchBonusWithdrawHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch bonus withdraw history';
      });
  },
});
 
export default getBonusWithDrawHistoryByUserIdReducer.reducer;
 
 