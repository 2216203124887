import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes"; 
import endpoints from "../../../Endpoints/endpoints"; 

const initiateTransactionApi = endpoints.initiateTransaction

const initial_initiateTransaction = {
  initiatedTransaction: [],
  initiateTransactionFetchStatus: statusCodes.IDLE,
  
};

export const fetchInitiateTransaction = createAsyncThunk(
  "initiateTransaction/post",
  async (transactionJSON) => {
    try {
      const initiateTransaction = await axios.post(
        initiateTransactionApi,
        transactionJSON
      );
      return initiateTransaction;
    } catch (error) {
      const errorPayload = {
        message: error.response.data,
      };
      // console.log(error.response.data);
      throw errorPayload; // Throwing the error payload
    }
  }
);

const initiateTransactionReducer = createSlice({
  name: "initiateTransactionReducer",
  initialState: initial_initiateTransaction,
  extraReducers: (builder) => {
    builder
      .addCase(fetchInitiateTransaction.pending, (state, action) => {
        state.initiateTransactionFetchStatus = statusCodes.LOADING;
       })
      .addCase(fetchInitiateTransaction.fulfilled, (state, action) => {
        state.initiatedTransaction = action.payload.data;
        state.initiateTransactionFetchStatus = statusCodes.SUCCESS;
       })
      .addCase(fetchInitiateTransaction.rejected, (state, action) => {
         state.initiateTransactionFetchStatus = statusCodes.ERROR;
       });
  },
});

export default initiateTransactionReducer.reducer;


 