import axios from "axios";
import qs from "qs";

// Function to fetch auth token
export const getAuthToken = async () => {
  const data = {
    UserName: "nssaecorresponds@gmail.com",
    Password: "Gkraocp@1972",
    grant_type: "password",
  };
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: qs.stringify(data),
    url: `https://api.iott.co.in/token`,
  };

  try {
    const response = await axios(options);
    if (response.status === 200) {
      localStorage.setItem(
        "iottToken",
        JSON.stringify(response.data.access_token)
      );
      return response.data.access_token;
    } else {
      throw new Error("Failed to get auth token");
    }
  } catch (error) {
    console.error("Error fetching auth token:", error);
    throw error;
  }
};
