import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes";
import endpoints from "../../../Endpoints/endpoints";

const clearSingleWatchHistoryApi = endpoints.clearSingleWatchHistory;

const initial_clearSingleWatchHistory = {
  clearSingleWatchHistoryData: [],
  ClearSingleWatchHistoryFetchStatus: statusCodes.IDLE,
};

const clearSingleWatchHistoryReducer = createSlice({
  name: "clearSingleWatchHistoryReducer",
  initialState: initial_clearSingleWatchHistory,
  extraReducers: (builder) => {
    builder
      .addCase(fetchClearSingleWatchHistory.pending, (state, action) => {
        state.ClearSingleWatchHistoryFetchStatus = statusCodes.LOADING;
        // console.log("clear single watch history data pending");
      })
      .addCase(fetchClearSingleWatchHistory.fulfilled, (state, action) => {
        state.clearSingleWatchHistoryData = action.payload.data;
        state.ClearSingleWatchHistoryFetchStatus = statusCodes.SUCCESS;
        // console.log(state.clearSingleWatchHistoryData);
      })
      .addCase(fetchClearSingleWatchHistory.rejected, (state, action) => {
        state.ClearSingleWatchHistoryFetchStatus = statusCodes.ERROR;
        // console.log("clear single watch history  data error");
      });
  },
});

export const fetchClearSingleWatchHistory = createAsyncThunk(
  "fetchClearSingleWatchHistory/delete",
  async (clearWatchHistoryJSON) => {
    // console.log(clearWatchHistoryJSON);
    try {
      const clearSinglewatchHistory = await axios.delete(
        `${clearSingleWatchHistoryApi}?userid=${clearWatchHistoryJSON.userId}&movieid=${clearWatchHistoryJSON.movieId}`
      );
      return clearSinglewatchHistory;
    } catch (error) {
      const errorPayload = {
        message: error.response.data.message,
      };
      throw errorPayload;
    }
  }
);

export default clearSingleWatchHistoryReducer.reducer;
