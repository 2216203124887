import {
  Box,
  Button,
  createTheme,
  Divider,
  Grid,
  Rating,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Header from "../../components/Header";
import "./index.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import StarIcon from "@mui/icons-material/Star";
import Footer from "../../components/Footer";
import { getAuthData } from "../../utils/auth";
// import footervalueReducer, {
//   setfootervalue,
// } from "../../Redux/reducers/footervalueReducer";

const useStyles = makeStyles((theme) => ({
  feedbackContainer: {
    backgroundColor: "black",
    color: "white",
    padding: theme.spacing(4),
    position: "relative",
  },

  verticalLine: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "50%",
    width: "1px",
    backgroundColor: "white",
    zIndex: 1,
  },
  contentGrid: {
    maxWidth: "1200px",
    margin: "0 auto",
    gridTemplateColumns: "1fr 1px 1fr",
    gap: "20px",
    position: "relative",

    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
  },
  emoji: {
    // width:'15px',
    cursor: "pointer",
    // marginBottom: "50px",
    [theme.breakpoints.up("xs")]: {
      width: "40px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "94px",
    },
    // [theme.breakpoints.up('xs')]: {
    //   marginBottom: '0px',
    // },
    // [theme.breakpoints.up('sm')]: {
    //   marginBottom: '50px',
    // },
  },
  ratings: {
    marginTop: "20px",
  },
  faqText: {
    height: "300px !important",
    textAlign: "center",
    borderRadius: "23px",
    opacity: "0.5",
    backgroundColor: "#c5c5c56b",
    color: "#eae8e8dd",
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    resize: "none",
    marginTop: "20px",
  },
  faqButtonSubmit: {
    backgroundColor: "#ffda78",
    color: "#000",
    borderRadius: "25px",
    padding: "10px 30px",
    fontSize: "18px",
    fontWeight: "bold",
    cursor: "pointer",
    marginTop: "20px",
    transition: "background-color 0.3s ease",
  },

  feedbackTitle: {
    color: "#ffda78",
    fontSize: "2rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  rating: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "66%",
    marginright: "10%",
    marginleft: "10%",
    height: "65%",
    borderRadius: "25px",
    backgroundColor: "#fff",
    padding: "20px",
    boxSizing: "border-box",
    fontSize: "34px",
    left: "90%",
  },
}));

const Feedback = () => {
  const classes = useStyles();
  const authData = getAuthData();
  const [value, setValue] = useState(0);
  const [feedbackData, setFeedbackData] = useState("");
  const [feedbackText, setFeedbackText] = useState("");

  const handleSubmit = async () => {
    if (!feedbackText.trim()) {
      setFeedbackData("Please fill required  * fields");
      return;
    }
    try {
      const response = await axios.post(
        "https://api.iott.co.in/api/user/feedback",
        {
          FeedBack: feedbackText,
          Rating: value,
          UserId: authData?.auth?.Id,
        }
      );
      // console.log(response.data);
      setFeedbackData("Your Feedback Added Successfully");
      setFeedbackText("");
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const handleFeedback = (rating) => {
    setValue(rating);
    // dispatch(feedbackReducer(rating));
  };

  return (
    <>
      <Grid container xs={12}>
        <Header />
        <Grid bgcolor={"#000"} minHeight={600} minWidth={"100%"}>
          <Box pt={3} textAlign={"center"}>
            <Typography
              fontFamily={"Quicksand"}
              fontSize={30}
              fontWeight={700}
              color={"#FFDA78"}
            >
              Feedback
            </Typography>
          </Box>

          <Grid
            xs={12}
            // mt={5}
            display={"flex"}
            justifyContent={"space-between"}
            sx={{
              flexDirection: { xs: "column", md: "row" },
              mt: { xs: 2, sm: 5 },
            }}
            mb={5}
          >
            <Grid
              xs={12}
              md={6}
              sx={{ pt: { xs: 0, sm: 8 } }}
              display={"flex"}
              justifyContent={"center"}
              mb={5}
            >
              <Grid item xs={6} md={9}>
                <Grid
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <img
                    src={
                      value !== 1 || value === 0
                        ? "https://bucketblob.iott.co.in/Icons/Poor1.png"
                        : "https://bucketblob.iott.co.in/Icons/poor.gif"
                    }
                    className={classes.emoji}
                    onClick={() => handleFeedback(1)}
                    alt="Poor"
                  />
                  <img
                    src={
                      value !== 2 || value === 0
                        ? "https://bucketblob.iott.co.in/Icons/Bad1.png"
                        : "https://bucketblob.iott.co.in/Icons/Bad.gif"
                    }
                    className={classes.emoji}
                    onClick={() => handleFeedback(2)}
                    alt="Bad"
                  />
                  <img
                    src={
                      value !== 3 || value === 0
                        ? "https://bucketblob.iott.co.in/Icons/Good1.png"
                        : "https://bucketblob.iott.co.in/Icons/Good.gif"
                    }
                    className={classes.emoji}
                    onClick={() => handleFeedback(3)}
                    alt="Good"
                  />
                  <img
                    src={
                      value !== 4 || value === 0
                        ? "https://bucketblob.iott.co.in/Icons/Excellent1.png"
                        : "https://bucketblob.iott.co.in/Icons/Excellent.gif"
                    }
                    className={classes.emoji}
                    onClick={() => handleFeedback(4)}
                    alt="Excellent"
                  />
                  <img
                    src={
                      value !== 5 || value === 0
                        ? "https://bucketblob.iott.co.in/Icons/Super1.png"
                        : "https://bucketblob.iott.co.in/Icons/super_gif.gif"
                    }
                    className={classes.emoji}
                    onClick={() => handleFeedback(5)}
                    alt="Super"
                  />
                </Grid>
                <Grid
                  xs={12}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  pt
                >
                  <Box
                    textAlign={"center"}
                    alignContent={"center"}
                    bgcolor={"#fff"}
                    borderRadius={10}
                    // height={40}
                    sx={{
                      height: { xs: 40, sm: 80 },
                      width: { xs: 500, sm: 350 },
                    }}
                  >
                    <Rating
                      name="customized"
                      value={value}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          sx={{ fontSize: { xs: 20, sm: 50 } }}
                        />
                      }
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderColor: "#FFDA78" }}
            />

            <Grid xs={12} md={6} mt={5}>
              <Box textAlign={"center"}>
                <Typography
                  fontFamily={"Quicksand"}
                  // fontSize={30}
                  fontWeight={700}
                  color={"#FFDA78"}
                  sx={{ fontSize: { xs: 20, sm: 30 } }}
                >
                  You can add your feedback
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
                pt={2}
                pb={2}
                textAlign={"center"}
              >
                {/* <ThemeProvider theme={theme}>
                  <TextField
                    type="text"
                    placeholder="Enter your PhoneNo *"
                    value={useNumber}
                    onChange={handleChange}
                    variant="outlined"
                    sx={{
                      width: { xs: "85%", sm: "60%", md: "45%", lg: "35%" },
                      pb: 3,
                    }}
                    inputProps={{ maxLength: 10 }}
                  />
                </ThemeProvider> */}

                <textarea
                  id="feedbackTextarea"
                  name="feedbackTextarea"
                  rows="5"
                  cols="28"
                  className="text-area"
                  placeholder="Enter Your Feedback *"
                  color="#fff"
                  value={feedbackText}
                  onChange={(e) => setFeedbackText(e.target.value)}
                ></textarea>
              </Box>
              <Box textAlign={"center"} pt={1.5}>
                <Button
                  size="large"
                  onClick={handleSubmit}
                  sx={{
                    borderRadius: 1,
                    width: { xs: "80%", sm: "30%" },
                    height: { xs: "none", sm: "10%" },
                    fontWeight: 700,
                    fontSize: { xs: 15, sm: 20 },
                  }}
                >
                  SUBMIT
                </Button>
              </Box>
              {feedbackData && (
                <Box textAlign={"center"} pt={1.5}>
                  <Typography
                    fontFamily={"Quicksand"}
                    fontSize={20}
                    fontWeight={700}
                    color="#fff"
                  >
                    {feedbackData}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
};
export default Feedback;
