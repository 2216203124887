import {
  Box,
  Grid,
  Typography,
  TextField,
  Checkbox,
  Button,
  InputAdornment,
  IconButton,
  Modal,
  Fade,
  Backdrop,
} from "@mui/material";
import { useState, useEffect } from "react";

import "./index.css";
import { Link } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import OtpInput from "react-otp-input";

import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { authLogin } from "../../../Redux/reducers/authLoginReducer";
import { authVerifyLogin } from "../../../Redux/reducers/authVerifyLoginReducer";
import { authForcedLogin } from "../../../Redux/reducers/authForcedLoginReducer";
import statusCodes from "../../../utils/statusCodes";
// import Loader from "../../../components/Loader";
import { getIp } from "../../../utils/getIp";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);

  const ipAddress = localStorage.getItem("ipAddress");

  const dispatch = useDispatch();

  // console.log(authDetails);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const [loginWithOtp, setLoginWithOtp] = useState("");

  const [verifyOtp, setVerifyOtp] = useState("");

  const [showVerifyOtp, setShowVerifyOtp] = useState(false);

  const [otpSent, setOtpSent] = useState(false);

  const [showOtpFields, setShowOtpFields] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");

  const [sendOtp, setSendOtp] = useState("");

  const [otp, setOtp] = useState("");

  const [loginError, setLoginError] = useState("");

  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => setShowModal(false);
  const [timer, setTimer] = useState(60); // 60 seconds timer
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [showResendButton, setShowResendButton] = useState(true);
  const [sendOtpvisible, setSendOtpvisible] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const { authVerifyDetails, authVerifyLoginStatus } = useSelector(
    (state) => state.rootReducer.authVerifyLoginReducer
  );

  const { authDetails, authLoginStatus, userExists } = useSelector(
    (state) => state.rootReducer.authLoginReducer
  );

  const handleLoginSuccess = () => {
    setShowModal(true);
    setTimeout(() => {
      setShowModal(false);
      // navigate("/");
      navigate(from, { replace: true });
    }, 2000); // Adjust the timeout as needed
  };



  const sendOtpTextColor =
    sendOtp === "Otp is sent your Mobile Number" ? "#13FF13" : "red";

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const mobileRegex = /^[0-9]{10}$/;
  // const mobileRegex = /^\+\d{12}$/;


  // const handleChangePassword=
  //   (event) => {
  //     formik.handleChange(event); 

  //     console.log(event.target.value);
  //   }
  // };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (ipAddress === "" || ipAddress === null || ipAddress === undefined) {
      getIp();
    }
  }, []);

  const validateSchema = Yup.object({
    email: Yup.string()
      .required("Enter a Email")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      ),
    password: Yup.string()
      .required("Enter Password")
      .min(2,)
      // .matches(
      //   /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])/,
      //   "Password invalid"
      // ),
  });

  const onMobileNumberBlur = () => {
    if (mobileRegex.test(mobileNumber)) {
      setMobileNumberError("");
    } else if (!mobileNumber) {
      setMobileNumberError("Phone number is required");
    } else {
      setMobileNumberError("Phone number must be exactly 10 digits");
    }
  };

  const handleLoginOtp = (e) => {
    e.preventDefault();

    setSendOtp("");
    setShowVerifyOtp(true);
    setSendOtpvisible(true);
    if (!mobileNumber) {
      setVerifyOtp("Enter mobile number ");
      return;
    }  
    if (!otpSent) {
      setVerifyOtp("Click on Send OTP");
      return;
    } 
   if (otp.length === 0) {
      setVerifyOtp("Enter OTP");
    } else if (otp.length < 4) {
      setVerifyOtp("OTP must have 4 numbers");
    } else {
      const data = { PhoneNo: mobileNumber, OTP: otp, DeviceName: ipAddress };
      dispatch(authVerifyLogin(data))
        .then((response) => {
          if (response.meta.requestStatus === "fulfilled") {
            // console.log("OTP set Successfully");
            handleLoginSuccess();
            setSendOtpvisible(false);
            setIsButtonDisabled(true);
            localStorage.setItem(
              "loginUser",
              JSON.stringify(response.payload[0])
            );

            // console.log(response.payload[0].Id);

            const userId = response.payload[0].Id;

            dispatch(
              authForcedLogin({
                UserId: userId,
                DeviceName: ipAddress,
              })
            ).then((response) => {
              // console.log("Forced Login:", response);
              if (response.meta.requestStatus === "fulfilled") {
                handleLoginSuccess();
                setVerifyOtp(false);
                // navigate("/",);
                navigate(from, { replace: true });
              } else if (response.meta.requestStatus === "rejected") {
                if (
                  response.error.message ===
                  "Request failed with status code 400"
                ) {
                  setVerifyOtp("Invalid OTP");
                } else if (response.error.name === "TypeError") {
                  setVerifyOtp("Check Your Internet Connectivity");
                } else {
                  setVerifyOtp("Something Went Wrong, please try again later");
                }
              }
            });
          } else if (response.meta.requestStatus === "rejected") {
            if (
              response.error.message === "Request failed with status code 400"
            ) {
              setVerifyOtp("Invalid OTP");
            } else if (response.error.name === "TypeError") {
              setVerifyOtp("Check Your Internet Connectivity");
            } else {
              setVerifyOtp("Something Went Wrong, please try again later");
            }
          }
        })
        .catch((error) => {
          // console.log("Login Error:", error);
        });
    }
  };

  const handleChangeNumber = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "").slice(0, 10);
    setMobileNumber(value);
    setMobileNumberError("");
    setSendOtp("");
    setVerifyOtp("");


    console.log(mobileNumber);
  };

  const onclickSendOtp = () => {
    if (mobileNumber.length === 10) {
      setShowVerifyOtp(false);
      setShowResendButton(false);

      setOtpSent(true); // Set otpSent to true when the button is clicked
      dispatch(authLogin({ PhoneNo: mobileNumber, DeviceName: ipAddress }))
        .then((response) => {
          if (response.meta.requestStatus === "fulfilled") {
            setShowOtpFields(true);
            setSendOtp("Otp is sent your Mobile Number");
            setIsTimerActive(true);
            setShowResendButton(false);
            setIsButtonDisabled(true);
            setTimer(60);
          } else if (response.meta.requestStatus === "rejected") {
            setIsButtonDisabled(false);
            if (
              response.error.message === "Request failed with status code 400"
            ) {
              setSendOtp("User not exist");
              setIsTimerActive(false);
            } else if (response.error.name === "TypeError") {
              setSendOtp("Check Your Internet Connectivity");
              setIsTimerActive(false);
            } else {
              setSendOtp("Something Went Wrong, please try again later");
              setIsTimerActive(false);
              setIsButtonDisabled(true);
            }
          }
        })
        .catch((error) => {
          // console.log("Login Error:", error);
          setIsButtonDisabled(false);
        });
    } else if (mobileNumber.length === 0) {
      setMobileNumberError("Phone number is Required");
    } else {
      setMobileNumberError("Enter a valid mobile number");
    }
  };
  const handleOkClick = () => {
    setShowModal(false);
    // navigate("/"); // Redirect to the home page
    navigate("/", { replace: true });
  };
  useEffect(() => {
    let timerInterval = null;
    if (isTimerActive && timer > 0) {
      timerInterval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000); // Update timer every second
    } else if (timer === 0) {
      setIsTimerActive(false);
      setIsButtonDisabled(true);

      setShowResendButton(true); // Show the resend button after the timer ends
    }
    return () => clearInterval(timerInterval); // Clear interval on cleanup
  }, [isTimerActive, timer]);

  return (
    <>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 120000 }} // 2 minutes timeout
      >
        <Fade in={showModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: 250, sm: 400 },
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              outline: "none",
            }}
          >
            <Typography fontFamily={"Merriweather"} fontSize={18}>
              Login Successful!
            </Typography>
            <Typography
              sx={{ mt: 2 }}
              fontFamily={"Merriweather"}
              fontSize={14}
            >
              You have logged in successfully. You will be redirected shortly.
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
            ></Box>
          </Box>
        </Fade>
      </Modal>

      <Grid container className="bg-image" height={"100vh"} width={"100vw"}>
        <Box mt={1.5} ml={5}>
          <Typography
            fontFamily={"Nunito"}
            fontWeight={700}
            fontSize={"15px"}
            color={"#fff"}
            sx={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() =>
              //  navigate("/")
              navigate("/", { replace: true })
            }
          >
            Back to Home
          </Typography>
        </Box>
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            sx={{ width: { xs: "70%", sm: "30%", lg: "25%" } }}
            bgcolor={"#010113"}
            border={"5px solid #FFDA78"}
            borderRadius={6}
            p={2}
          >
            <Box
              width={"100%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              pt={2}
              pb={4}
            >
              <Box
                sx={{
                  display: { xs: "flex" },
                  flexDirection: {
                    xs: "row",
                    sm: "column",
                    alignItems: "center",
                  },
                }}
              >
                <img
                  src={"https://bucketblob.iott.co.in/Icons/logo.png"}
                  alt="logo"
                  className="logo"
                  onClick={() =>
                    //  navigate("/")
                    navigate("/", { replace: true })
                  }
                />

                <Typography
                  mb={1}
                  fontFamily={"Merriweather"}
                  fontWeight={900}
                  fontSize={30}
                  color={"#FFDA78"}
                >
                  LOGIN
                </Typography>
              </Box>

              {loginWithOtp ? (
                <>
                  {/* login with otp */}

                  <form onSubmit={handleLoginOtp} className="signIn-form">
                    <TextField
                    sx={{
                      mb: 1,
                      mt: 4,
                      '& .MuiInputBase-input::placeholder': {
                        color: '#FFDA78',
                        opacity: 1,
                      },
                      color: '#FFDA78',
                    }}
                      placeholder="Mobile Number"
                      variant="standard"
                      onChange={handleChangeNumber}
                      value={mobileNumber}
                      onBlur={onMobileNumberBlur}
                      helperText={mobileNumberError && mobileNumberError}
                      inputProps={{ maxLength: 10 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start"> 
                            {!isButtonDisabled && (
                              <button
                                type="button"
                                className="send-otp"
                                onClick={onclickSendOtp}
                                sx={{ cursor: "pointer",}}
                                style={{fontFamily:'Quicksand'}}
                              >
                                Send OTP
                              </button>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {otpSent && !showResendButton && isTimerActive && (
                        <Typography
                          fontFamily={"Merriweather"}
                          color={"#FF0000"}
                          fontSize={13}
                        >
                          {`${timer}s`}
                        </Typography>
                      )}

                      {showResendButton && otpSent && !isTimerActive && (
                        <Typography
                          fontFamily={"Merriweather"}
                          color={"#FF0000"}
                          fontSize={13}
                          sx={{ cursor: "pointer" }}
                          onClick={onclickSendOtp}
                        >
                          Resend OTP ?
                        </Typography>
                      )}
                    </Box>

                    {showOtpFields ? (
                      <Box
                        width={"50%"}
                        mt={2}
                        mb={1}
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Typography color={"#FFDA78"} marginRight={2}>
                          OTP
                        </Typography>

                        <OtpInput
                          value={otp}
                          onChange={setOtp}
                          numInputs={4}
                          renderSeparator={<span>-</span>}
                          renderInput={(props) => <input {...props} />}
                          inputStyle={{
                            backgroundColor: "transparent",
                            border: "1px solid #FFDA78",
                            width: 24,
                            height: 24,
                            borderRadius: 4,
                            color: "#ffda78",
                          }}
                        />
                      </Box>
                    ) : (
                      ""
                    )}

                    <Box width={"50%"} mt={2} mb={3}>
                      <Button
                        fullWidth
                        type="submit"
                        sx={{ fontFamily: "Quicksand", fontSize: "17px", fontWeight:600 }}
                      >
                        Login
                      </Button>
                    </Box>
                  </form>
                </>
              ) : (
                <>
                  {/* login with email address */}
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                    validationSchema={validateSchema}
                    onSubmit={(values) => {
                      // console.log("Login :", values);

                      let loginValues = { ...values, DeviceName: ipAddress };

                      dispatch(authLogin(loginValues))
                        .then((response) => {
                          if (response.meta.requestStatus === "fulfilled") {
                            handleLoginSuccess();
                            localStorage.setItem(
                              "loginUser",
                              JSON.stringify(response.payload[0])
                            );
                            // console.log(response.payload[0].Id);

                            const userId = response.payload[0].Id;

                            dispatch(
                              authForcedLogin({
                                UserId: userId,
                                DeviceName: ipAddress,
                              })
                            ).then((response) => {
                              // console.log("Forced Login:", response);
                              if (response.meta.requestStatus === "fulfilled") {
                                navigate("/");
                                // const lastPath =
                                //   localStorage.getItem("lastPath") || "/";
                                //   navigate(lastPath, { replace: true });
                                // window.location.assign(lastPath);
                              } else if (
                                response.meta.requestStatus === "rejected"
                              ) {
                                if (
                                  response.error.message ===
                                  "Request failed with status code 400"
                                ) {
                                  setLoginError("Invalid Credintials");
                                } else if (
                                  response.error.name === "TypeError"
                                ) {
                                  setLoginError(
                                    "Check Your Internet Connectivity"
                                  );
                                } else {
                                  setLoginError(
                                    "Something Went Wrong, please try again later"
                                  );
                                }
                              }
                            });
                          } else if (
                            response.meta.requestStatus === "rejected"
                          ) {
                            if (
                              response.error.message ===
                              "Request failed with status code 400"
                            ) {
                              setLoginError("Invalid Credintials");
                            } else if (response.error.name === "TypeError") {
                              setLoginError("Check Your Internet Connectivity");
                            } else {
                              setLoginError("Something Went Wrong");
                            }
                          }
                        })
                        .catch((error) => {
                          // console.log("Login Error Email:", error);
                        });
                    }}
                  >
                    {(formik) => {
                      return (
                        <form
                          onSubmit={formik.handleSubmit}
                          className="signIn-form"
                        >
                          <Box
                            width={"70%"}
                            mt={0.5}
                            mb={0.5}
                            display={"flex"}
                            flexDirection={"column"}
                          >
                            <TextField
                            sx={{  mt: 0.5, width: "230px",
                              '& .MuiInputBase-input::placeholder': {
                                color: '#FFDA78', 
                                opacity: 1, 
                              },
                              color: '#FFDA78',  }}
                              placeholder="Email"
                              variant="standard"
                              {...formik.getFieldProps("email")}
                              helperText={
                                formik.touched.email && formik.errors.email
                              }
                            />
                          </Box>

                          <Box
                            width={"70%"}
                            display={"flex"}
                            flexDirection={"column"}
                          >
                            <TextField
                            sx={{  mt: 0.5, width: "230px",
                              '& .MuiInputBase-input::placeholder': {
                                color: '#FFDA78', 
                                opacity: 1, 
                              },
                              color: '#FFDA78',  }}
                              placeholder="Password"
                              variant="standard"
                              {...formik.getFieldProps("password")}
                              helperText={
                                formik.touched.password &&
                                formik.errors.password
                              }
                              type={showPassword ? "text" : "password"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      
                                    >
                                      {showPassword ? (
                                        <VisibilityOff
                                          style={{
                                            color: "#FFDA78",
                                            fontSize: "1.2rem",
                                          }}
                                        />
                                      ) : (
                                        <Visibility
                                          style={{
                                            color: "#FFDA78",
                                            fontSize: "1.2rem",
                                          }}
                                        />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(event) => {
                                formik.handleChange(event); 
                                setLoginError("");
                                // console.log(event.target.value); 
                              }}

                            />
                          </Box>

                          <Box
                            width={"70%"}
                            textAlign={"right"}
                            mt={0}
                            pt={0}
                            mb={2}
                          >
                            <Link
                              to="/forgot-password"
                              style={{
                                color: "#fff",
                                textDecoration: "none",
                                fontFamily: "Merriweather",
                                fontSize: "10px",
                              }}
                            >
                              {" "}
                              Forgot Password?{" "}
                            </Link>
                          </Box>

                          <Box width={"50%"} mt={1} mb={0.5}>
                            <Button
                              fullWidth
                              type="submit"
                              sx={{
                                fontFamily: "Quicksand",
                                fontSize: "15px",
                                fontWeight:"900",
                              }}
                            >
                              Login
                            </Button>
                          </Box>

                          {loginError && (
                            <Typography
                              color="red"
                              fontFamily={"Quicksand"}
                              fontSize={14}
                              mt={1}
                              mb={2}
                            >
                              {loginError}
                            </Typography>
                          )}
                        </form>
                      );
                    }}
                  </Formik>
                </>
              )}

              {sendOtp ? (
                <Typography
                
                  fontFamily={"Quicksand"}
                  fontSize={14}
                  color={sendOtpTextColor}
                >
                  {sendOtp}
                </Typography>
              ) : (
                ""
              )}

              {showVerifyOtp ? (
                <Typography color={"red"} fontSize={14}>
                  {verifyOtp}
                </Typography>
              ) : (
                ""
              )}

              {loginWithOtp ? (
                ""
              ) : (
                // <Box
                //   width={"70%"}
                //   display={"flex"} 
                //   justifyContent={"center"}
                //   alignItems={"center"}
                //   sx={{ cursor: "pointer" }}
                //   onClick={() => setLoginWithOtp(!loginWithOtp)}
                // >
                //   <Typography
                //     fontFamily={"Merriweather"}
                //     color={"#FFDA78"}
                //     fontSize={10}
                //     mr={0.5}
                //   >
                //     Login With Otp{" "}
                //   </Typography>
                //   <IoArrowForwardCircleOutline
                //     className="arrow-icon"
                //     color={"#FFDA78"}
                //     onClick={() => setLoginWithOtp(!loginWithOtp)}
                //   />
                // </Box>
                
                
                <Box width={"50%"} mt={1} mb={0.5}>
                <Button
                  fullWidth
                  type="submit"
                  sx={{
                    fontFamily: "Quicksand",
                    fontSize: "15px",
                    cursor:'pointer',
                    fontWeight:"900",
                  }}
                  onClick={() => setLoginWithOtp(!loginWithOtp)}
                >
                  Login With Otp{" "}
                </Button>
              </Box>

              )}

              <Typography
                width={"70%"}
                textAlign={"center"}
                mt={1}
                className="info"
                fontFamily={"Quicksand"}
                fontSize={"10px"}
              >
                Not a Member ?
                <span>
                  <Link
                    to="/register"
                    style={{
                      color: "#FFDA78",
                      textDecoration: "underline",
                      fontFamily: "Merriweather",
                      fontSize: "12px",
                      marginLeft: "4px",
                      marginRight: "4px",
                      fontWeight:"900",
                    }}
                  >
                    Sign Up
                  </Link>
                </span>
                Now
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* )} */}
    </>
  );
};

export default Login;
