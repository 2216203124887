import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../utils/statusCodes";
import endpoints from "../../Endpoints/endpoints";

const getAllNotificationsApi = endpoints.getAllNotifications;

const initialNotification = {
  getAllNotificationsData: [],
  getAllNotificationFetchStatus: statusCodes.IDLE,
};

const getAllNotificationReducer = createSlice({
  name: "getAllNotificationReducer",
  initialState: initialNotification,
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetAllNotification.pending, (state, action) => {
        state.getAllNotificationFetchStatus = statusCodes.LOADING;
        // console.log("get all notifications data pending");
      })
      .addCase(fetchGetAllNotification.fulfilled, (state, action) => {
        state.getAllNotificationsData = action.payload.data;
        state.getAllNotificationFetchStatus = statusCodes.SUCCESS;
        // console.log(state.getAllNotificationsData);
      })
      .addCase(fetchGetAllNotification.rejected, (state, action) => {
        state.getAllNotificationFetchStatus = statusCodes.ERROR;
        // console.log("get all notifications data error");
      });
  },
});

export const fetchGetAllNotification = createAsyncThunk(
  "getAllNotification/get",
  async (authToken) => {
    try {
      const getNotifications = await axios.get(getAllNotificationsApi, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(getNotifications)
      return getNotifications;
    } catch (error) {
      const errorPayload = {
        message: error.response.data.message,
      };
      throw errorPayload;
    }
  }
);

export default getAllNotificationReducer.reducer;
