import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes";
import endpoints from "../../../Endpoints/endpoints";
 
let initial_video_by_id = {
  movieState: {}, //sesha added
  videoById: [],
  videoByIdFetchStatus: statusCodes.IDLE,
};
 
const getVideoByIdReducer = createSlice({
  name: "getVideoByIdReducer",
  initialState: initial_video_by_id,
  reducers: {
    setMovieState(state, action) {
      console.log("checking arj", action.payload);
      state.movieState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVideoById.pending, (state) => {
        state.videoByIdFetchStatus = statusCodes.LOADING;
        // console.log("getvideo by id data pending");
      })
      .addCase(fetchVideoById.fulfilled, (state, action) => {
        state.videoById = action.payload.data;
        console.log(state.videoById);
        state.videoByIdFetchStatus = statusCodes.SUCCESS;
        localStorage.setItem("videoById", JSON.stringify(state.videoById));
        // console.log(state.videoById);
      })
      // .addCase(fetchVideoById.rejected, (state, action) => {
      //   state.videoByIdFetchStatus = statusCodes.ERROR;
      //   // console.log("getvideo by id data error");
      // });
 
      .addCase(fetchVideoById.rejected, (state, action) => {
        state.videoByIdFetchStatus = statusCodes.ERROR;
        console.error("Error fetching video by ID:", action.error.message); // Log or handle the error here
      });
  },
});
 
 
export const fetchVideoById = createAsyncThunk(
  "getVideoById/get",
  async (fetchVideoByIdJSON) => {
    console.log("categoryValue:", fetchVideoByIdJSON);
    console.log("id:", fetchVideoByIdJSON);
 
    try {
      let videoById;
      if (
        fetchVideoByIdJSON.categoryValue === "WebSeries" ||
        fetchVideoByIdJSON.categoryValue === "Anime"
      ) {
        console.log('getWebSeriresById is calling')
        videoById = await axios.get(
          `${endpoints.getWebSeriresById}?id=${fetchVideoByIdJSON.fetchedMovieId}`
        );
      } else {
        console.log('getVedioByid is calling')
        videoById = await axios.get(
          `${endpoints.getVideoByid}?id=${fetchVideoByIdJSON.fetchedMovieId}`
        );
      }
      return videoById;
    } catch (error) {
      const errorPayload = {
        message: error.response.data.message,
      };
      throw errorPayload;
    }
  }
);
 
// export const fetchVideoById = createAsyncThunk(
//   "getVideoById/get",
//   async (fetchVideoByIdJSON) => {
//     console.log("categoryValue:", fetchVideoByIdJSON);
//     console.log("id:", fetchVideoByIdJSON);
   
 
//     if (
//       fetchVideoByIdJSON.categoryValue == "WebSeries" ||
//       fetchVideoByIdJSON.categoryValue == "Anime"
//     ) {
//       try {
 
//         console.log('getWebSeriresById is calling')
//         const videoById = await axios.get(
//           `${endpoints.getWebSeriresById}?id=${fetchVideoByIdJSON.fetchedMovieId}`
//         );
//         // console.log(videoById);
//         return videoById;
//       } catch (error) {
//         const errorPayload = {
//           message: error.response.data.message,
//         };
//         throw errorPayload;
//       }
//     }
//     else {
//       if (
//         fetchVideoByIdJSON.categoryValue !== "WebSeries" ||
//         fetchVideoByIdJSON.categoryValue !== "Anime"
//       ) {
//       try {
//         console.log('getVedioByid is calling')
//         const videoById = await axios.get(
//           `${endpoints.getVideoByid}?id=${fetchVideoByIdJSON.fetchedMovieId}`
//         );
//         console.log(videoById);
//         return videoById;
//       } catch (error) {
//         const errorPayload = {
//           message: error.response.data.message,
//         };
//         throw errorPayload;
//       }
//     }
//     }
//   }
// );
 
export const { setMovieState } = getVideoByIdReducer.actions;
export default getVideoByIdReducer.reducer;
 
 