

 
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
 
// Define the async thunk for fetching referral details
// export const fetchReferralDetails = createAsyncThunk(
//   'referralDetails/fetchReferralDetails',
//   async ({ userId, level }, { rejectWithValue }) => {
//     console.log('referal code triger')
//     console.log( userId, level)
//     try {
//       const response = await axios.get(
//         `https://api.iott.co.in/Payment/GetReferralDetailsRequest?userId=${userId}&level=${level}`
//       );
//       console.log(response)
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data || 'An error occurred');
//     }
//   }
// );
 
export const fetchReferralDetails = createAsyncThunk(
  'referral/fetchReferralDetails',
  async ({ userId, level }, thunkAPI) => {
    try {
      const response = await axios.get(
        `https://api.iott.co.in/api/Payment/GetReferralDetailsRequest?userId=${userId}&level=${level}`
      ); 
      if (response.data?.message === 'No data found') {
        return thunkAPI.rejectWithValue('No data found');
      }
 
    //   return response.data;
    return Array.isArray(response.data) ? response.data : []; // Ensure data is an array
     
    } catch (error) {
      console.error('API Error:', error);
      return thunkAPI.rejectWithValue(error.response?.data || 'Error fetching data');
    }
  }
);
 
 
const referralDetailsRequestReducer = createSlice({
 
  name: 'referralDetails',
  initialState: {
    details:[],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReferralDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReferralDetails.fulfilled, (state, action) => {
        state.loading = false;
        // state.details = action.payload;
        state.details = action.payload; // Ensure details is an array
        console.log(action.payload)
      })
      .addCase(fetchReferralDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch referral details';
        console.log(action.payload)
      });
  },
});
 
export default referralDetailsRequestReducer.reducer;
 
 