import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import statusCodes from "../../utils/statusCodes";

import { fetchPrivacyPolicy } from "../../Redux/reducers/privacyPolicyReducer";

const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const { privacyPolicy, privacyPolicyFetchStatus } = useSelector(
    (state) => state.rootReducer.privacyPolicyReducer
  );
  useEffect(() => {
    dispatch(fetchPrivacyPolicy());
  }, []);
  return (
    <>
      <Grid container bgcolor={"#000"}>
        <Header />
        <Grid
          pt={4}
          pb={2}
          container
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography
            fontFamily={"Quicksand"}
            fontWeight={700}
            sx={{ fontSize: { xs: 20, sm: 30 } }}
            color={"#FFDA78"}
          >
            Privacy Policy
          </Typography>
          <Grid
            container
            justifyContent={"center"}
            xs={10}
            textAlign={"center"}
            minHeight={"auto"}
          >
            {privacyPolicyFetchStatus === statusCodes.LOADING && (
              <Grid container justifyContent={"center"} alignItems={"center"}>
                <CircularProgress color="warning" />
              </Grid>
            )}
            {privacyPolicyFetchStatus === statusCodes.SUCCESS && (
              <Typography
                color={"#fff"}
                fontFamily={"Quicksand"}
                fontWeight={400}
                sx={{ fontSize: { xs: 10, sm: 15 } }}
                textAlign={"justify"}
              >
                {privacyPolicy.Description}
              </Typography>
            )}
            {privacyPolicyFetchStatus === statusCodes.ERROR && (
              <Grid container justifyContent={"center"} alignItems={"center"}>
                <Typography variant="h5" color="error" textAlign="center">
                  Failed to load Terms and Conditions. Please try again later.
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Footer />
      </Grid>
    </>
  );
};
export default PrivacyPolicy;
