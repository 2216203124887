import React, { useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Grid, Typography, Box, Card } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { subscribeUser } from "../../Redux/reducers/subscription/subscribeUserReducer";
import { fetchSubscriptions } from "../../Redux/reducers/mySubscriptionsReducer";
import { getAuthData } from "../../utils/auth";
 
const MySubscriptions = () => {
  window.scrollTo(0, 0);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
 
  const mySubscriptionsData = location.state;
  console.log(mySubscriptionsData);
 
  const SubscriptionPlans = (subscriptionMessage) => {
    navigate("/subscriptions", {
      state: { ...mySubscriptionsData, subscriptionMessage },
    });
  };
 
  const { subscriptions } = useSelector(
    (state) => state.rootReducer.subscriptionsReducer
  );
 
  useEffect(() => {
    dispatch(fetchSubscriptions(getAuthData?.auth?.Id));
  }, []);
 
  return (
    <Grid sx={{ backgroundColor: "rgba(1, 1, 19, 0.8)" }}>
      <Header subscription />
      <Grid
        container
        // flexDirection={"column"}
        minHeight={"70vh"}
        justifyContent={"center"}
        alignItems={"center"}
        mt={1}
      >
        <Typography
          color={"#FFDA78"}
          sx={{ fontSize: { xs: "20px", sm: "25px" } }}
          variant="h4"
          align="center"
          gutterBottom
          fontFamily={"Quicksand"}
          fontWeight={800}
        >
          My Current Plans
        </Typography>
 
        <Grid ml={5}>
          <Button onClick={() => SubscriptionPlans()} sx={{fontWeight:700, borderRadius:1}}>IOTT PLANS</Button>
        </Grid>
        <Grid
          container
          xs={11}
          justifyContent={"center"}
          alignItems={"center"}
          flexWrap={"wrap"}
          gap={2}
        >
          {subscriptions?.map((each, index) => (
            <SubscriptionCard
              key={index}
              subscriptions={each}
              SubscriptionPlans={SubscriptionPlans}
            />
          ))}
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
};
 
export default MySubscriptions;
 
const SubscriptionCard = ({ subscriptions, SubscriptionPlans }) => {
  const currentDate = new Date();
 
  const validityDate = new Date(subscriptions.Validity);
  // Extract year, month, and date from validityDate
  const validityYear = validityDate.getFullYear();
  const validityMonth = validityDate.getMonth() + 1; // Months are zero-based
  const validityDay = validityDate.getDate();
 
  // Format validityDate into a readable string
  const formattedValidityDate = `${validityYear}-${validityMonth
    .toString()
    .padStart(2, "0")}-${validityDay.toString().padStart(2, "0")}`;
    
  const timeDiff = validityDate - currentDate;
  const daysLeft = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  const hoursLeft = Math.floor(
    (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutesLeft = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
  const secondsLeft = Math.floor((timeDiff % (1000 * 60)) / 1000);
 
  // console.log(`Time Difference: ${timeDiff}ms`);
  // console.log(`Days Left: ${daysLeft}`);
  // console.log(`Hours Left: ${hoursLeft}`);
  // console.log(`Minutes Left: ${minutesLeft}`);
  // console.log(`Seconds Left: ${secondsLeft}`);
 
  const getSubscriptionMessage = () => {
    if (daysLeft > 7) {
      return "YOUR PLAN IS ACTIVE";
    } else if (daysLeft > 0 && daysLeft <= 7) {
      return `YOUR PLAN ENDS IN ${daysLeft} DAYS`;
    } else if (daysLeft === 0 && hoursLeft > 0) {
      return `YOUR PLAN ENDS IN ${hoursLeft} HOURS`;
    } else if (daysLeft === 0 && hoursLeft === 0 && minutesLeft > 0) {
      return `YOUR PLAN ENDS IN ${minutesLeft} MINUTES`;
    } else if (
      daysLeft === 0 &&
      hoursLeft === 0 &&
      minutesLeft === 0 &&
      secondsLeft > 0
    ) {
      return `YOUR PLAN ENDS IN ${secondsLeft} SECONDS`;
    } else {
      return "YOUR PLAN HAS EXPIRED";
    }
  };
 
  const subscriptionMessage = getSubscriptionMessage();
  const isActive = subscriptionMessage === "YOUR PLAN IS ACTIVE";
 
  const isDisbeled = validityDate > currentDate;
 
  return (
    <>
      <Card
        sx={{
          backgroundColor: "#fff",
          color: "black",
          borderRadius: 2,
          width: { xs: "80%", sm: "40%", md: "25%" },
          height: "auto",
          pb: 2,
          border: "5px solid rgba(1, 1, 19, 0.8)",
        }}
        className="subscription-card"
      >
        <Box
          borderRadius={1}
          bgcolor={"#FFDA78"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          margin={"auto"}
          height={40}
          flexDirection={"row"}
          mb={3}
          sx={{ width: { xs: "50%" } }}
        >
          <Typography gutterBottom fontFamily={"Quicksand"} fontWeight={700}>
            {subscriptions?.SubscriptionPlan}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={2}
        >
          <Typography
            variant="body1"
            gutterBottom
            fontFamily={"Quicksand"}
            fontWeight={700}
          >
            Purchased Amount : ₹ {subscriptions?.PurchaseAmount}
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            fontFamily="Quicksand"
            fontWeight={600}
          >
            Language : {subscriptions?.Language}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={2}
        >
          <Typography
            variant="subtitle2"
            sx={{
              color: isActive ? "green" : "red",
              fontWeight: isActive ? "bold" : "700",
              fontSize: isActive ? "18px" : "16px",
            }}
          >
            {subscriptionMessage}
          </Typography>
        </Box>
        <Typography pt={3} fontSize={"14px"} color={"#ffda78"} mr={1} textAlign={'center'} sx={{
              color: isActive ? "#eba434" : "red",
              fontWeight: isActive ? "bold" : "500",
              fontSize: isActive ? "14px" : "16px",
            }}>
          Your plan Ends on {formattedValidityDate}
        </Typography>
      </Card> 
       
        {/* <Button
          disabled={isDisbeled}
          // onClick={() => repeatPlan(subscriptions?.SubscriptionPlan)}
        >
          Repeat Plan
        </Button> */} 
      {/* <Grid container justifyContent={"center"} alignItems={"center"}>
        <Button onClick={() => SubscriptionPlans(subscriptionMessage)}>
          Go TO Plans
        </Button>
      </Grid> */}
    </>
  );
};
 
 