import * as React from "react";
import { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menubar from "./Menubar";
import { useLocation, useNavigate } from "react-router-dom";
import { PiFilmReelFill, PiWalletFill } from "react-icons/pi";
import { HiOutlineSearch } from "react-icons/hi";
import { IoInformationCircle, IoPersonCircleSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  deactiveSearchBar,
  isActiveSearchBar,
} from "../../Redux/reducers/searchActiveReducer";
import "./index.css";
import { fetchSuggestedMovies, resetSuggestedMovies } from "../../Redux/reducers/suggestions/getSuggestedMoviesReducer";
import Searchbar from "../Searchbar";
import { fetchWalletAmount } from "../../Redux/reducers/wallet/getWalletAmountReducer";
import { getAuthData } from "../../utils/auth";
import qs from "qs";
import axios from "axios";
import {
  FaAngleDown,
  FaBars,
  FaCommentDots,
  FaHistory,
  FaSignOutAlt,
  FaUserCircle,
} from "react-icons/fa";
import {
  MdCollectionsBookmark,
  MdNotificationsActive,
  MdPrivacyTip,
  MdSubscriptions,
} from "react-icons/md";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
} from "@mui/material";
import endpoints from "../../Endpoints/endpoints";
import Loader from "../Loader";
import { getAuthToken } from "../../utils/getAuthToken";
import { IoIosArrowBack, IoIosHome, IoMdHelpCircle } from "react-icons/io";
import { HiOutlineLanguage } from "react-icons/hi2";
import { BiSolidCameraMovie } from "react-icons/bi";
import { getAllLanguageFunction } from "../../Redux/reducers/subscription/getAllLanguageReducer";
import { fetchallVideos } from "../../Redux/reducers/allVideosReducer";
import { fetchByCategoryDashboardBanners } from "../../Redux/reducers/bannersByCategoryReducer";
import {
  clearAllGeners,
  fetchByGenereDashboardBanners,
} from "../../Redux/reducers/bannersByGenereReducer";
import {
  clearAllLanguages,
  fetchVideoByLanguage,
} from "../../Redux/reducers/videos/getVideoByLanguageReducer";
import { fetchSubscriptions } from "../../Redux/reducers/mySubscriptionsReducer";
import IndianFlag from "./Menubar/Indianflag";
import { clearSearchResults } from "../../Redux/reducers/search/searchReducer";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authData = getAuthData();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  // const [userClickedItem, SetUserClickedItem] = useState("");
  const isOpen = Boolean(anchorEl);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [mySubscriptions, setmySubscriptions] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);

  const [userClickedItem, setUserClickedItem] = useState(null); // Initialize with null or an empty string

  const { allLanguage } = useSelector(
    (state) => state.rootReducer.getAllLanguageReducer);
  // console.log(mySubscriptions);
  // const {subscriptions}=useSelector((state)=>state.rootReducer.fetchSubscriptions)
  // console.log(subscriptions)

  // const handleUserClick = (clickedItem) => {
  //   SetUserClickedItem(clickedItem);
  // };
  const handleUserClick = (clickedItem) => {
    setUserClickedItem(clickedItem);
  };
  
  const genres = [
    "Action",
    "Devotional",
    "Sci-Fi",
    "Adventure",
    "Romance",
    "Comedy",
    "Thriller",
    "Sports",
    "Drama",
    "Family",
  ];

  useEffect(() => {
    if (
      authData?.authToken === "" ||
      authData?.authToken === null ||
      authData?.authToken === undefined
    ) {
      getAuthToken();
    }
  }, []);

  const handleLogoClick = () => {
    dispatch(deactiveSearchBar());
    navigate("/");
  };

  const onClickSearch = () => {
    dispatch(isActiveSearchBar()); 
    dispatch(fetchSuggestedMovies());
    // dispatch(resetSuggestedMovies())
    navigate("/suggestions");
  };

  const onClickOpenSearch = () => {
    dispatch(isActiveSearchBar());
  };

  const onClickSubscription = () => {
    if (mySubscriptions.length > 0 && mySubscriptions[0].SubscriptionId !== 0) {
      navigate("/my_subscriptions", { state: mySubscriptions });
    } else {
      navigate("/subscriptions");
    }
  };

  const onClickOpenWallet = () => {
    if (authData?.auth) {
      dispatch(fetchWalletAmount(authData?.auth?.Id));
      navigate("/wallet");
    } else {
      navigate("/login");
    }
  };

  const onClickProfile = () => {
    if (authData?.auth) {
      navigate("/profile");
    } else {
      navigate("/login");
    }
  };
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const handlePopoverClose = () => {
  //   setAnchorEl(null);
  // };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setUserClickedItem(null); // Clear clicked item state
  };

  function handleLogout() {
    // console.log("logoutTriggered");
    setLoaderStatus(true);
    axios
      .get(`${endpoints.logout}?userid=${authData?.auth?.Id}`, {
        headers: {
          Authorization: `Bearer ${authData?.authToken}`,
        },
      })

      .then((response) => {
        if (response.status === 200) {
          localStorage.removeItem("loginUser");
          // handlePopoverClose(false);

          // setLoaderStatus(false);
          localStorage.removeItem("web-view-count");
          setLoaderStatus(false);

          setLogoutDialogOpen(false);
          navigate("/login");
          // window.location.reload(false);
        }
      })

      .catch((error) => {
        setLoaderStatus(false);
        // alert(error)
      });
  }

  const handleCheckOnceLogout = () => {
    // console.log("triggerd");
    setLogoutDialogOpen(true);
  };

  // const handleNavigate = (path) => {
  //   if (path === "logout" && authData?.auth) {
  //     // handleLogout();
  //     handleCheckOnceLogout();
  //     handlePopoverClose();
  //   }
  //   // else {
  //   // navigate("/login");
  //   // }
  //   if (!authData?.auth)
  //     // else {
  //     navigate("/login");
  //   // }
  //   if (path === "profile") {
  //     navigate("/profile");
  //   }

  //   if (path === "collections") {
  //     navigate("/collections");
  //   }
  //   if (path === "notifications") {
  //     navigate("/notifications");
  //   }
  //   if (path === "watchhistory") {
  //     navigate("/watch_history");
  //   }
  // };



/////////////////// jitendra added /////////////////

 
const handleNavigate = (path) => {
  if (path === "logout" && authData?.auth) {
      handleCheckOnceLogout();
      handlePopoverClose();
     
  }
 
  if (!authData?.auth) {
      navigate("/login");
     
      // navigate("/login", { replace: true });                
  }
 
  if (path === "profile" && authData?.auth) {
      navigate("/profile");
  } else if (path === "profile") {
      navigate("/login", { replace: true });
  }
 
  if (path === "collections" && authData?.auth) {
      navigate("/collections");
  } else if (path === "collections") {
      navigate("/login", { replace: true });
  }
 
  if (path === "notifications" && authData?.auth) {
      navigate("/notifications");
  } else if (path === "notifications") {
      navigate("/login", { replace: true });
  }
 
  if (path === "watchhistory" && authData?.auth) {
      navigate("/watch_history");
  } else if (path === "watchhistory") {
      navigate("/login", { replace: true });
  }
  if (path === "refer&earn" && authData?.auth) {
    navigate("/refer&earn");
  } else if (path === "refer&earn") {
    navigate("/login", { replace: true });
  }

 
};
 
///////////////////////////////////////////////////////


  const fetchMySubscriptions = async () => {
    try {
      const response = await axios.get(
        `https://api.iott.co.in/api/payment/get_purchase_subscription_history?userid=${authData?.auth?.Id}`
      );
      // console.log(response.data);
      setmySubscriptions(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //arjun Add thisuseEffect

  useEffect(() => {
    dispatch(fetchSubscriptions(authData?.auth?.Id));
  }, [dispatch]);
  // console.log();

  useEffect(() => {
    if (authData?.auth?.Id) {
      fetchMySubscriptions(fetchSubscriptions());
    }
    if (allLanguage.length < 1) {
      dispatch(getAllLanguageFunction());
    }
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleMoviesOrWebseries = (movieOrWeb) => {
    setDrawerOpen(false);

    if (movieOrWeb === "Movies") {
      dispatch(fetchallVideos());
      navigate(`/category/${movieOrWeb}`, { state: movieOrWeb });
    }

    dispatch(fetchByCategoryDashboardBanners(movieOrWeb));
    navigate(`/category/${movieOrWeb}`, { state: movieOrWeb });
  };
  const handleGenerMovies = (gener) => {
    setDrawerOpen(false);
    dispatch(clearAllGeners());
    dispatch(fetchByGenereDashboardBanners(gener));
    navigate(`/category/${gener}`, { state: "generes" });
  };

  const handleLanguage = (Language) => {
    setDrawerOpen(false);
    dispatch(clearAllLanguages());
    dispatch(fetchVideoByLanguage(Language));
    navigate(`/category/${Language}`, { state: "languages" });
  };

  const list = () => {
    return (
      <Box
        sx={{
          width: { xs: 200, sm: "36vw" },
          minHeight: "100%",
          height: "auto",
        }}
        className="drawer-iott"
        role="presentation"
      >
        <List sx={{ pt: 3 }}>
          <ListItem
            button
            sx={{ color: "#FFDA78" }}
            onClick={() => setDrawerOpen(false)}
          >
            <IoIosArrowBack className="drawer-icon" />
            <ListItemText primary="More" />
          </ListItem>

          <ListItem button onClick={() => (navigate("/"), toggleDrawer(false))}>
            <IoIosHome className="drawer-icon" />
            <ListItemText primary="Home" />
          </ListItem>

          <Accordion
            expanded={expanded === "moviesPanel"}
            onChange={handleAccordionChange("moviesPanel")}
            sx={{ bgcolor: "transparent", color: "inherit" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <BiSolidCameraMovie className="drawer-icon" />
              <Typography>Entertainment</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ListItem
                button
                onClick={() => handleMoviesOrWebseries("Movies")}
              >
                <ListItemText primary="Movies" />
              </ListItem>
              <ListItem
                button
                onClick={() => handleMoviesOrWebseries("Webseries")}
              >
                <ListItemText primary="Webseries" />
              </ListItem>
              <ListItem button onClick={() => handleMoviesOrWebseries("Anime")}>
                <ListItemText primary="Animes" />
              </ListItem>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "genresPanel"}
            onChange={handleAccordionChange("genresPanel")}
            sx={{ bgcolor: "transparent", color: "inherit", p: 0 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <PiFilmReelFill className="drawer-icon" />
              <Typography>Genres</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {genres.map((gener) => (
                <ListItem button onClick={() => handleGenerMovies(gener)}>
                  <ListItemText primary={gener} />
                </ListItem>
              ))}
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "languagesPanel"}
            onChange={handleAccordionChange("languagesPanel")}
            sx={{ bgcolor: "transparent", color: "inherit", p: 0 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <HiOutlineLanguage className="drawer-icon" />
              <Typography>Languages</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {allLanguage.map((each, columnIndex) => (
                <ListItem button onClick={() => handleLanguage(each.Language)}>
                  <ListItemText primary={each.Language} />
                </ListItem>
              ))}
            </AccordionDetails>
          </Accordion>

          <ListItem
            button
            onClick={() => (onClickProfile(), setDrawerOpen(false))}
          > 
            <FaUserCircle className="drawer-icon" />
            <ListItemText primary="My Profile" />
          </ListItem>

          <ListItem
            button
            onClick={() => (onClickOpenWallet(), setDrawerOpen(false))}
          >
            <PiWalletFill className="drawer-icon" />
            <ListItemText primary="Wallet" />
          </ListItem>

          <ListItem
            button
            onClick={() => (
              handleNavigate("notifications"), setDrawerOpen(false)
            )}
          >
            <MdNotificationsActive className="drawer-icon" />
            <ListItemText primary="Notifications" />
          </ListItem>

          <ListItem
            button
            onClick={() => (
              handleNavigate("collections"), setDrawerOpen(false)
            )}
          >
            <MdCollectionsBookmark className="drawer-icon" />
            <ListItemText primary="My Collections" />
          </ListItem>

          <ListItem
            button
            onClick={() => (
              handleNavigate("watchhistory"), setDrawerOpen(false)
            )}
          >
            <FaHistory className="drawer-icon" />
            <ListItemText primary="Watch history" />
          </ListItem>

          <ListItem
            button
            onClick={() => (onClickSubscription(), setDrawerOpen(false))}
          >
            <MdSubscriptions className="drawer-icon" />

            <ListItemText
              primary={
                mySubscriptions.length > 0 &&
                mySubscriptions[0].SubscriptionId !== 0
                  ? "My Subscriptions"
                  : "IOTT Subscribe"
              }
            />
          </ListItem>

          <ListItem
            button
            onClick={() => (navigate("/help"), setDrawerOpen(false))}
          >
            <IoMdHelpCircle className="drawer-icon" />
            <ListItemText primary="Help" />
          </ListItem>

          <ListItem
            button
            onClick={() => (navigate("/feedback"), setDrawerOpen(false))}
          >
            <FaCommentDots className="drawer-icon" />
            <ListItemText primary="Feedback" />
          </ListItem>

          <ListItem
            button
            onClick={() => (navigate("/aboutUs"), setDrawerOpen(false))}
          >
            <IoInformationCircle className="drawer-icon" />
            <ListItemText primary="About us" />
          </ListItem>

          <ListItem
            button
            onClick={() => (navigate("/privacy&policy"), setDrawerOpen(false))}
          >
            <MdPrivacyTip className="drawer-icon" />
            <ListItemText primary="Privacy policy" />
          </ListItem>

          {authData?.auth && (
            <ListItem
              button
              onClick={() => (handleLogout(), setDrawerOpen(false))}
              sx={{ color: "red", fontWeight: 1000 }}
            >
              <FaSignOutAlt className="drawer-icon" />
              <ListItemText primary="Logout" />
            </ListItem>
          )}
          {/* 
          <ListItem button onClick={() => navigate("/shorts")}>
            <PiWalletFill className="drawer-icon" />
            <ListItemText primary="Shorts" />
          </ListItem> */}
        </List>
      </Box>
    );
  };

  return (
    <Grid item width={"100%"}>
      <AppBar
        position="static"
        sx={{ bgcolor: "#0A0A23", padding: { xs: "6px", sm: "10px" } }}
      >
        {loaderStatus && <Loader message="Logging out, please wait..." />}
        <Toolbar>
          <Box width={"100%"} display={"flex"} justifyContent={"space-between"}>
            <Box display={{ xs: "block", md: "none" }}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <FaBars color={"#FFDA78"} size={24} />
              </IconButton>
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
              >
                {list()}
              </Drawer>
            </Box>
            <Box
              width={"28vw"}
              display={{ xs: "none", md: "flex" }}
              justifyContent={"flex-start"}
            >
              <Menubar />
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              onClick={handleLogoClick}
              sx={{ cursor: "pointer" }}
            >
              {/* <Box mr={3}>
                <IndianFlag />
              </Box> */}
              <img
                src={
                  "https://bucketblob.iott.co.in/Icons/iott-modified-logo.png"
                }
                alt="iott-logo"
                className="iott-header-logo"
              />
            </Box>

            <Box
              width={"auto"}
              display={{ xs: "none", md: "flex" }}
              justifyContent={"flex-end"}
              alignItems={"flex-end"}
            >
              {location.pathname !== "/suggestions" ? (
                <Box className="HeaderIcons" mr={3} onClick={onClickSearch}>
                  <HiOutlineSearch color={"#FFDA78"} fontSize={24} />
                  <Typography
                    fontFamily={"Quicksand"}
                    fontSize={12}
                    color={"#FFDA78"}
                    marginRight={0.5}
                    fontWeight={700}
                  >
                    Search
                  </Typography>
                </Box>
              ) : (
                <Searchbar onClickOpenSearch={onClickOpenSearch} />
              )}

              <Box
                width={"auto"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box
                  className="HeaderIcons"
                  mr={3}
                  onClick={onClickSubscription}
                >
                  <img
                    src={
                      "https://bucketblob.iott.co.in/Icons/iott-modified-logo.png"
                    }
                    color={"#FFDA78"}
                    style={{
                      height: "22px",
                      width: undefined,
                      aspectRatio: 1.5,
                    }}
                  />
                  <Typography
                    fontFamily={"Quicksand"}
                    fontSize={11}
                    color={"#FFDA78"}
                    marginRight={0.2}
                    fontWeight={700}
                  >
                    {mySubscriptions.length > 0 &&
                    mySubscriptions[0].SubscriptionId !== 0
                      ? "My Plans"
                      : "Subscribe"}
                  </Typography>
                </Box>
                <Box className="HeaderIcons" mr={3} onClick={onClickOpenWallet}>
                  <PiWalletFill color={"#FFDA78"} fontSize={26} />
                  <Typography
                    fontFamily={"Quicksand"}
                    fontSize={11}
                    color={"#FFDA78"}
                    marginRight={0.5}
                    fontWeight={700}
                  >
                    Wallet
                  </Typography>
                </Box>

                <Box className="HeaderIcons" onClick={onClickProfile}>
                  <IoPersonCircleSharp color={"#FFDA78"} fontSize={26} />
                  <Typography
                    fontFamily={"Quicksand"}
                    fontSize={11}
                    color={"#FFDA78"}
                    fontWeight={700}
                  >
                    Profile
                  </Typography>
                </Box>
                <Box
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                >
                  <FaAngleDown color={"#FFDA78"} size={15} cursor={"pointer"} />
                  <Popover
                    open={isOpen}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleNavigate("profile");
                        handleUserClick("My Profile");
                        handlePopoverClose(); // Close the popover
                      }}
                      sx={{
                        backgroundColor:
                          userClickedItem === "My Profile"
                            ? "#FFDA78"
                            : "transparent",
                            fontWeight:"700",
                        "&:hover": {
                          backgroundColor: "#FFDA78",
                        },
                      }}
                    >
                      My Profile
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleNavigate("collections");
                        handleUserClick("My Collections");
                        handlePopoverClose(); // Close the popover
                      }}
                      sx={{
                        backgroundColor:
                          userClickedItem === "My Collections"
                            ? "#FFDA78"
                            : "transparent",
                            fontWeight:"700",


                        "&:hover": {
                          backgroundColor: "#FFDA78",
                        },
                      }}
                    >
                      My Collections
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        handleNavigate("notifications");
                        handleUserClick("Notifications");
                        handlePopoverClose(); // Close the popover
                      }}
                      sx={{
                        backgroundColor:
                          userClickedItem === "Notifications"
                            ? "#FFDA78"
                            : "transparent",
                            fontWeight:"700",
                        "&:hover": {
                          backgroundColor: "#FFDA78",
                        },
                      }}
                    >
                      Notifications
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        handleNavigate("watchhistory");
                        handleUserClick("Watch History");
                        handlePopoverClose(); // Close the popover
                      }}
                      sx={{
                        backgroundColor:
                          userClickedItem === "Watch History"
                            ? "#FFDA78"
                            : "transparent",
                            fontWeight:"700",
                        "&:hover": {
                          backgroundColor: "#FFDA78",
                        },
                      }}
                    >
                      Watch History
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        handleNavigate("refer&earn");
                        handleUserClick("Refer And Earn");
                        handlePopoverClose(); // Close the popover
                      }}
                      sx={{
                        backgroundColor:
                          userClickedItem === "Refer And Earn"
                            ? "#FFDA78"
                            : "transparent",
                            fontWeight:"700",
                        "&:hover": {
                          backgroundColor: "#FFDA78",
                        },
                      }}
                    >
                      Refer & Earn
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: "red",
                        backgroundColor: "transparent",
                        fontWeight:"700",
                        "&:hover": {
                          backgroundColor: "#FFDA78",
                        },
                      }}
                      onClick={() => { 
                        navigate(1);      
                            
                        handleNavigate("logout");    
                        handleUserClick("Logout");
                        handlePopoverClose(); // Close the popover
                      }} 
                    >
                        {authData?.auth ? "Logout" : "Login"}
                    </MenuItem>
                  </Popover>
                </Box>
              </Box>
            </Box>
            <Box
              // width={"28vw"}
              display={{ xs: "flex", md: "none" }}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <Box
                onClick={onClickSearch}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={0.2}
              >
                <HiOutlineSearch color={"#FFDA78"} fontSize={24} />
                <Typography
                  fontFamily={"Quicksand"}
                  fontSize={12}
                  color={"#FFDA78"}
                  fontWeight={700}
                >
                  Search
                </Typography>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {/* const [logoutDialogOpen, setLogoutDialogOpen] = useState(false); */}

      <Dialog
        open={logoutDialogOpen}
        onClose={() => setLogoutDialogOpen(false)}
      >
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to logout?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLogoutDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogout} color="primary" autoFocus>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Header;


