import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authRegisterReducer from "./reducers/authRegisterReducer";
import authLoginReducer from "./reducers/authLoginReducer";
import authVerifyLoginReducer from "./reducers/authVerifyLoginReducer";
 
 
import authLogoutReducer from "./reducers/authLogoutReducer";
import bannersByAllCategoryReducer from "./reducers/bannersByAllCategoryReducer";
import bannersByAllLanguagesReducer from "./reducers/bannersByAllLanguagesReducer";
import bannersByCategoryReducer from "./reducers/bannersByCategoryReducer";
import bannersByGenereReducer from "./reducers/bannersByGenereReducer";
import dashboardBannersReducer from "./reducers/dashboardBannersReducer";
import privacyPolicyReducer from "./reducers/privacyPolicyReducer";
import termsAndConditonsReducer from "./reducers/termsAndConditionsReducer";
import aboutUsReducer from "./reducers/aboutUsReducer";
import contactUsReducer from "./reducers/contactUsReducer";
import addFavoriteReducer from "./reducers/favorite/addFavoriteReducer";
import getAllFavoritesReducer from "./reducers/favorite/getAllFavoritesReducer";
import getFavoriteByMovieIdReducer from "./reducers/favorite/getFavoriteByMovieIdReducer";
import getVideoByIdReducer from "./reducers/videos/getVideoByIdReducer";
import getTrailerByIdReducer from "./reducers/videos/getTrailerByIdReducer";
import getVideoByLanguageReducer from "./reducers/videos/getVideoByLanguageReducer";
import getFavoriteByNameReducer from "./reducers/favorite/getFavoriteByNameReducer";
import getAllNotificationReducer from "./reducers/getAllNotificationReducer";
import helpUserReducer from "./reducers/helpUserReducer";
import getWatchHistoryReducer from "./reducers/watchHistory/getWatchHistoryReducer";
import clearSingleWatchHistoryReducer from "./reducers/watchHistory/clearSingleWatchHistoryReducer";
import clearAllWatchHistoryReducer from "./reducers/watchHistory/clearAllWatchHistoryReducer";
import addWatchHistoryReducer from "./reducers/watchHistory/addWatchHistoryReducer";
import getOrderIdReducer from "./reducers/getOrderIdReducer";
import initiateTransactionReducer from "./reducers/wallet/initiateTransactionReducer";
import purchaseHistoryReducer from "./reducers/wallet/purchaseHistoryReducer";
import addWalletAmountReducer from "./reducers/wallet/addWalletAmountReducer";
import getWalletAmountReducer from "./reducers/wallet/getWalletAmountReducer";
import transactionDetailsReducer from "./reducers/wallet/getTransactionDetailsReducer";
import getSuggestedMoviesReducer from "./reducers/suggestions/getSuggestedMoviesReducer";
import purchaseMovieReducer from "./reducers/wallet/purchaseMovieReducer";
import verifyPurchaseMovieReducer from "./reducers/wallet/verifyPurchaseMovieReducer";
import tokenReducer from "./reducers/tokenReducer";
 
import searchActiveReducer from "./reducers/searchActiveReducer";
import authForcedLoginReducer from "./reducers/authForcedLoginReducer";
import searchReducer from "./reducers/search/searchReducer";
import subscriptionCheckFunction from "./reducers/subscription/checkSubscriptionReducer";
import getBounsAmountReducer from "./reducers/wallet/getBounsAmountReducer";
import getAllLanguageReducer from "./reducers/subscription/getAllLanguageReducer";
import allVideosReducer from "./reducers/allVideosReducer";
import playerStateReducer from "./reducers/videos/playerStateReducer";
import subscriptionsReducer from "./reducers/mySubscriptionsReducer";
// They are related to subscriptionReducer
import purchaseSubscriptionReducer from "./reducers/subscription/purchaseSubscriptionReducer";
import subscribeUserReducer from './reducers/subscription/subscribeUserReducer';
import getVedioByIdBannerReducer from "./reducers/videos/getVedioByIdBannerReducer";
import getBonusWithDrawHistoryByUserIdReducer from "./reducers/referAndEarn/getBonusWithDrawHistoryByUserIdReducer";
import referralDetailsRequestReducer from "./reducers/referAndEarn/referralDetailsRequestReducer";
 
 
 
 
const rootReducer = combineReducers({
  authRegisterReducer,
  authLoginReducer,
  authVerifyLoginReducer,
  authForcedLoginReducer,
  authLogoutReducer,
  bannersByAllCategoryReducer,
  bannersByAllLanguagesReducer,
  bannersByCategoryReducer,
  bannersByGenereReducer,
  dashboardBannersReducer,
  privacyPolicyReducer,
  termsAndConditonsReducer,
  aboutUsReducer,
  contactUsReducer,
  addFavoriteReducer,
  getAllFavoritesReducer,
  getFavoriteByMovieIdReducer,
 
  playerStateReducer,
 
  allVideosReducer,
  getVideoByIdReducer,
  getTrailerByIdReducer,
  getVideoByLanguageReducer,
  getFavoriteByNameReducer,
  getAllNotificationReducer,
  helpUserReducer,
  getWatchHistoryReducer,
  clearSingleWatchHistoryReducer,
  clearAllWatchHistoryReducer,
  addWatchHistoryReducer,
  getOrderIdReducer,
  initiateTransactionReducer,
  purchaseHistoryReducer,
  addWalletAmountReducer,
  getWalletAmountReducer,
  getBounsAmountReducer,
  transactionDetailsReducer,
  getSuggestedMoviesReducer,
  purchaseMovieReducer,
  verifyPurchaseMovieReducer,
  tokenReducer,
  searchReducer,
  searchActiveReducer,
  //arjun add the subscriptionsReducer//
  subscriptionsReducer,
  getAllLanguageReducer,
  subscriptionCheckFunction,
  purchaseSubscriptionReducer,
  subscribeUserReducer,
  getVedioByIdBannerReducer,
  getBonusWithDrawHistoryByUserIdReducer,
  referralDetailsRequestReducer,
});
 
export const store = configureStore({
  reducer: { rootReducer },
});
 
 