import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Divider } from "@mui/material";
import { styled } from "@mui/system";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { fetchWatchHistory } from "../../Redux/reducers/watchHistory/getWatchHistoryReducer";
import { useDispatch, useSelector } from "react-redux";
import { fetchClearSingleWatchHistory } from "../../Redux/reducers/watchHistory/clearSingleWatchHistoryReducer";
import { fetchClearAllWatchHistory } from "../../Redux/reducers/watchHistory/clearAllWatchHistoryReducer";
import { getAuthData } from "../../utils/auth";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
 
const useStyles = makeStyles((theme) => ({
  img: {
    borderRadius: "8px",
    cursor: "pointer",
    width: "80%", // Adjusted default width
    height: "inherit",
    [theme.breakpoints.up("sm")]: {
      width: "40%", // Adjusted width for sm screens and up
      height: "auto",
    },
    [theme.breakpoints.up("xs")]: {
      width: "100px", // Adjusted width for xs screens and up
      height: "auto",
    },
    [theme.breakpoints.up("md")]: {
      width: "30%", // Adjusted width for xs screens and up
      height: "auto",
    },
  },
}));
 
 
// Styled components
const Container = styled(Box)(({ theme }) => ({
  backgroundColor: "#F4C86F",
  borderRadius: "15px",
  padding: theme.spacing(3), 
  margin: "auto",
  border: "2px solid #fff", 
  [theme.breakpoints.up('xs')]: {
    maxWidth: "70%", // or any specific value for xs
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: "500px", // change this value as needed
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: "60%", // change this value as needed
  },
  // You can add more breakpoints like 'lg' or 'xl' if needed
}));
 
 
const SectionHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(1),
  paddingBottom: theme.spacing(0.5),
}));
 
const VideoItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(2),
}));
 
const VideoDetails = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));
 
const WatchHistory = () => {
  const authData = getAuthData();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
 
  const { categorizedWatchHistory, watchHistoryFetchStatus } = useSelector(
    (state) => state.rootReducer.getWatchHistoryReducer
  );
 
  const [clear, setClear] = useState(false);
 
  const handleClick = (id) => {
    const clearWatchHistoryJSON = {
      movieId: id,
      userId: authData?.auth?.Id,
    };
    dispatch(fetchClearSingleWatchHistory(clearWatchHistoryJSON))
      .then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          setClear(!clear);
        }
      });
    dispatch(fetchWatchHistory(authData?.auth?.Id));
  };
 
  const deleteClick = () => {
    dispatch(fetchClearAllWatchHistory(authData?.auth?.Id)).then((response) => {
      if (response.meta.requestStatus === "fulfilled") {
        setClear(!clear);
      }
    });
  };
 
  useEffect(() => {
    dispatch(fetchWatchHistory(authData?.auth?.Id));
  }, [clear, dispatch, authData?.auth?.Id]);
 
  const handleWatchHistoryMovie = (item) => {
    const formattedMovieName = item.MovieName.replace(/\s+/g, "_");
    navigate(`/details/${formattedMovieName}`, { state: item.Id });
  };
 
  return (
    <>
      <Header />
      <Grid pt={5} bgcolor={"#010113"} xs={10} sx={{ p: { xs: 0 } }}>
        <Container>
          <Box textAlign={"center"} pb={1}>
            <Typography
              fontFamily={"Quicksand"}
              fontWeight={700}
              fontSize={{ xs: 20, sm: 26, md: 32 }}
            >
              Watch History
            </Typography>
          </Box>
 
          {watchHistoryFetchStatus === "loading" && (
            <Typography textAlign="center" fontFamily={"Quicksand"} fontWeight={600} fontSize={{xs:'13px',sm:'17px'}} color={"#000"}>
              Loading ....
            </Typography>
          )}
       <Typography
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
              textAlign: "right",
           
            }}
            mt={1}
            fontFamily={"Quicksand"}
            fontWeight={700}
            fontSize={{ xs: '10px', sm: '16px', md: 24 }}
            onClick={deleteClick}
          >
            Delete All
          </Typography>
          {watchHistoryFetchStatus === "success" && Object.keys(categorizedWatchHistory).length > 0 ? (
            Object.keys(categorizedWatchHistory).map((category, index) => (
              <React.Fragment key={index}>
                <SectionHeader>
                  <Typography
                    fontFamily={"Quicksand"}
                    fontWeight={700}
                    fontSize={{ xs: 12, sm: 20, md: 24 }}
                   
                  >
                    {category}
                  </Typography>
                 
                 
                 
                </SectionHeader>
                <Divider  sx={{ border: "1px solid #808080" }}/>
             
                {categorizedWatchHistory[category].length > 0 ? (
                  categorizedWatchHistory[category].map((video, index) => (
                    <VideoItem
                      key={index}
                      pt={2}
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "row", sm: "row" },
                        ml: { xs: 'none', sm: 6 }
                      }}
                    >
                      <img
                        src={video.MoviePoster}
                        alt={video.MovieName}
                        className={classes.img}
                        onClick={() => handleWatchHistoryMovie(video)}
                      />
                      <VideoDetails>
                      <Box display={'flex'} flexDirection={'column'} gap={{xs:3,sm:5,md:8}} ml={{xs:0,sm:5, md:8}}>
                          <Typography
                            fontFamily={"Quicksand"}
                            fontWeight={700}
                            sx={{ fontSize: { xs: '9px', sm: '13px' } }}
                          >
                            {video.MovieName}
                          </Typography>
                          <Typography
                            sx={{
                              textDecoration: "underline",
                              cursor: "pointer",
                              fontSize: { xs: '8px', sm: '13px' },
                            }}
                            fontFamily={"Quicksand"}
                            fontWeight={700}
                            onClick={() => handleClick(video.Id)}
                            color={"#ba344d"}
                          >
                            Remove this from Watch History
                          </Typography>
                        </Box>
                       
                      </VideoDetails>
                     
                    </VideoItem>
               
                 ))
                ) : (
                  <Typography
                    textAlign={"center"}
                    fontFamily={"Quicksand"}
                    fontWeight={600}
                    fontSize={25}
                    color={"#000"}
                  >
                    Currently You Have No Watch History
                  </Typography>
                )}
              </React.Fragment>
            ))
          ) : (
            watchHistoryFetchStatus === "success" && (
              <Typography
                textAlign={"center"}
                fontFamily={"Quicksand"}
                fontWeight={600}
                fontSize={25}
                color={"#000"}
              >
                Currently You Have No Watch History
              </Typography>
            )
          )}
 
          {watchHistoryFetchStatus === "error" && (
            <Typography
              textAlign={"center"}
              fontFamily={"Quicksand"}
              fontWeight={600}
              fontSize={25}
              color={"#000"}
            >
              Something Went wrong..! Try again.
            </Typography>
          )}
         
          <Divider />
 
        </Container>
        <Grid 
          mt={5} 
          // pb={4} 
          bgcolor={"#22263E"}
          borderColor={"#242A4A"} 
        >
          <Footer />
        </Grid>
      </Grid>
    </>
  );
};
 
export default WatchHistory;
 
 