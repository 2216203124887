import MainBanners from "../../components/MainBanners";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchVideoById } from "../../Redux/reducers/videos/getVideoByIdReducer";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FaPlay, FaPlus } from "react-icons/fa";
import Subscription from "../Subscription";
import Footer from "../../components/Footer";
 
import ShareIcon from "@mui/icons-material/Share";
 
import { useNavigate } from "react-router-dom";
 
import "./index.css";
import HomeGenerSlider from "../../components/HomeGenerSlider";
import Header from "../../components/Header";
import { getAuthData } from "../../utils/auth";
import { subscriptionCheckFunction } from "../../Redux/reducers/subscription/checkSubscriptionReducer";
import axios from "axios";
import endpoints from "../../Endpoints/endpoints";
import { authLogout } from "../../Redux/reducers/authLogoutReducer";
import { fetchVerifyPurchaseMovie } from "../../Redux/reducers/wallet/verifyPurchaseMovieReducer";
import statusCodes from "../../utils/statusCodes";
import { fetchWalletAmount } from "../../Redux/reducers/wallet/getWalletAmountReducer";
import { fetchBounsAmount } from "../../Redux/reducers/wallet/getBounsAmountReducer";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ShareModal from "./ShareModal";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
 
import {
  fetchFavoritesByMovieId,
  setFavorites,
} from "../../Redux/reducers/favorite/getFavoriteByMovieIdReducer";
import { fetchAddFavorite } from "../../Redux/reducers/favorite/addFavoriteReducer";
import Loader from "../../components/Loader";
import {
  resetPlayerState,
  setPlayerState,
} from "../../Redux/reducers/videos/playerStateReducer";
 
import { fetchSearchMovies } from "../../Redux/reducers/search/searchReducer";
import { getAuthToken } from "../../utils/getAuthToken";
import { makeStyles } from "@mui/styles";
import SeoMetaDetails from "../../utils/SeoMetaDetails";
import { fetchAllFavorites } from "../../Redux/reducers/favorite/getAllFavoritesReducer";
import { setCacheTimestamp } from "../../Redux/reducers/videos/getVedioByIdBannerReducer";
 
const useStyles = makeStyles((theme) => ({
  castImg: {
    width: 100,
    height: 100,
 
    [theme.breakpoints.up("xs")]: {
      width: 60,
      height: 60,
      borderRadius: 50,
    },
    [theme.breakpoints.up("md")]: {
      width: 100,
      height: 100,
      borderRadius: 10,
    },
  },
}));
 
const DetailedPage = () => {
  const classes = useStyles();
  const { movieNameParam } = useParams();
  const theme = useTheme();
 
  const location = useLocation();
 
  // const movieId = location.state?.id;
 
  const authData = getAuthData();
  const ipAdress = localStorage.getItem("ipAddress");
  const dispatch = useDispatch();
 
  const navigate = useNavigate();
 
  const { movieState, videoById, videoByIdFetchStatus } = useSelector(
    (state) => state.rootReducer.getVideoByIdReducer
  );
  // console.log(videoById);
  // console.log(videoByIdFetchStatus)
 
  const categoryValue = movieState?.Category;
  // console.log(movieState);
  // const movieId = movieState?.Id;
  const movieId = location.state?.id;
  // ===sesha added above======
 
  const [currentMovieId, setCurrentMovieId] = useState(movieId);
  const [isPurchaseMovie, setIsPurchaseMovie] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOpen1, setDialogOpen1] = useState(false);
  const [selectWallet, setSelectWallet] = useState("Yes");
  const [selectBonus, setSelectBonus] = useState("No");
 
  const [selectedSeason, setSelectedSeason] = useState("S01");
  const [seasonNumber, setSeasonNumber] = useState(0);
  const [episodeNumber, setEpisodeNumber] = useState(0);
 
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
 
  const [favourite, setFavourite] = useState(false);
 
  const [userCollectionsMovieNames, setUserCollectionsMovieNames] = useState(
    []
  );
  const currentMovieName = videoById[0]?.MovieName;
  // console.log(episodeNumber);
  const handleImageLoad = () => {
    setLoading(false);
  };
 
  // console.log(videoById);
 
  const { subscribeddata, checkIsSubscriptionStatus } = useSelector(
    (state) => state.rootReducer.subscriptionCheckFunction
  );
 
  const { verify_purchase_movie } = useSelector(
    (state) => state.rootReducer.verifyPurchaseMovieReducer
  );
 
  const { getFavoritesById, fetchStatus } = useSelector(
    (state) => state.rootReducer.getFavoriteByMovieIdReducer
  );
 
  // console.log(getFavoritesById);
 
  const { addFavoriteFetchStatus } = useSelector(
    (state) => state.rootReducer.addFavoriteReducer
  );
  // console.log(verify_purchase_movie);
 
  // console.log(verify_purchase_movie);
  const { wallet_amount } = useSelector(
    (state) => state.rootReducer.getWalletAmountReducer
  );
  const { bouns_amount } = useSelector(
    (state) => state.rootReducer.getBounsAmountReducer
  );
 
  const dateTimeString = videoById[0]?.ReleasedYear;
  const newDate = new Date(dateTimeString);
  const year = newDate.getFullYear();
 
  const durationString = videoById[0]?.Duration || "00:00";
 
  const [hours, minutes] = durationString.split(":");
 
  const formattedDuration = `${parseInt(hours, 10)}hr ${parseInt(
    minutes,
    10
  )}mins`;
 
  const handleClose = () => setOpenModal(!openModal);
 
  const handleSetSubscription = () => {
    // Ensure subscribeddata is an array
    if (!Array.isArray(subscribeddata)) {
      console.error("subscribeddata is not an array:", subscribeddata);
      return false;
    }
 
    if (!videoById[0]?.Language) {
      console.error("videoById[0]?.Language is not available");
      return false;
    }
 
    const trimmedLanguage = videoById[0].Language.trim();
 
    const isSubscribedLanguage = subscribeddata.some((subscription) => {
      const isLanguageMatch =
        subscription.Language === trimmedLanguage ||
        subscription.Language === "AllLanguages";
      const isActive = subscription.IsSubscribed;
      return isLanguageMatch && isActive;
    });
 
    // console.log("isActive", isSubscribedLanguage);
 
    return isSubscribedLanguage;
  };
 
  const handleChangeSeason = (event) => {
    const selectedSeasonIndex = event.target.value;
    setSelectedSeason(videoById[0]?.Seasons[selectedSeasonIndex]?.SeasonId);
    setSeasonNumber(selectedSeasonIndex);
  };
 
  const handleIsLoginCheck = async (data) => {
    //==is_Login check===//
    const logoutJSON = {
      userId: authData?.auth?.Id,
      auth_token: authData?.authToken,
    };
    const options = {
      method: "GET",
      headers: { Authorization: `Bearer ${authData?.authToken}` },
    };
 
    const response = await fetch(
      `https://api.iott.co.in/api/user/IsLogin?UserId=${authData?.auth?.Id}&DeviceName=${ipAdress}`,
      options
    );
    const isLoginData = await response.json();
 
    if (isLoginData === 1) {
      validate_beforeMoviePlayer(data);
      // console.log(isLoginData);
    } else {
      localStorage.removeItem("loginUser");
      localStorage.removeItem("isSignIn");
      localStorage.removeItem("web-view-count");
      navigate("/login");
      // // console.log("handleLogout called");
    }
  };
 
  const { get_all_favorites, getAllFavoritesFetchStatus } = useSelector(
    (state) => state.rootReducer.getAllFavoritesReducer
  );
 
  // console.log(get_all_favorites);
  // useEffect(() => {
  //   if (authData?.auth?.Id) {
  //     dispatch(fetchAllFavorites(authData?.auth?.Id));
  //   }
  // }, []);
  // useEffect(() => {
  //   const hello = async () => {
  //     if (authData?.auth?.Id) {
  //       console.log("hello");
  //       try {
  //         const response = await dispatch(fetchAllFavorites(authData.auth.Id));
  //         console.log(response.payload.data);
 
  //         const favData = response.payload.data;
  //         const favMovies = favData.map((each) => each.MovieName);
  //         setUserCollectionsMovieNames(favMovies);
  //         console.log(favMovies);
 
  //         // Handle response here if needed
  //         // For example, you might want to check if favorites were fetched successfully
  //         console.log("Favorites fetched:", response);
 
  //         // Add further code that depends on the successful fetch of favorites
  //       } catch (error) {
  //         // Handle any errors that occur during fetching
  //         console.error("Error fetching favorites:", error);
  //       }
  //     }
  //   };
 
  //   hello();
  // }, [authData?.auth?.Id, dispatch]);
  useEffect(() => {
    const fetchFavorites = async () => {
      if (authData?.auth?.Id) {
        try {
          const response = await dispatch(fetchAllFavorites(authData.auth.Id));
          const favData = response.payload.data;
          const favNames = favData.map((each) => each.MovieName);
          setUserCollectionsMovieNames(favNames);
        } catch (error) {
          console.error("Error fetching favorites:", error);
        }
      }
    };
 
    fetchFavorites();
  }, [authData?.auth?.Id, dispatch]);
  const isFavorite = userCollectionsMovieNames.includes(currentMovieName);
 
  // console.log(get_all_favorites);
 
  useEffect(() => {
    // Assuming you need to fetch the movie ID or other details based on movieNameParam
    // If you have a way to map movieNameParam to movieId, do it here
    // For example, you might fetch details or perform an action based on movieNameParam
 
    // Dispatch an action to fetch favorite status by movieId
    dispatch(fetchFavoritesByMovieId({ userId: 1, movieId: movieNameParam })); // Adjust as necessary
  }, [dispatch, movieNameParam, ]);
 
  useEffect(() => {
    const currentPath = location.pathname;
    localStorage.setItem("lastPath", currentPath);
    // window.location.assign('/login');  // Redirect to login page
  }, [location]);
 
  useEffect(() => {
    window.scrollTo(0, 0);
 
    const fetchData = async () => {
      try {
        let authToken = authData?.authToken;
 
        // Check if the auth token is available, if not fetch it
        if (!authToken) {
          authToken = await getAuthToken();
        }
 
        const searchJSON = { searchValue: movieNameParam, authToken };
 
        if (!movieId || movieId === null) {
          // Fetch movies by search if movieId is not provided
          const response = await dispatch(fetchSearchMovies(searchJSON));
          // console.log(response);
 
          if (response && response.payload && response.payload.length > 0) {
            const fetchedMovieId = response.payload[0].Id;
            const categoryValuerefresh = response.payload[0].Category;
            setCurrentMovieId(fetchedMovieId);
            const fetchVideoByIdJSON = {
              fetchedMovieId: fetchedMovieId,
              categoryValue: categoryValuerefresh,
            };
            // dispatch(setCacheTimestamp(Date.now()));
            dispatch(fetchVideoById(fetchVideoByIdJSON));
 
            const verifyPurchaseMovieJSON = {
              userId: authData?.auth?.Id,
              movieId: fetchedMovieId,
            };
 
            dispatch(subscriptionCheckFunction(authData?.auth?.Id));
            dispatch(fetchVerifyPurchaseMovie(verifyPurchaseMovieJSON));
 
            if (
              authData?.auth &&
              (wallet_amount === "" || bouns_amount === "")
            ) {
              dispatch(fetchWalletAmount(authData?.auth?.Id));
              dispatch(fetchBounsAmount(authData?.auth?.Id));
            }
          } else {
            console.error("Failed to fetch movie details by name");
          }
        } else {
          // Fetch movie by ID if movieId is provided
          setCurrentMovieId(movieId);
          const fetchVideoByIdJSON = {
            fetchedMovieId: movieId,
            categoryValue: categoryValue,
          };
          dispatch(fetchVideoById(fetchVideoByIdJSON));
 
          const verifyPurchaseMovieJSON = {
            userId: authData?.auth?.Id,
            movieId: movieId,
          };
 
          dispatch(subscriptionCheckFunction(authData?.auth?.Id));
          dispatch(fetchVerifyPurchaseMovie(verifyPurchaseMovieJSON));
 
          if (authData?.auth && (wallet_amount === "" || bouns_amount === "")) {
            dispatch(fetchWalletAmount(authData?.auth?.Id));
            dispatch(fetchBounsAmount(authData?.auth?.Id));
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
 
    fetchData();
  }, [movieId, episodeNumber, movieNameParam]);
 
  useEffect(() => {
    const verifyPurchaseMovieJSON = {
      userId: authData?.auth?.Id,
      movieId: movieId,
    };
    dispatch(fetchFavoritesByMovieId(verifyPurchaseMovieJSON));
  }, [addFavoriteFetchStatus]);
 
  const handlePurchaseMovie = async (data) => {
    await axios
      .post(
        `${endpoints.addPurchaseMovie}`,
        {
          UserId: authData?.auth?.Id,
          PurchaseAmount: videoById[0]?.PriceOfTheMovie,
          MovieId: videoById[0]?.Id,
          UseBonus: data,
        },
        {
          headers: {
            Authorization: `Bearer ${authData?.authToken}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          handleWatchMovie(videoById[0]?.Id);
        }
      });
  };
 
  function handleProceed(moviePrice) {
    if (selectWallet === "No" && selectBonus === "Yes") {
      if (wallet_amount >= moviePrice) {
        handlePurchaseMovie("YES");
      } else {
        alert("Insufficient Balance. Please add money to wallet");
      }
    } else {
      if (selectWallet === "No" || selectBonus === "Yes") {
        if (selectWallet === "No") {
          if (wallet_amount >= moviePrice) {
            handlePurchaseMovie("NO");
          } else {
            alert("Insufficient Balance. Please add money to wallet");
          }
        } else if (selectBonus === "Yes") {
          if (bouns_amount >= moviePrice) {
            if (selectWallet === "No") {
              // // console.log("YES");
              // alert("Yes")
              handlePurchaseMovie("YES");
            } else {
              alert("Please select Wallet.");
            }
          } else {
            alert("Insufficient Balance.");
          }
        }
      } else if (selectWallet === "Yes" || selectBonus === "No") {
        alert("Please select any one");
      }
    }
  }
 
  const handleWatchMovie = (data) => {
    // console.log(data);
    // console.log("vedioplayerPage");
 
    if (categoryValue == "WebSeries" || categoryValue == "Anime") {
      const playerState = {
        movieType: "series",
        seasonNo: seasonNumber,
        episodeNo: episodeNumber,
      };
      // if ([3912, 3884, 3848, 3865, 3847].includes(data)) {
      //   const playerState = {
      //     movieType: "series",
      //     seasonNo: seasonNumber,
      //     episodeNo: episodeNumber,
      //   };
 
      // here also add the newly created dispatch action regarding movie player refresh
      dispatch(setPlayerState(playerState));
 
      // console.log("if");
      const fetchVideoByIdJSON = {
        fetchedMovieId: movieId,
        categoryValue: categoryValue,
      };
      const refreshState = {
        playerState: playerState,
        fetchVideoByIdJSON: fetchVideoByIdJSON,
      };
      navigate(`/movie-player/${data}`, { state: refreshState });
    } else {
      dispatch(resetPlayerState());
      // console.log("else");
      navigate(`/movie-player/${data}`);
    }
  };
 
  const handleSelectWallet = (event) => {
    if (event.target.value != selectWallet) {
      setSelectWallet("No");
    } else if (event.target.value && selectWallet === "No") {
      setSelectWallet("Yes");
    }
  };
 
  const handleSelectBonus = (event) => {
    if (event.target.value != selectBonus) {
      setSelectBonus("Yes");
    } else if (event.target.value && selectBonus === "Yes") {
      setSelectBonus("No");
    }
  };
 
  const handleToClose = () => {
    setDialogOpen(false);
  };
  const handleToClose1 = () => {
    setDialogOpen1(false);
  };
  const handleClickToOpen1 = () => {
    setDialogOpen1(true);
  };
  const handleClickToOpen = () => {
    setDialogOpen(true);
  };
 
  function checkWalletAmount(moviePrice) {
    // window.alert("Sucess")
    let totalAmount = wallet_amount ? wallet_amount : 0;
    if (totalAmount >= moviePrice) {
      handleClickToOpen1();
    } else {
      handleClickToOpen();
    }
  }
 
  const validate_beforeMoviePlayer = (data) => {
    // console.log("before");
    // console.log("validation started");
    if (data?.IsPremium) {
      if (verify_purchase_movie === 1 || handleSetSubscription()) {
        handleWatchMovie(data?.Id);
      } else {
        checkWalletAmount(data?.PriceOfTheMovie);
      }
    } else {
      handleWatchMovie(data?.Id);
    }
  };
 
  const onClickWatchBtn = (data) => {
    handleIsLoginCheck(data);
  };
 
  const onClickWatchWebSeriesBtn = (data) => {
    handleIsLoginCheck(data);
  };
 
  // const handleFavoriteMovie = (movieId) => {
  //   if (authData?.auth) {
  //     const addFavoriteJson = {
  //       UserId: authData?.auth?.Id,
  //       MovieId: movieId,
  //     };
  //     dispatch(fetchAddFavorite(addFavoriteJson));
  //   } else {
  //     navigate("/login");
  //   }
  // };
 
  // const handleFavoriteMovie = (movieId) => {
  //   if (authData?.auth) {
  //     const isFavorite = getFavoritesById === 1;
  //     const newFavoriteStatus = isFavorite ? 0 : 1;
 
  //     // Update local state first
  //     dispatch(setFavorites(newFavoriteStatus));
 
  //     // Then update server
  //     const addFavoriteJson = {
  //       UserId: authData.auth.Id,
  //       MovieId: movieId,
  //     };
  //     dispatch(fetchAddFavorite(addFavoriteJson));
  //   } else {
  //     navigate("/login");
  //   }
  // };
  // beforedhi pinadhi
 
  // const handleFavoriteMovie = async (movieId) => {
  //   if (authData?.auth) {
  //     const isFavorite = getFavoritesById === 1;
  //     const newFavoriteStatus = isFavorite ? 0 : 1;
 
  //     // Update local state first
  //     dispatch(setFavorites(newFavoriteStatus));
 
  //     // Then update server
  //     const addFavoriteJson = {
  //       UserId: authData.auth.Id,
  //       MovieId: movieId,
  //     };
 
  //     try {
  //       await dispatch(fetchAddFavorite(addFavoriteJson));
  //       // Optionally handle success (e.g., show a message or update state)
  //     } catch (error) {
  //       console.error('Error updating favorite status:', error);
  //       // Optionally handle error (e.g., show an error message)
  //     }
  //   } else {
  //     navigate("/login");
  //   }
  // };
 
  const handleFavoriteMovie = async (movieId) => {
    if (authData?.auth) {
      const isFavorite = getFavoritesById === 1;
      const newFavoriteStatus = isFavorite ? 0 : 1;
 
      // Optimistically update local state
      dispatch(setFavorites(newFavoriteStatus));
 
      // Then update server
      const addFavoriteJson = {
        UserId: authData.auth.Id,
        MovieId: movieId,
      };
 
      try {
        await dispatch(fetchAddFavorite(addFavoriteJson));
 
        // Re-fetch favorites to ensure state is accurate after update
        const response = await dispatch(fetchAllFavorites(authData.auth.Id));
        const favData = response.payload.data;
        const favNames = favData.map((each) => each.MovieName);
        setUserCollectionsMovieNames(favNames);
      } catch (error) {
        console.error("Error updating favorite status:", error);
        // Optionally revert the optimistic update if needed
        dispatch(setFavorites(isFavorite ? 1 : 0));
      }
    } else {
      navigate("/login");
    }
  };
 
  // const onClickShareIcon = () => {
  //     setOpenModal(true)
  // }
  function handleNavigateWallet() {
    navigate("/wallet");
  }
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
 
  const getImageSrc = () => {
    if (isXs) return videoById[0]?.MoviePoster;
    if (isMd) return videoById[0]?.WebPoster;
    return videoById[0]?.WebPoster; // default to WebPoster if neither xs nor sm
  };
 
  const handleNavigateToSubscrption = () => {
    navigate("/Subscriptions");
  };
  return (
    <Box bgcolor={"#010113"}>
      <Header />
 
      <SeoMetaDetails
        movieId={currentMovieId}
        movieName={videoById[0]?.MovieName}
      />
 
      {/* {=========== above comp is for SEO ===============} */}
 
      {videoByIdFetchStatus === statusCodes.LOADING && (
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          minHeight={"70vh"}
        >
          <CircularProgress color="warning" />
        </Grid>
      )}
 
      {videoByIdFetchStatus === statusCodes.SUCCESS && (
        <Box minHeight={"70vh"}>
          <div style={{ position: "relative", width: "100vw", height: "100%" }}>
            <img
              src={getImageSrc()}
              alt="banners"
              style={{ top: 0, left: 0, width: "100%", height: "100%" }}
            />
 
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100%",
                background:
                  "linear-gradient(to top, rgba(0,0,0,0.92) 0%,rgba(0,0,0,0.74) 35%,rgba(0,0,0,0.66) 45%,rgba(0,0,0,0.58) 50%,rgba(0,0,0,0) 100%)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                overflowX: "hidden",
              }}
            >
              <Box
                width={"100%"}
                display={{ xs: "none", md: "flex" }}
                justifyContent={"flex-start"}
                pb={3}
                pl={10}
              >
                <Box width={"36%"}>
                  <h1 className="movie-name">
                    {videoById[0]?.MovieName}{" "}
                    <span className="imdb">
                      IMDB {videoById[0]?.IMDbRating}
                    </span>
                  </h1>
                  <Typography
                    color={"#ffa500"}
                    fontSize={14}
                    fontWeight={600}
                    mb={1}
                  >
                    {year} <span className="divider">|</span>
                    {formattedDuration} <span className="divider">|</span>
                    {videoById[0]?.Language}
                  </Typography>
                  <Typography
                    color={"rgba(255, 255, 255, 1)"}
                    fontSize={14}
                    fontWeight={600}
                    
                    sx={{
                      display: "-webkit-box",
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxHeight: "3em", // Adjust as needed for line height and font size
                    }}
                  >
                    {videoById[0]?.Description}
                  </Typography>
                  {verify_purchase_movie === 1 || handleSetSubscription() ? (
                    ""
                  ) : (
                    <Typography
                      className="mv-paid"
                      padding={0.5}
                      mt={1}
                      fontWeight={700}
                      width={"16%"}
                    >
                      {videoById[0]?.IsPremium
                        ? `Pay ${videoById[0].PriceOfTheMovie}`
                        : "Free"}
                    </Typography>
                  )}
                  {(videoById[0]?.Category === "WebSeries" ||
                    videoById[0]?.Category === "WebSeries" ||
                    videoById[0]?.Category === "Anime") && (
                    <Typography
                      pb={0.5}
                      color={"#FFDA78"}
                      fontFamily={"Quicksand"}
                      fontWeight={600}
                      fontSize={18}
                    >
                      {videoById[0]?.Seasons[seasonNumber]?.SeasonId} -{" "}
                      {"Episode "}
                      {episodeNumber + 1}
                    </Typography>
                  )}
 
                  <Box 
                    width={{ xs: "90%", sm: "85%", md: "72%" }}
                    display={"flex"}
                    justifyContent={"space-between"}
                    mt={3}
                    gap={1}
                  >
                    {videoById[0]?.Category === "Webseries" ||
                    videoById[0]?.Category === "WebSeries" ||
                    videoById[0]?.Category === "Anime" ? (
                      <Button
                        variant="contained"
                        className="buttons"
                        sx={{ fontSize: 12 }}
                        startIcon={<FaPlay className="btn-icon" />}
                        onClick={() => onClickWatchWebSeriesBtn(videoById[0])}
                      >
                        Watch Episode
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        className="buttons"
                        startIcon={<FaPlay className="btn-icon" />}
                        onClick={() => onClickWatchBtn(videoById[0])}
                      >
                        Watch Movie
                      </Button>
                    )}
                    {/* <Button
                      sx={{ backgroundColor: "#FFDA78" }}
                      variant="contained"
                      className="buttons"
                      startIcon={
                        getFavoritesById !== 1 && (
                          <FaPlus className="btn-icon" />
                        )
                      }
                      endIcon={
                        getFavoritesById === 1 && (
                          <IoCheckmarkDoneCircle
                            size={18}
                            className="btn-icon"
                          />
                        )
                      }
                      onClick={() => handleFavoriteMovie(videoById[0].Id)}
                    >
                      {getFavoritesById === 1 ? "Favorite" : "Add Favorite"}
                    </Button> */}
                    {/* <Button
                      sx={{ backgroundColor: "#FFDA78" }}
                      variant="contained"
                      className="buttons"
                      startIcon={
                        getFavoritesById !== 1 && (
                          <FaPlus className="btn-icon" />
                        )
                      }
                      endIcon={
                        getFavoritesById === 1 && (
                          <IoCheckmarkDoneCircle
                            size={18}
                            className="btn-icon"
                          />
                        )
                      }
                      onClick={() => handleFavoriteMovie(videoById[0].Id)} // Use the correct ID
                    >
                      {getFavoritesById === 1 ? "Favorite" : "Add Favorite"}
                    </Button> */}
 
                    <Button
                      sx={{ backgroundColor: "#FFDA78" }}
                      variant="contained"
                      className="buttons"
                      startIcon={!isFavorite && <FaPlus className="btn-icon" />}
                      endIcon={
                        isFavorite && (
                          <IoCheckmarkDoneCircle
                            size={18}
                            className="btn-icon"
                          />
                        )
                      }
                      onClick={() => handleFavoriteMovie(videoById[0]?.Id)} // Ensure the correct ID is used
                    >
                      {isFavorite ? "Favorite" : "Add Favorite"}
                    </Button>
 
                    <IconButton onClick={handleClose}>
                      <ShareIcon sx={{ color: "#FFDA78" }} />
                    </IconButton>
 
                    <ShareModal openModal={openModal} closeIcon={handleClose} />
                  </Box>
                </Box>
              </Box>
            </div>
          </div>
 
          <Grid item bgcolor={"#010113"} minHeight={"30vh"}>
            {/* {============mobile responsive===============} */}
            <Box
              textAlign={"center"}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              <h1 className="movie-name">
                {videoById[0]?.MovieName}{" "}
                <span className="imdb">IMDB {videoById[0]?.IMDbRating}</span>
              </h1>
              <Typography
                color={"#ffa500"}
                fontSize={14}
                fontWeight={600}
                mb={1}
              >
                {year} <span className="divider">|</span>
                {formattedDuration} <span className="divider">|</span>
                {videoById[0]?.Language}
              </Typography>
 
              <Typography
                color={"rgba(255, 255, 255, 1)"}
                fontSize={14}
                fontWeight={600}
                sx={{
                  // display: "-webkit-box",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxHeight: "3em", // Adjust as needed for line height and font size
                  display: { xs: "none", md: "block" },
                }}
              >
                {videoById[0]?.Description}
              </Typography>
            </Box>
 
            <Box
              width={"100%"}
              // display={"flex"}
              gap={3}
              m={"auto"}
              mt={3}
              sx={{
                flexDirection: { xs: "row" },
                display: { xs: "flex", md: "none" },
                justifyContent: "center",
              }}
            >
              {videoById[0]?.Category === "Webseries" ||
              videoById[0]?.Category === "WebSeries" ||
              videoById[0]?.Category === "Anime" ? (
                <Button
                  variant="contained"
                  // className="buttons"
                  sx={{
                    bgcolor: "#FFDA78",
                    borderRadius: 2,
                    fontSize: 10,
                    fontWeight: 600,
                  }}
                  startIcon={<FaPlay style={{ fontSize: 10 }} />}
                  onClick={() => onClickWatchWebSeriesBtn(videoById[0])}
                >
                  Watch Episode
                </Button>
              ) : (
                <Button
                  variant="contained"
                  // className="buttons"
                  startIcon={<FaPlay style={{ fontSize: 10 }} />}
                  onClick={() => onClickWatchBtn(videoById[0])}
                  sx={{
                    bgcolor: "#FFDA78",
                    borderRadius: 2,
                    fontSize: 10,
                    fontWeight: 600,
                  }}
                >
                  Watch Movie
                </Button>
              )}
 
              {/* =================== Favourite & AddFavourite Button ===================== */}
              <Button
                sx={{
                  bgcolor: "#FFDA78",
                  borderRadius: 2,
                  fontSize: 10,
                  fontWeight: 600,
                }}
                variant="contained"
                // className="buttons"
                startIcon={!isFavorite && <FaPlus className="btn-icon" />}
                      endIcon={
                        isFavorite && (
                          <IoCheckmarkDoneCircle
                            size={18}
                            className="btn-icon"
                          />
                        )
                      }
                onClick={() => handleFavoriteMovie(videoById[0].Id)}
              >
                {getFavoritesById === 1 ? "Favorite" : "Add Favorite"}
              </Button>
 
              <div
                style={{
                  position: "absolute",
                  top: "14%",
                  right: 0,
                }}
              >
                <IconButton onClick={handleClose}>
                  <ShareIcon sx={{ color: "#fff" }} />
                </IconButton>
                <ShareModal openModal={openModal} closeIcon={handleClose} />
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "40%",
                  right: 0,
                }}
              >
                {verify_purchase_movie === 1 || handleSetSubscription() ? (
                  ""
                ) : (
                  <svg
                    width="116"
                    height="25"
                    viewBox="0 0 116 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {" "}
                    <path d="M116 0H0L16.5 13.125L0 25H116V0Z" fill="#FFDA78" />
                    <text
                      x="50%"
                      y="50%"
                      dominantBaseline="middle"
                      textAnchor="middle"
                      fill="black"
                      fontSize="12"
                      fontWeight="700"
                      width="16%"
                      padding="0.5"
                      mt="1"
                    >
                      {videoById[0]?.IsPremium
                        ? `Pay ${videoById[0].PriceOfTheMovie}`
                        : "Free"}
                    </text>
                  </svg>
                )}
              </div>
            </Box>
 
            {/* ==================== Description & Cast& crew ========================== */}
 
            <Box pt={2} pb={2} pl={4}>
              <Box sx={{ display: { xs: "block", md: "none", mb: 2 } }}>
                <Typography
                  color={"#FFDA78"}
                  fontFamily={"Quicksand"}
                  fontSize={13}
                  fontWeight={700}
                >
                  Description
                </Typography>
                <Typography
                  color={"#fff"}
                  fontFamily={"Quicksand"}
                  fontSize={10}
                  fontWeight={500}
                >
                  {videoById[0]?.Description}
                </Typography>
              </Box>
 
              <Typography
                color={"#FFDA78"}
                sx={{ fontSize: { xs: 13, md: 18 }, pt: { xs: 2, md: 0 } }}
                fontWeight={700}
                mb={2}
              >
                Cast & Crew
              </Typography>
 
              <Box
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                mb={2}
              >
                {videoById[0]?.cast?.map((each, index) => (
                  <>
                    <Box
                      key={index}
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      marginRight={5}
                    >
                      <img
                        src={each.ImageURL}
                        alt="cast-crew"
                        className={classes.castImg}
                      />
 
                      <Typography
                        // fontSize={12}
                        color={"#FFDA78"}
                        fontWeight={500}
                        sx={{ fontSize: { xs: 10, md: 12 } }}
                        mt={2}
                      >
                        {each.Role}
                      </Typography>
                      <Typography
                        sx={{ fontSize: { xs: 10, md: 14 } }}
                        color={"#FFDA78"}
                        fontWeight={500}
                        // alignContent={'center'}
                        maxWidth={"100%"}
                      >
                        {each.Charactor}
                      </Typography>
                    </Box>
                  </>
                ))}
              </Box>
            </Box>
 
            {/* ===============for webseries=============== */}
 
            {videoById[0]?.Seasons && videoById[0]?.Seasons?.length > 0 && (
              <Grid bgcolor={"#010113"} sx={{ p: { xs: 0, sm: 3, md: 6 } }}>
                <Box display={"flex"} justifyContent={"flex-end"}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    sx={{ m: 1, minWidth: 150 }}
                  >
                    <Select
                      value={selectedSeason}
                      onChange={handleChangeSeason}
                      displayEmpty
                      IconComponent={ArrowDropDownIcon}
                      renderValue={(selected) => {
                        if (!selected) {
                          return (
                            <Button sx={{ borderRadius: "2px" }}>
                              Seasons
                            </Button>
                          );
                        }
                        return selected;
                      }}
                      sx={{
                        color: "black",
                        bgcolor: "#FFDA78",
                        borderRadius: 2,
                        height: {
                          xs: "5vh",
                        },
                      }}
                    >
                      {videoById[0]?.Seasons?.map((episode, index) => (
                        <MenuItem
                          key={index}
                          value={index}
                          selected={selectedSeason === index}
                          sx={{
                            bgcolor:
                              selectedSeason === index
                                ? "#FFDA78"
                                : "transparent",
                            color:
                              selectedSeason === index ? "black" : "inherit",
                            "&:hover": {
                              bgcolor: "#FFDA78",
                            },
                          }}
                        >
                          {episode.SeasonId}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Grid
                  sx={{ pt: { xs: 4, md: 8 } }}
                  display={"flex"}
                  flexDirection={"column"}
                >
                  {videoById[0]?.Seasons[seasonNumber]?.Episodes?.map(
                    (episode, index) => (
                      <Grid
                        key={index}
                        display={"flex"}
                        flexDirection={"row"}
                        gap={3}
                        marginBottom={5}
                        sx={{
                          cursor: "pointer",
                          p: { xs: 2, md: 0 },
                          mb: { xs: 0, md: 4 },
                        }}
                        onClick={() => setEpisodeNumber(index)}
                      >
                        <Box
                          sx={{
                            border: 2,
                            borderColor: "#FFDA78",
                            borderRadius: 2,
                            width: { xs: "40%", md: "30%", lg: "18%" },
                            height: {
                              xs: "80px",
                              md: "120px",
                              md: "150px",
                              lg: "160px",
                            },
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src={episode.EpisodeImage}
                            style={{
                              height: "100%",
                              width: "100%",
                              borderRadius: 8,
                            }}
                            // src="https://bucketblob.iott.co.in/images/cast&crew/Rasikadugal.jpg"
                            className="series-card"
                            alt={`Episode ${index + 1}`}
                          />
                        </Box>
 
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          sx={{
                            width: { xs: "60%", md: "62%", lg: "50%" },
                            p: { xs: 0, md: 2 },
                          }}
                        >
                          <Typography
                            pb={0.5}
                            color={"#fff"}
                            fontFamily={"Quicksand"}
                            fontWeight={700}
                            // fontSize={20}
                            sx={{ fontSize: { xs: 12, md: 18 } }}
                          >
                            {episode.EpisodeTitle}
                          </Typography>
                          <Typography
                            pb={0.5}
                            color={"#FFDA78"}
                            fontFamily={"Quicksand"}
                            fontWeight={600}
                            // fontSize={18}
                            sx={{ fontSize: { xs: 10, md: 15, md: 20 } }}
                          >
                            {videoById[0]?.Seasons[seasonNumber]?.SeasonId} -{" "}
                            {episode.EpisodeNo} | {episode.EpisodeName}
                          </Typography>
 
                          <Typography
                            pb={0.5}
                            color={"red"}
                            fontFamily={"Quicksand"}
                            fontWeight={500}
                            // fontSize={15}
                            sx={{ fontSize: { xs: 10, md: 15, md: 20 } }}
                          >
                            {episode.Duration}{" "}
                            {episode?.Language ? `| ${episode?.Language}` : ""}
                          </Typography>
 
                          <Typography
                            pb={0.5}
                            color={"#fff"}
                            fontFamily={"Quicksand"}
                            fontWeight={500}
                            fontSize={15}
                            sx={{
                              display: "-webkit-box",
                              WebkitLineClamp: 4,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              fontSize: { xs: 10, md: 12, md: 15 },
                              textOverflow: "ellipsis",
                              maxHeight: "4em", // Adjust as needed for line height and font size
                            }}
                          >
                            {videoById[0]?.Description}
                          </Typography>
                        </Box>
                      </Grid>
                    )
                  )}
                </Grid>
              </Grid>
            )}
 
            {(videoById[0]?.Seasons === null ||
              videoById[0]?.Seasons === undefined) && (
              <>
                <Typography
                  variant="h6"
                  color={"#FFDA78"}
                  fontWeight={700}
                  pl={4}
                >
                  Related
                </Typography>
                <Grid mt={1}>
                  <HomeGenerSlider gener={videoById[0]?.Genre} title="" />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      )}
 
      {videoByIdFetchStatus === statusCodes.ERROR && (
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          minHeight={"70vh"}
          color={"#e2e2e2"}
        >
          <h1>Something went wrong try again..!</h1>
        </Grid>
      )}
      {/* ====================== if user not by the movie to show the wallet for buy a movie but he has insufficentbalance=============== */}
 
      <Dialog open={dialogOpen} onClose={handleToClose}>
        <DialogContent>
          <Box p={2}>
            <Typography variant="h6" align="center" gutterBottom>
              Insufficient Balance in your Wallet!
            </Typography>
            <Typography variant="body1" align="center" gutterBottom>
              Wallet Balance: {wallet_amount ? wallet_amount : 0}
            </Typography>
            <Typography variant="body1" align="center" gutterBottom>
              Bonus Balance:{" "}
              {bouns_amount?.BonusAmount ? bouns_amount?.BonusAmount : 0}
            </Typography>
            <Grid container spacing={2} justifyContent="space-between" mt={2}>
              <Grid item>
                <Button
                  onClick={() => handleNavigateWallet()}
                  variant="contained"
                  color="primary"
                >
                  Add Money
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleToClose}
                  variant="contained"
                  color="secondary"
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
 
      {/* ====================== if user not by the movie to show the wallet for buy a movie=============== */}
 
      <Dialog open={dialogOpen1} onClose={handleToClose1}>
        <DialogContent>
          <Box p={2}>
            <Typography variant="h5" gutterBottom>
              {videoById[0]?.PriceOfTheMovie} ₹ (Movie Price)
            </Typography>
            <Box mb={2}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={8}>
                  <Typography variant="subtitle1">Wallet Balance</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Wallet Balance: {wallet_amount ? wallet_amount : 0}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="wallet"
                      name="wallet"
                      value={selectWallet}
                      onChange={handleSelectWallet}
                    >
                      <FormControlLabel value="No" control={<Radio />} />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={8}>
                  <Typography variant="subtitle1">Wallet Bonus</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Bonus Balance:{" "}
                    {bouns_amount?.BonusAmount ? bouns_amount?.BonusAmount : 0}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="bonus"
                      name="bonus"
                      value={selectBonus}
                      onChange={handleSelectBonus}
                    >
                      <FormControlLabel value="Yes" control={<Radio />} />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
 
            {/* ====================Cancel  & Proceed====================== */}
 
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item>
                <Button
                  onClick={handleToClose1}
                  variant="contained"
                  color="secondary"
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleProceed(videoById[0]?.PriceOfTheMovie)}
                >
                  Proceed
                </Button>
              </Grid>
            </Grid>
            <Grid sx={{ textAlign: "center", mt: 3 }}>
              <Button
                onClick={() => {
                  handleNavigateToSubscrption();
                }}
                sx={{ color: "#000" }}
              >
                Subscriptions
              </Button>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
 
      {addFavoriteFetchStatus === statusCodes.LOADING && (
        <Loader
          message={
            getFavoritesById === 1
              ? "Adding to collections, please wait.."
              : "Removing from collections.."
          }
        />
      )}
      <Footer />
    </Box>
  );
};
 
export default DetailedPage;
 
 
 