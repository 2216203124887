import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../utils/statusCodes";
import endpoints from "../../Endpoints/endpoints"; 

const getOrderIdApi = endpoints.getOrderId 

const initial_getOrderId = { getOrderId: {}, getOrderIdFetchStatus: statusCodes.IDLE };

const getOrderIdReducer = createSlice({
  name: "getOrderIdReducer",
  initialState: initial_getOrderId,
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetOrderId.pending, (state, action) => {
        state.getOrderIdFetchStatus = statusCodes.LOADING;
        // console.log("Privacy Policy data pending");
      })
      .addCase(fetchGetOrderId.fulfilled, (state, action) => {
        state.getOrderId = action.payload.data;
        state.getOrderIdFetchStatus = statusCodes.SUCCESS;
        // console.log(state.getOrderId);
      })
      .addCase(fetchGetOrderId.rejected, (state, action) => {
        state.getOrderIdFetchStatus = statusCodes.ERROR;
        // console.log("Privacy Policy data error");
      });
  },
});


export const fetchGetOrderId = createAsyncThunk("getOrderId/get", async () => {

 
  try {
    const getOrderIdData = await axios.get(getOrderIdApi);
    return getOrderIdData;
  } catch (error) {
    const errorPayload = {
      message: error.response.data.message,
    };
    throw errorPayload;
  }


});

export default getOrderIdReducer.reducer;
