import React, { useEffect } from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAuthData } from "../../utils/auth";
import { fetchAllFavorites } from "../../Redux/reducers/favorite/getAllFavoritesReducer";
import statusCodes from "../../utils/statusCodes";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { setMovieState } from "../../Redux/reducers/videos/getVideoByIdReducer";

const UserCollections = () => {
  const authData = getAuthData();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Assuming Redux state setup correctly
  const { get_all_favorites, getAllFavoritesFetchStatus } = useSelector(
    (state) => state.rootReducer.getAllFavoritesReducer
  );
//  console.log(get_all_favorites)
  useEffect(() => {
    if (authData?.auth?.Id) {
      dispatch(fetchAllFavorites(authData?.auth?.Id));
    }
  }, []);

  const handleCollectionMovie = (item) => {
    const formattedMovieName = item.MovieName.replace(/\s+/g, "_");
    // console.log(item);
    dispatch(setMovieState(item));
    navigate(`/details/${formattedMovieName}`);
  };

  if (getAllFavoritesFetchStatus === statusCodes.LOADING) {
    return (
      <>
        <Header />
        <Grid
          container
          bgcolor={"#1c1c30"}
          display={"flex"}
          flexDirection={"column"}
          gap={1}
          minHeight={"60vh"}
        >
          <Grid p={2}>
            <Typography
              variant={{ xs: "body2", md: "h6" }}
              color={"#FFDA78"}
              fontWeight={"bold"}
              fontSize={'20px'}
            >
              My Collections
            </Typography>
          </Grid>
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <CircularProgress color="warning" />
          </Grid>
          <Footer />
        </Grid>
      </>
    );
  }

  if (getAllFavoritesFetchStatus === statusCodes.SUCCESS) {
    return (
      <>
        <Header />
        <Grid
          container
          bgcolor={"#1c1c30"}
          display={"flex"}
          flexDirection={"column"}
          gap={1}
          minHeight={"70vh"}
        >
          <Grid p={2}>
            <Typography
              variant={{ xs: "body2", md: "h6" }}
              color={"#FFDA78"}
              fontWeight={"bold"}
              fontSize={'20px'}
            >
              My Collections
            </Typography>
          </Grid>
          <Grid
            container
            xs={12}
            justifyContent={"center"}
            alignItems={"center"} 
          >
            <Grid
              container
              xs={11}
              justifyContent={"center"}
              spacing={{ xs: 4, sm:2 }}
            >
              {get_all_favorites.length > 0 &&
              get_all_favorites[0]?.message === undefined ? (
                get_all_favorites.map((item) => (
                  <Grid item xs={6} sm={4} md={3} lg={2} key={item.Id}>
                    <Box
                      sx={{ textAlign: "center", cursor: "pointer" }}
                      onClick={() => handleCollectionMovie(item)}
                    >
                      <img
                        src={item.MoviePoster}
                        alt={item.MovieName}
                        loading="lazy"
                        style={{
                          maxWidth: "100%",
                          border: "2px solid #FFDA78",
                          borderRadius: "8px",
                        }}
                      />
                      <Typography
                        variant="subtitle1"
                        sx={{ mt: 1, color: "#FFF", }}
                      >
                        {item.MovieName}
                      </Typography>
                    </Box>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12} p={2} style={{ textAlign: "center" }}>
                  <Typography
                    fontFamily={"Quicksand"}
                    fontSize={30}
                    fontWeight={600}
                    color={"#FFF"}
                  >
                    No movie collections found.
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Footer />
        </Grid>
      </>
    );
  }

  if (getAllFavoritesFetchStatus === statusCodes.ERROR) {
    return (
      <>
        <Header />
        <Grid
          container
          bgcolor={"#1c1c30"}
          display={"flex"}
          flexDirection={"column"}
          gap={1}
          minHeight={500}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={12} p={2} style={{ textAlign: "center" }}>
            <Typography
              variant={{ xs: "body2", md: "h6" }}
              color={"#FFDA78"}
              fontWeight={"bold"}
              fontSize={'20px'}
            >
              My Collections
            </Typography>
            <Typography
              fontFamily={"Quicksand"}
              fontSize={30}
              fontWeight={600}
              color={"#FFF"}
            >
              Failed to load collections. Please try again later.
            </Typography>
          </Grid>
          <Footer />
        </Grid>
      </>
    );
  }

  // Default case if none of the above conditions are met (should ideally not happen)
  return null;
};

export default UserCollections;
