
import {
  Box,
  FormControl,
  Input,
  InputAdornment,
  InputBase,
  alpha,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  clearSearchResults,
  fetchSearchMovies,
} from "../../Redux/reducers/search/searchReducer";
import { useDispatch } from "react-redux";
import { fetchSuggestedMovies, resetSuggestedMovies } from "../../Redux/reducers/suggestions/getSuggestedMoviesReducer";
import { getAuthData } from "../../utils/auth";
import { getAuthToken } from "../../utils/getAuthToken";
 
const Searchbar = () => {
  const authToken = JSON.parse(localStorage.getItem("iottToken"));
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
 
  useEffect(() => {
    if (authToken === "" || authToken === null || authToken === undefined) {
      getAuthToken();
    }
  }, []);
 
  useEffect(() => {
    if (searchValue && authToken) {
      const searchJSON = { searchValue, authToken };
      const searchTimer = setTimeout(() => { 
        dispatch(fetchSearchMovies(searchJSON));
      }, 500);
      return () => clearTimeout(searchTimer);
    } else {
      dispatch(clearSearchResults());  
      dispatch(resetSuggestedMovies());
      const fetchSuggestedMoviesWithCacheBuster = () => {
        dispatch(fetchSuggestedMovies());
      };
      fetchSuggestedMoviesWithCacheBuster();
    }
  }, [searchValue,authToken, dispatch]);
 
  return (
    <Box mr={2} width={{ xs: "70%", md: "50%" }}>
      <FormControl fullWidth sx={{ padding: 0.5 }} variant="standard">
        <Input
          id="standard-adornment-amount"
          placeholder="Type here to search"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          sx={{
            color: "#000",
            fontWeight: 600,
            bgcolor: "#FFDA78",
            borderRadius: 2,
            border: "none",
            "&:before": {
              // This removes the default bottom border
              borderBottom: "none",
            },
            "&:after": {
              // This removes the default bottom border
              borderBottom: "none",
            },
            "&:hover:not(.Mui-disabled):before": {
              // This removes the border on hover
              borderBottom: "none",
            },
          }}
          startAdornment={
            <InputAdornment position="start">
              {" "}
              <SearchIcon sx={{ color: "#000", p: 0.5 }} />
            </InputAdornment>
          }
        />
      </FormControl>
    </Box>
  );
};
 
export default Searchbar;
 
 