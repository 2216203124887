import { Grid, Box, Typography, Button, Tooltip } from "@mui/material";
import React, { useRef, useEffect, useState } from "react";
import { FaPlay, FaPlus } from "react-icons/fa";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOff from "@mui/icons-material/VolumeOff";
import IconButton from "@mui/material/IconButton";
import "./index.css";
import HomeBannerSlider from "../HomeBannerSlider";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchVideoById,
  setMovieState,
} from "../../Redux/reducers/videos/getVideoByIdReducer";
import { useNavigate } from "react-router-dom";
import { fetchFavoritesByMovieId } from "../../Redux/reducers/favorite/getFavoriteByMovieIdReducer";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { fetchAddFavorite } from "../../Redux/reducers/favorite/addFavoriteReducer";
import { getAuthData } from "../../utils/auth";
import Loader from "../Loader";
import statusCodes from "../../utils/statusCodes";
import getVedioByIdBannerReducer, { fetchVideoByIdBanner } from './../../Redux/reducers/videos/getVedioByIdBannerReducer';
 
const MainBanners = () => {
 
  const authData = getAuthData();
 
  const { dashboard_banners } = useSelector(
    (state) => state.rootReducer.dashboardBannersReducer
  );
 
  const dashboard_banners_Id = dashboard_banners.map((i) => i.Id);
  // console.log(dashboard_banners);
 
  // const { videoById } = useSelector(
  //   (state) => state.rootReducer.getVideoByIdReducer
  // );
  // console.log(videoById);
 
  const { videoByIdBanner } = useSelector(
    (state) => state.rootReducer.getVedioByIdBannerReducer
  );
  // console.log(videoByIdBanner);
 
  const { getFavoritesById } = useSelector(
    (state) => state.rootReducer.getFavoriteByMovieIdReducer
  );
 
  const { addFavoriteFetchStatus } = useSelector(
    (state) => state.rootReducer.addFavoriteReducer
  );
 
  const dispatch = useDispatch();
  const videoRef = useRef(null);

  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const [videoRimg, setVideoRimg] = useState(true);
  const [autoplayAllowed, setAutoplayAllowed] = useState(false);
  const [videoMuted, setVideoMuted] = useState(true);
  const [activeMovieId, setActiveMovieId] = useState(dashboard_banners[0]?.Id);
 
  const navigate = useNavigate();
 
  useEffect(() => {
    if (dashboard_banners.length > 0) {
      setActiveMovieId(dashboard_banners[0]?.Id);
 
      const fetchVideoByIdJSON = {
        fetchedMovieId: dashboard_banners[0]?.Id,
        categoryValue: dashboard_banners[0]?.Category,
      };
      dispatch(fetchVideoByIdBanner(fetchVideoByIdJSON));
    }
  }, [dashboard_banners, dispatch]);
 
  useEffect(() => {
    const clearMv = setTimeout(() => {
      setVideoRimg(false);
      setAutoplayAllowed(true);
    }, 3000);
    return () => clearTimeout(clearMv);
  }, []);
 
  const playFromSpecificDuration = () => {
    if (videoRef.current) {
      const startTime = 22 * 60; // 22 minutes
      const endTime = 24 * 60; // 24 minutes
      videoRef.current.currentTime = startTime;
      videoRef.current.play();
      videoRef.current.addEventListener("timeupdate", function () {
        if (this.currentTime >= endTime) {
          this.pause();
        }
      });
    }
  };
 
  useEffect(() => {
    if (autoplayAllowed && videoByIdBanner.length > 0) {
      playFromSpecificDuration();
    }
  }, [autoplayAllowed, videoByIdBanner]);
 
  useEffect(() => {
    const verifyPurchaseMovieJSON = {
      userId: authData?.auth?.Id,
      movieId: videoByIdBanner[0]?.Id,
    };
    dispatch(fetchFavoritesByMovieId(verifyPurchaseMovieJSON));
  }, [addFavoriteFetchStatus, videoByIdBanner[0]?.Id]);
 
  const handleFavoriteMovie = (movieId) => {
    if (authData?.auth) {
      const addFavoriteJson = {
        UserId: authData?.auth?.Id,
        MovieId: movieId,
      };
      dispatch(fetchAddFavorite(addFavoriteJson));
    } else {
      navigate("/login");
    }
  };
 
  const handlePlayButtonClick = () => {
    setAutoplayAllowed(true);
    setVideoMuted(!videoMuted);
  };
 
  const onClickHomeBannerSliderImg = (movieDetails) => {
    setActiveMovieId(movieDetails.Id);
    // console.log(movieDetails);
    const fetchVideoByIdJSON = {
      fetchedMovieId: movieDetails.Id,
      categoryValue: movieDetails.Category,
    };
    dispatch(fetchVideoByIdBanner(fetchVideoByIdJSON));
  };
 
  const dateTimeString = videoByIdBanner[0]?.ReleasedYear;
 
  const newDate = new Date(dateTimeString);
  const year = newDate.getFullYear();
 
  // const duration = Duration
  const durationString = videoByIdBanner[0]?.Duration || "00:00";
 
  const [hours, minutes] = durationString.split(":");
 
  const formattedDuration = `${parseInt(hours, 10)}hr ${parseInt(
    minutes,
    10
  )}mins`;
 
  // // console.log(formattedDuration);
 
  const onClickMvWatchBtn = (movie) => {
    // const encId = btoa(videoById[0]?.Id);
    // navigate(`/details/${encId}`);
    const formattedMovieName = videoByIdBanner[0]?.MovieName?.replace(/\s+/g, "_");
    const movieJson = {
      Category: movie?.Category,
      Id: movie?.Id,
      MovieName: movie?.MovieName,
      MoviePoster: movie?.MoviePoster,
    };
    dispatch(setMovieState(movieJson));
    navigate(`/details/${formattedMovieName}`);
    // navigate(`/details/${formattedMovieName}`, {
    //   state: videoById[0]?.Id,
    // });
  };
 
  return (
    <Box
      sx={{
        position: "relative",
        width: "inherit",
        height: {
          xs: "35vh", // Adjust the height for extra-small screens
          sm: "60vh", // Adjust the height for small screens
          md: "100%", // Default height for medium and larger screens
        },
      }}
    >

{isVideoLoading && videoRimg ? (
        <Loader />  
      ) : 
      videoRimg ? (
        <img
          src={videoByIdBanner[0]?.WebPoster}
          onLoad={() => setVideoRimg(false)}   
          alt="banners"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
      ) : (
        <video
          key={videoByIdBanner[0]?.Quality720}
          ref={videoRef}
          autoPlay={autoplayAllowed}
          muted={videoMuted}
          width="100%"
          height="100%"
          onLoadedData={() => setIsVideoLoading(false)}  
          onEnded={() => setVideoRimg(true)}            
        >
          <source src={videoByIdBanner[0]?.Quality720} type="video/mp4" />
        </video>
      )}


      {/* {
      
      videoRimg ? (
        <img
          src={videoByIdBanner[0]?.WebPoster}
          onLoad={() => setVideoRimg(false)}
          alt="banners"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
      ) : (
        <video
          key={videoByIdBanner[0]?.Quality720}
          ref={videoRef}
          autoPlay={autoplayAllowed}
          muted={videoMuted}
          width="100%"
          height="100%"
          onLoadedData={() => setIsVideoLoading(false)}
          onEnded={() => setVideoRimg(true)}
        >
          <source src={videoByIdBanner[0]?.Quality720} type="video/mp4" />
        </video>
      )} */}
 
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100%",
          background:
            "linear-gradient(to top, rgba(0,0,0,0.92) 0%,rgba(0,0,0,0.74) 35%,rgba(0,0,0,0.66) 45%,rgba(0,0,0,0.58) 50%,rgba(0,0,0,0) 100%)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          overflowX: "hidden",
        }}
        sx={{ height: { xs: "100%", sm: "100%" } }}
      >
        <Box
          width={"95%"}
          display={"flex"}
          justifyContent={"space-evenly"}
          pb={0}
          sx={{ p: { xs: 0, sm: 5 } }}
        >
          <Box width={"36%"} sx={{ display: { xs: "none", md: "block" } }} mb={20}>
            <h1 className="movie-name">
              {videoByIdBanner[0]?.MovieName}{" "}
              <span className="imdb">IMDB {videoByIdBanner[0]?.IMDbRating}</span>
            </h1>
            <Typography
              color={"rgba(255, 255, 255, 0.9)"}
              fontSize={20}
              fontWeight={600}
              mb={1}
            >
              {year} <span className="divider">|</span>
              {formattedDuration} <span className="divider">|</span>
              {videoByIdBanner[0]?.Language}
            </Typography>
            <Typography
              color={"rgba(255, 255, 255, 1)"}
              fontSize={'18px'}
              fontWeight={600}
              fontFamily={'Quicksand'}
              sx={{
                display: "-webkit-box",
                WebkitLineClamp: 4,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxHeight: "3em", // Adjust as needed for line height and font size
              }}
            >
              {videoByIdBanner[0]?.Description}
            </Typography>
 
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"space-between"}
              mt={3}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <Button
                variant="contained"
                className="buttons"
                onClick={() => onClickMvWatchBtn(videoByIdBanner[0])}
                startIcon={<FaPlay className="btn-icon" />}
                sx={{mr:5}}
              >
                Watch
              </Button>
              <Button
                sx={{ backgroundColor: "#FFDA78" }}
                variant="contained"
                className="buttons"
                startIcon={
                  getFavoritesById !== 1 && <FaPlus className="btn-icon" />
                }
                endIcon={
                  getFavoritesById === 1 && (
                    <IoCheckmarkDoneCircle size={18} className="btn-icon" />
                  )
                }
                onClick={() => handleFavoriteMovie(videoByIdBanner[0]?.Id)}
              >
                {getFavoritesById === 1 ? "Favorite" : "Add Favorite"}
              </Button>
            </Box>
 
          </Box>
 
          <Box
            // width={"50%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-end"}
            alignItems={"flex-end"}
            pt={0}
            pb={0}
            sx={{ width: { xs: "100%", sm: "50%" }, pr: { xs: 0, sm: 4 } }}
          >
            <Tooltip
              title={videoMuted ? "Volume Off" : "Volume On"}
              placement="left"
            >
              <IconButton aria-label="volumeUp" onClick={handlePlayButtonClick}>
                {videoMuted ? (
                  <VolumeOff
                    sx={{ fontSize: { xs: "15px", sm: "30px" }, color: "#fff" }}
                  />
                ) :
                 (
                  <VolumeUpIcon
                    sx={{ fontSize: { xs: "15px", sm: "30px" }, color: "#fff" }}
                  />
                )}
              </IconButton>
 
            </Tooltip>
            <Box
              sx={{
                display: { xs: "block", md: "none" },
                mr: { xs: "15%",   },
                width: "70%",
                spacing: 2,
                mt: "auto",
              }}
            >
              <Button
                variant="contained"
                // className="buttons"
                onClick={() => onClickMvWatchBtn(videoByIdBanner[0])}
                startIcon={<FaPlay className="btn-icon" color="#000" />}
                sx={{
                  width: "100%",
                  bgcolor: "rgba(255,218,120,0.8)",
                  color: "#000",
                  fontWeight: 100,
                  borderRadius: 2,
                  mb: 1,
                  fontSize: 10,
                }}
              >
                Watch movie
              </Button>
             
              <Button
                sx={{
                  width: "100%",
                  bgcolor: "rgba(255,218,120,0.8)",
                  color: "#000",
                  fontWeight: 100,
                  borderRadius: 2,
                  fontSize: 10,
                }}
                variant="contained"
                // className="buttons"
                startIcon={
                  getFavoritesById !== 1 && <FaPlus className="btn-icon" />
                }
                endIcon={
                  getFavoritesById === 1 && (
                    <IoCheckmarkDoneCircle size={18} className="btn-icon" />
                  )
                }
                onClick={() => handleFavoriteMovie(videoByIdBanner[0]?.Id)}
              >
                {getFavoritesById === 1 ? "Favorite" : "Add Favorite"}
              </Button>
            </Box>
 
            <HomeBannerSlider onClickHomeBannerSliderImg={onClickHomeBannerSliderImg} />
 
          </Box>
        </Box>
      </div>
 
      {addFavoriteFetchStatus === statusCodes.LOADING && (
        <Loader
          message={
            getFavoritesById === 1
              ? "Removing from collections.."
              : "Adding to collections.."
          }
        />
      )}
    </Box>
  );
};
 
export default MainBanners;
 
 