import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes";
import endpoints from "../../../Endpoints/endpoints"; 

 
const searchCollectionApi = `${endpoints.searchCollection}?Name=Checkpost`

const initialFavoriteByName = { getFavoritesByName: [], getFavoritesByMovieNameFetchStatus: statusCodes.IDLE };

const getFavoriteByMovieNameReducer = createSlice({
  name: "getFavoriteByMovieNameReducer",
  initialState: initialFavoriteByName,
  extraReducers: (builder) => {
    builder
      .addCase(fetchFavoritesByMovieName.pending, (state, action) => {
        state.getFavoritesByMovieNameFetchStatus = statusCodes.LOADING;
        // console.log("get favorites by movie name data pending");
      })
      .addCase(fetchFavoritesByMovieName.fulfilled, (state, action) => {
        state.getFavoritesByName = action.payload.data;
        state.getFavoritesByMovieNameFetchStatus = statusCodes.SUCCESS;
        // console.log(state.getFavoritesByName);
      })
      .addCase(fetchFavoritesByMovieName.rejected, (state, action) => {
        state.getFavoritesByMovieNameFetchStatus = statusCodes.ERROR;
        // console.log("get favorites by movie name data error");
      });
  },
});


export const fetchFavoritesByMovieName = createAsyncThunk("favoritesByMovieName/get", async () => {

 
  try {
    const favoritesByMovieName = await axios.get(searchCollectionApi);
    return favoritesByMovieName;
  } catch (error) {
    const errorPayload = {
      message: error.response.data.message,
    };
    throw errorPayload;
  }


});

export default getFavoriteByMovieNameReducer.reducer;
