import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useState, useEffect } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

import { Link, useLocation, useNavigate } from "react-router-dom";

import endpoints from "../../../Endpoints/endpoints";

import axios from "axios";

import "./index.css";

import { Formik } from "formik";
import * as Yup from "yup";

import { auth } from "../../../utils/firebaseConfig";

import { confirmPasswordReset } from "firebase/auth";
import Loader from "../../../components/Loader";

function useQuery() {
  const location = useLocation();

  return new URLSearchParams(location.search);
}

const ResetPassword = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const modeEvent = query.get("mode");

  const [showPassword, setShowPassword] = useState(false);

  const [passwordReset, setPasswordReset] = useState(false);

  const [onClickBtn, setOnClickBtn] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    let userEmail = localStorage.getItem("userEmail");

    const object = { Email: userEmail };
    axios
      .post(endpoints.RegisterEmailVerification, object)

      .then((res) => {
        // console.log(res.data[0].message);
      })

      .catch((e) => {
        // console.log(e.message);
      });
  }, []);

  const validateSchema = Yup.object({
    email: Yup.string()
      .required("Enter a Email")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      ),

    password: Yup.string()
      .required("Enter Password")
      .min(8, "Password length should be 8 Letters")
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])/,
        "Password invalid follow Ex-Password: eXamplE#1234"
      ),
  });

  return (
    <Grid container className="bg-image" height={"100vh"} width={"100vw"}>
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box
          sx={{ width: { xs: "70%", sm: "30%", lg: "25%" } }}
          minHeight={"50vh"}
          bgcolor={"#010113"}
          border={"5px solid #FFDA78"}
          borderRadius={6}
        >
          <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            pt={2}
            pb={4}
          >
            <img
              src={"https://bucketblob.iott.co.in/Icons/logo.png"}
              alt="logo"
              className="logo"
              onClick={() => navigate("/")}
            />

            <>
              <Typography
                mb={1}
                fontWeight={600}
                fontSize={20}
                color={"#FFDA78"}
                fontFamily={"Quicksand"}
              >
                RESET PASSWORD
              </Typography>

              <Formik
                initialValues={{
                  email: "",

                  password: "",
                }}
                validationSchema={validateSchema}
                onSubmit={(values) => {
                  // console.log("Form Reset Password Values:", values);
                  setPasswordReset("saving..");

                  confirmPasswordReset(query.get("oobCode"), values.password)
                    .then((response) => {
                      // console.log(response);
                    })
                    .catch((e) => {
                      // console.log(e.message);
                    });

                  const object = {
                    Email: values.email,
                    Password: values.password,
                  };

                  axios
                    .post(endpoints.PasswordSave, object)

                    .then((res) => {
                      setPasswordReset("Password Reset Successfull");
                    })
                    .catch((e) => {
                      // console.log(e.response.data);
                      setPasswordReset(e.response.data[0].message);
                    });
                  //   setEmail("");
                  //   setPassword("");
                }}
              >
                {(formik) => {
                  return (
                    <form
                      onSubmit={formik.handleSubmit}
                      className="resetPassword-form"
                    >
                      <TextField
                        sx={{ mb: 1, mt: 2 }}
                        fullWidth
                        placeholder="Enter Email"
                        variant="standard"
                        {...formik.getFieldProps("email")}
                        helperText={formik.touched.email && formik.errors.email}
                      />

                      <TextField
                        sx={{ mb: 2 }}
                        fullWidth
                        placeholder="Enter New Password"
                        variant="standard"
                        {...formik.getFieldProps("password")}
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <VisibilityOff
                                    style={{
                                      color: "#FFDA78",
                                      fontSize: "1.2rem",
                                    }}
                                  />
                                ) : (
                                  <Visibility
                                    style={{
                                      color: "#FFDA78",
                                      fontSize: "1.2rem",
                                    }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />

                      <Box alignSelf={"center"} width={"75%"} mt={5} mb={3}>
                        <Button
                          fullWidth
                          type="submit"
                          onClick={() => setPasswordReset(true)}
                        >
                          submit
                        </Button>
                      </Box>
                    </form>
                  );
                }}
              </Formik>

              {passwordReset ? (
                <>
                  <Typography color={"#13FF13"}>{passwordReset}</Typography>
                  <span>
                    <Link
                      to="/login"
                      style={{
                        color: "#FFDA78",
                        textDecoration: "underline",
                        fontFamily: "Quicksand",
                        fontSize: "12px",
                      }}
                    >
                      Login
                    </Link>
                  </span>{" "}
                </>
              ) : (
                ""
              )}

              <Box
                mt={5}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <CheckCircleRoundedIcon
                  sx={{ color: "green", marginRight: "10px" }}
                />
                <Typography color={"#fff"}>
                  Verified Your Email Successfully
                </Typography>
                <br />
              </Box>
            </>
            {passwordReset === "saving.." && (
              <Loader message={"wait a moment.."} />
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
