import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import Header from "../../components/Header";
import { PiWalletFill } from "react-icons/pi";
import { MdCurrencyRupee } from "react-icons/md";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import "./index.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isActiveSearchBar } from "../../Redux/reducers/searchActiveReducer";
import { fetchTransactionDetails } from "../../Redux/reducers/wallet/getTransactionDetailsReducer";
import ScratchCard from "../../components/ScratchCard"; // Adjust the path to where your App component is located
import axios from "axios";
import Footer from "../../components/Footer";
import { getAuthData } from "../../utils/auth";
import endpoints from "../../Endpoints/endpoints";
import { GrInProgress } from "react-icons/gr";
import { fetchWalletAmount } from "../../Redux/reducers/wallet/getWalletAmountReducer";
import { mdi_banking } from "./mdi_bank.png";
import { BsBank } from "react-icons/bs";
import { FaSackDollar } from "react-icons/fa6";
import { fetchReferralDetails } from "../../Redux/reducers/referAndEarn/referralDetailsRequestReducer";

const Wallet = () => {
  const dispatch = useDispatch();
  const authData = getAuthData();
  const [isOptionChosen, setIsOptionChosen] = useState(false);
  const [isScratched, setIsScratched] = useState(false);
  const [data, setData] = useState(null);
  const [billdata, setBillData] = useState(null);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [view, setView] = useState("Movie History"); // Possible values: "bill", "subscription", or null

  const [amount, setAmount] = useState("");
  const [selectedAmount, setSelectedAmount] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [OrderId, setOrderId] = useState("");
  const [rewardsData, setRewardsData] = useState([]);
  const [errorReward, seterrorReward] = useState("");

  const [activeView, setActiveView] = useState("bank");

  const [snackstatus, setSnackstatus] = useState(false);
  // const [subscriptionStatus, setSubscriptionStatus] = useState([])

  console.log(billdata);

  // useEffect(()=>{
  //   const CheckSubscriptionStatus = async () => {
  //     try {
  //       const response = await axios.get(
  //         `https://api.iott.co.in/api/videos/CheckSubscriptionStatus?userId=${authData?.auth?.Id}`);
  //       // console.log("Response from reward data:", response.data, `https://api.iott.co.in/api/referral/Get_Rewardhistory?userid=${authData?.auth?.Id}` );
  //       const data = response.data;
  //       // const activeSubscription=(data.some((item)=>item.IsSubscribed));
  //       const activeSubscription =data.map((data)=>data.IsSubscribed)
  //       console.log(data.map((data)=>data.IsSubscribed));
  //       setSubscriptionStatus(activeSubscription);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       // seterrorReward("Something went wrong");
  //     }
  //   };
  //   CheckSubscriptionStatus();

  // },[])

  useEffect(() => {
    if (activeView === "bill") {
      setView("Movie History"); // Set the default view to Movie History when activeView is bill
    }
  }, [activeView]);

  const handleCloseSnackbar = () => {
    setSnackstatus(false);
  };

  const handleButtonClick = (view) => {
    setActiveView(view);
    setView(view);
  };
  const boxStyles = {
    width: "70%",
    height: "20%",
    flexWrap: "wrap",
    // paddingTop: 2,
    backgroundColor: "#fff",
    // background: "linear-gradient(45deg, #ffd700, #ff8c00)",
    background: "#fff",
    borderRadius: 4,
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    padding: 1, // Uncomment and adjust padding as needed
  };
  const { transaction_details, transactionDetailsFetchStatus } = useSelector(
    (state) => state.rootReducer.transactionDetailsReducer
  );

  const { details } = useSelector(
    (state) => state.rootReducer.referralDetailsRequestReducer
  );
  // console.log(details);
  // console.log(details.length);

  const { wallet_amount } = useSelector(
    (state) => state.rootReducer.getWalletAmountReducer
  );
  // console.log(wallet_amount);
  const crypto = require("crypto");

  // useEffect(() => {
  //   console.log("Referral details:", details);
  // }, [details]);

  // const getDateDifference = (date) => {
  //   const currentDate = new Date();
  //   const movieDate = new Date(date);
  //   const differenceInTime = currentDate.getTime() - movieDate.getTime();

  //   const minutes = Math.floor(differenceInTime / (1000 * 60));
  //   if (minutes < 60) {
  //     return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
  //   }

  //   const hours = Math.floor(minutes / 60);
  //   if (hours < 24) {
  //     return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
  //   }

  //   const days = Math.floor(hours / 24);
  //   if (days < 30) {
  //     return days === 1 ? "1 day ago" : `${days} days ago`;
  //   }

  //   const months = Math.floor(days / 30);
  //   if (months < 12) {
  //     return months === 1 ? "1 month ago" : `${months} months ago`;
  //   }

  //   const years = Math.floor(months / 12);
  //   return years === 1 ? "1 year ago" : `${years} years ago`;
  // };

  const getDateDifference = (date) => {
    const movieDate = new Date(date);
    const day = movieDate.getDate().toString().padStart(2, "0");
    const month = (movieDate.getMonth() + 1).toString().padStart(2, "0");
    const year = movieDate.getFullYear();

    // Get hours and minutes
    const hours = movieDate.getHours().toString().padStart(2, "0");
    const minutes = movieDate.getMinutes().toString().padStart(2, "0");

    return `${day}/${month}/${year}  ${hours}:${minutes}`;
  };


  const movieExpiryDate = (date) => {
    const DateTime = new Date(date);
  
    const newMovieDateTime = DateTime.getTime()
      ? new Date(DateTime.getTime() + 72 * 60 * 60 * 1000)
      : null;
  
    const result = newMovieDateTime ? getDateDifference(newMovieDateTime) : "N/A";
    console.log(result) ;

    return result;
  };

  


  const presentDate = getDateDifference(new Date());
  const validationDate = getDateDifference("2024-10-21T10:31:45.713");

  // console.log(presentDate > validationDate);
  // console.log(getDateDifference(new Date()));

  // console.log(getDateDifference("2024-10-21T10:31:45.713"));

  const handleViewChange = (view) => {
    setActiveView(view);
    if (view === "rewards") {
      setIsOptionChosen(true);
    } else {
      setIsOptionChosen(false);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Success":
        return "#00FF00"; // Green for Success
      case "InProcess":
        return "#FFA800"; // Orange for In Process
      case "Failed":
        return "#FF0000"; // Red for Failed
      default:
        return "#000"; // Default color
    }
  };

  const getAmountColor = (status) => {
    switch (status) {
      case "Success":
        return "#00FF00"; // Green for Success
      case "InProcess":
        return "#FFA800"; // Orange for In Process
      case "Failed":
        return "#FF0000"; // Red for Failed
      default:
        return "#000"; // Default color
    }
  };

  window.Buffer = window.Buffer || require("buffer").Buffer;

  window.Crypto = window.Crypto || require("crypto").Crypto;

  const ccav = require("./ccavutil");

  const fetchrewardsData = async () => {
    try {
      const response = await axios.get(
        `https://api.iott.co.in/api/referral/Get_Rewardhistory?userid=${authData?.auth?.Id}`

        // `https://api.iott.co.in/api/referral/get_rewardhistory?userid=${authData?.auth?.Id}`
        // `https://api.iott.co.in/api/Payment/GetReferralDetailsRequest?userId=${userId}&level=${level}`
      );
      console.log(
        "Response from reward data:",
        response.data,
        `https://api.iott.co.in/api/referral/Get_Rewardhistory?userid=${authData?.auth?.Id}`
      );
      // const data = JSON.stringify(response.data)
      setRewardsData(Array.isArray(response.data) ? response.data : []);
      seterrorReward("");
    } catch (error) {
      // console.error("Error fetching data:", error);
      seterrorReward("Something went wrong");
    }
  };

  // const fetchrewardsData = () => {
  //   const userId = authData?.auth?.Id;
  //   dispatch(fetchReferralDetails({ userId }));
  //   // console.log(fetchReferralDetails({ userId }))
  // }

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://api.iott.co.in/api/payment/get_purchase_subscription_history?userid=${authData?.auth?.Id}`
      );
      console.log(response.data);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const billsData = async () => {
    try {
      const response = await axios.get(
        `https://api.iott.co.in/api/payment/get_purchase_history?userid=${authData?.auth?.Id}`
      );
      // console.log(response.data);
      setBillData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (authData?.auth) {
      fetchData();

      billsData();

      getOrderId();

      fetchrewardsData();

      dispatch(fetchWalletAmount(authData?.auth?.Id));

      dispatch(fetchTransactionDetails(authData?.auth?.Id));
    }

    // const interval = setInterval(() => {
    //   setCurrentDateTime(new Date());
    // }, 1000);

    // return () => clearInterval(interval);
  }, []);

  const handleAmountChange = (e) => {
    const enteredAmount = e.target.value;
    if (parseInt(enteredAmount) < 30 && enteredAmount !== "") {
      setValidationMessage("Please enter at least 30 rupees.");
    } else {
      setValidationMessage("");
    }

    setSelectedAmount("");
    setAmount(enteredAmount);
  };

  const handleTopUpOptionChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedAmount(selectedValue);
    setAmount(selectedValue);
    setAmount("");
    setValidationMessage("");
  };

  const getOrderId = () => {
    axios
      .get(`${endpoints.getOrderId}`, {
        headers: {
          Authorization: `Bearer ${authData?.authToken}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setOrderId(response.data.OrderId);
        }
      })
      .catch((err) => console.log(err));
  };

  let url = "";
  const initiateTransaction = async () => {
    await axios
      .post(
        `${endpoints.initiateTransaction}`,
        {
          UserId: authData?.auth?.Id,
          OrderId: OrderId,
          Amount: amount,
        },
        {
          headers: {
            Authorization: `Bearer ${authData?.authToken}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
        }
      })
      .catch((err) => console.log(err));
  };
  const setPmtUrl = async () => {
    if (typeof initiateTransaction === "function") {
      initiateTransaction();
    } else {
      console.error("initiateTransaction is not defined");
      return;
    }

    const body =
      "merchant_id=708495&order_id=" +
      OrderId +
      "&redirect_url=https://deve.iott.co.in/MarchantPage2?Userid=" +
      authData?.auth?.Id +
      "&cancel_url=https://deve.iott.co.in/MarchantPage2?userid=" +
      authData?.auth?.Id +
      "&OrderId=" +
      OrderId +
      "&amount=" +
      amount +
      "&currency=INR";

    const workingKey = "6E824886AB948C420B7D6E0E2B9333F6";
    const accessCode = "AVVJ56IK18AD79JVDA";

    const md5 = crypto.createHash("md5").update(workingKey).digest();
    const keyBase64 = Buffer.from(md5).toString("base64");

    const ivBase64 = Buffer.from([
      0x00, 0x01, 0x02, 0x03, 0x04, 0x05, 0x06, 0x07, 0x08, 0x09, 0x0a, 0x0b,
      0x0c, 0x0d, 0x0e, 0x0f,
    ]).toString("base64");

    const encRequest = ccav.encrypt(body, keyBase64, ivBase64);

    url =
      "https://secure.ccavenue.com/transaction.do?command=initiateTransaction&encRequest=" +
      encRequest +
      "&access_code=" +
      accessCode;

    let form = document.forms["redirect"];
    if (!form) {
      form = document.createElement("form");
      form.setAttribute("Name", "redirect");
      form.setAttribute("method", "POST");
      document.body.appendChild(form);
    }

    form.action = url;
    form.submit();
  };

  const handleProceed = () => {
    if (!selectedAmount && (amount === "" || parseInt(amount) < 30)) {
      setValidationMessage(
        // "Please enter at least 30 rupees or select a top-up."
        "Please enter at least 30 rupees"
      );
    } else {
      // setSuccessMessage("Top-up successful!");
      if (OrderId) {
        setPmtUrl();
      } else {
        setSnackstatus(true);
      }
    }
  };

  /////////////////////////// Refer & Earn Bank Details /////////////////////////////
  const [formValues, setFormValues] = useState({
    BankName: "",
    AccountNumber: "",
    IFSCCode: "",
    Branch: "",
    Name: "",
  });

  const [formErrors, setFormErrors] = useState({
    BankName: "",
    AccountNumber: "",
    IFSCCode: "",
    Branch: "",
    Name: "",
  });

  const validateAccountNumber = (value) => {
    const AccountNumberPattern = /^[A-Za-z0-9]{10,15}$/;
    return AccountNumberPattern.test(value) ? "" : "Invalid account number";
  };

  const validateIFSCCode = (value) => {
    const IFSCCodePattern = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    return IFSCCodePattern.test(value) ? "" : "Invalid IFSC code";
  };

  const userId = authData?.auth?.Id;
  const [addMessage, setAddMessage] = React.useState("");
  const [BankNames, setBankNames] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [totalBonus, setTotalBonus] = useState(0);
  const [error, setError] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDetailsSaved, setIsDetailsSaved] = useState(false); // Indicates if details have been saved
  const [savedDetails, setSavedDetails] = useState({}); // State to store saved bank details
  const [withdrawHistory, setWithdrawHistory] = useState({
    WithDrawAmmount: "",
    date: "",
  });
  const [totalEarnings, setTotalEarnings] = useState(0); // New state for total earnings

  const handleChange = (e) => {
    // console.log(e.target.name)
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    // if (name === 'AccountNumber') {
    //   // Limit the length to 15 characters and ensure only digits
    //   const newValue = value.slice(0, 15);
    //   if (/^\d*$/.test(newValue)) {
    //     setFormValues({ ...formValues, [name]: newValue });
    //   }
    // } else {
    //   // Update form values for other fields
    //   setFormValues({ ...formValues, [name]: value });
    // }

    // if (name === 'IFSCCode') {
    //   // Validate IFSC code format
    //   const ifscPattern = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    //   if (!ifscPattern.test(value)) {
    //     setFormErrors({ ...formErrors, [name]: 'Invalid IFSC code' });
    //   } else {
    //     setFormErrors({ ...formErrors, [name]: '' });
    //   }
    // }
  };

  const handleCancel = () => {
    setFormValues({
      BankName: "",
      AccountNumber: "",
      IFSCCode: "",
      Branch: "",
      Name: "",
    });
    // Reset form errors if needed
    setFormErrors({
      BankName: "",
      AccountNumber: "",
      IFSCCode: "",
      Branch: "",
      Name: "",
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.iott.co.in/api/payment/GetBonusWithDrawHistoryByUserId?userid=${authData?.auth?.Id}`
        );
        setWithdrawHistory(response.data);
        // console.log(response.data);
      } catch (err) {
        setError(err);
      } finally {
        // setLoading(false);
      }
    };

    fetchData();
  }, []);

  // const validateForm = () => {
  //   let isValid = true;
  //   const newErrors = { ...formErrors };

  //   const AccountNumberError = validateAccountNumber(formValues.AccountNumber);
  //   if (AccountNumberError) {
  //     newErrors.AccountNumber = AccountNumberError;
  //     isValid = false;
  //   } else {
  //     newErrors.AccountNumber = "";
  //   }

  //   const IFSCCodeError = validateIFSCCode(formValues.IFSCCode);
  //   if (IFSCCodeError) {
  //     newErrors.IFSCCode = IFSCCodeError;
  //     isValid = false;
  //   } else {
  //     newErrors.IFSCCode = "";
  //   }

  //   const errors = {};
  //   if (!formValues.BankName) errors.BankName = "Bank Name is required";
  //   if (!formValues.AccountNumber)
  //     errors.AccountNumber = "Account number is required";
  //   if (!formValues.IFSCCode) errors.IFSCCode = "IFSC code is required";
  //   if (!formValues.Branch) errors.Branch = "Branch is required";
  //   if (!formValues.Name) errors.Name = "Name is required";

  //   setFormErrors(errors);
  //   if (Object.keys(errors).length === 0) {
  //     setSavedDetails(formValues);
  //     setIsDetailsSaved(true);
  //     setIsEditMode(false);
  //     setAddMessage("Details saved successfully!");
  //   } else {
  //     setAddMessage(""); // Clear the success message on error
  //   }
  // };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Validate AccountNumber
    const AccountNumberError = validateAccountNumber(formValues.AccountNumber);
    if (AccountNumberError || !formValues.AccountNumber) {
      newErrors.AccountNumber =
        AccountNumberError || "Account number is required";
      isValid = false;
    }

    // Validate IFSCCode
    const IFSCCodeError = validateIFSCCode(formValues.IFSCCode);
    if (IFSCCodeError || !formValues.IFSCCode) {
      newErrors.IFSCCode = IFSCCodeError || "IFSC code is required";
      isValid = false;
    }

    // Check other required fields
    if (!formValues.BankName) {
      newErrors.BankName = "Bank Name is required";
      isValid = false;
    }
    if (!formValues.Branch) {
      newErrors.Branch = "Branch is required";
      isValid = false;
    }

    if (!formValues.Name) {
      newErrors.Name = "Name is required";
      isValid = false;
    }

    // Update the formErrors state with the new errors
    setFormErrors(newErrors);

    return isValid; // Return the validity of the form
  };

  const handleback = () => {
    setIsDetailsSaved(true);
    setIsEditMode(false);
  };

  const handleEdit = () => {
    setFormValues(savedDetails);
    setIsEditMode(true);
  };

  const handleConfrim = () => {
    if (!validateForm()) {
      setAddMessage("Please enter valid details");
      //  console.log("detaisl saved" );
      return;
    } else {
      setAddMessage("");
    }

    const postData = {
      UserId: userId,
      BankName: formValues.BankName,
      AccountNumber: formValues.AccountNumber,
      IFSCCode: formValues.IFSCCode,
      Branch: formValues.Branch,
      Name: formValues.Name,
    };

    console.log(postData.UserId);

    try {
      const userId = authData?.auth?.Id;
      if (!userId) {
        setAddMessage("Unable to retrieve user ID. Please log in again.");
        return;
      }

      const response = axios
        .post(
          "https://api.iott.co.in/api/payment/AddUserTransactionDetails",
          postData
        )
        .then((response) => {
          console.log("response for api", response);
        });
      setAddMessage("Your bank details have been added successfully.");
      setIsDetailsSaved(true);
      setSavedDetails(formValues);
      // console.log("Data saved successfully:", response);
    } catch (error) {
      // console.error("Error saving data:", error);
      setAddMessage(
        "An error occurred while saving your details. Please try again."
      );
      setIsDetailsSaved(false);
    }
  };

  // const handleConfrim = async () => {
  //   if (!validateForm()) {
  //     setAddMessage("Please fill all required fields correctly.");
  //     return; // Stop execution if validation fails
  //   }

  //   setAddMessage("Your bank details have been updated successfully!");
  //   console.log("Your bank details have been updated successfully!");
  //   alert("Details confirmed");

  //   const putData = {
  //     BankName: formValues.BankName,
  //     AccountNumber: formValues.AccountNumber,
  //     IFSCCode: formValues.IFSCCode,
  //     Branch: formValues.Branch,
  //     Name: formValues.Name,
  //   };

  //   try {
  //     const userId = authData?.auth?.Id;
  //     if (!userId) {
  //       setAddMessage("Unable to retrieve user ID. Please log in again.");
  //       return;
  //     }

  //     const response = await axios.put(
  //       `https://api.iott.co.in/api/payment/UpdateUserTransactionDetails?userId=${userId}`,
  //       putData
  //     );
  //     console.log("response for api", response);

  //     if (response.status === 200) {
  //       setAddMessage("Your bank details have been updated successfully.");
  //       setIsDetailsSaved(true);
  //       setSavedDetails(formValues);
  //     } else {
  //       setAddMessage("Failed to update your bank details. Please try again.");
  //     }
  //   } catch (error) {
  //     console.error("Error updating data:", error);
  //     setAddMessage("An error occurred while updating your details. Please try again.");
  //     setIsDetailsSaved(false);
  //   }
  // };

  // const handleConfrim = async () => {
  //   if (!validateForm()) {
  //     setAddMessage("");
  //     return; // Stop execution if validation fails
  //   }

  //   const putData = {
  //     UserId: userId, // Make sure this userId is correct
  //     BankName: formValues.BankName,
  //     AccountNumber: formValues.AccountNumber,
  //     IFSCCode: formValues.IFSCCode,
  //     Branch: formValues.Branch,
  //     Name: formValues.Name,
  //   };

  //   try {
  //     const response = await axios.put(
  //       "https://api.iott.co.in/api/payment/UpdateUserTransactionDetails",putData
  //     );

  //     // console.log("response for api", response);

  //     // Check for different status codes
  //     if (response.status === 200 || response.status === 204) {
  //       setAddMessage("Your bank details have been updated successfully.");
  //       setIsDetailsSaved(true);
  //       setSavedDetails(formValues); // Update saved details
  //     } else {
  //       console.log("Failed to update, received status:", response.status);
  //       setAddMessage("Failed to update your bank details. Please try again.");
  //     }
  //   } catch (error) {
  //     console.error("Error updating data:", error);
  //     setAddMessage("An error occurred while updating your details. Please try again.");
  //     setIsDetailsSaved(false);
  //   }
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    // Validate the form
    if (!validateForm()) {
      setAddMessage("Please fill all required fields correctly.");
      return;
    }

    setAddMessage("Your bank details are being updated...");

    // Prepare the data for the PUT request
    const putData = {
      BankName: formValues.BankName,
      AccountNumber: formValues.AccountNumber,
      IFSCCode: formValues.IFSCCode,
      Branch: formValues.Branch,
      Name: formValues.Name,
    };

    try {
      const userId = authData?.auth?.Id;
      if (!userId) {
        setAddMessage("Unable to retrieve user ID. Please log in again.");
        return;
      }

      // console.log("User ID:", userId);
      // console.log("Form Values:", putData);

      // Make the PUT request to update data
      const response = await axios.put(
        `https://api.iott.co.in/api/payment/UpdateUserTransactionDetails?userId=${userId}`,
        putData
      );

      // console.log("API Response:", response);
      // console.log(response.status);

      if (response.status === 200) {
        setAddMessage("Your bank details have been updated successfully.");
        setIsEditMode(false);
        setIsDetailsSaved(true);
        setSavedDetails(formValues);

        setTimeout(() => {
          setAddMessage("");
        }, 2000);
      } else {
        setAddMessage("Failed to update your bank details. Please try again.");
      }
    } catch (error) {
      // console.error("Error updating data:", error?.response?.data || error.message);
      setAddMessage(
        "An error occurred while updating your details. Please try again."
      );
      setIsDetailsSaved(false);
    }
  };

  // const checkBankDetails = async ()=>{

  // }

  useEffect(() => {
    const fetchBankNames = async () => {
      const userJson = localStorage.getItem("loginUser");
      const userObject = JSON.parse(userJson);
      const userId = userObject.Id;
      // console.log(userId);

      if (!userId) return;

      try {
        const resp = await axios.get(
          `https://api.iott.co.in/api/payment/GetUserTransactionDetails?UserId=${userId}`
        );
        //  console.log('response', resp.data)
        setIsDetailsSaved(true);
        setSavedDetails(resp.data);
        setIsLoading(false);
      } catch (error) {
        // console.error("Error fetching bank Names:", error);
        setIsLoading(false);
      }
    };
    fetchBankNames();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Array of month Names
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract parts
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    // Format time (HH:MM)
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const time = `${hours}:${minutes}`;

    return `${month} ${day}, ${year} ${time}`;
  };

  // console.log('userid',authData?.auth?.Id)
  /////////////////////////////// BONUS API ////////////////////////////
  const fetchTotalEarnings = async () => {
    try {
      const userId = authData?.auth?.Id;
      // Assuming the ReferredByUserId is the same as the current user's ID
      const response = await axios.get(
        `https://api.iott.co.in/api/Payment/GetTotalBonusAmountByReferredByUserId?ReferredByUserId=${userId}`
      );

      if (response.status === 200) {
        // console.log(response);
        setTotalEarnings(response.data);
        // console.log(response);
      } else {
        throw new Error("Failed to fetch total earnings.");
      }
    } catch (error) {
      setError("Error fetching total earnings. Please try again later.");
      // console.error("Error fetching total earnings:", error);
    }
  };
  fetchTotalEarnings();

  /////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <Header />

      <div className="main-container-wallet">
        {/* <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          minHeight={"100%"}
          p={2}
        > */}
        <Grid
          style={{
            backgroundImage: `url(${"https://bucketblob.iott.co.in/Icons/bgImage.jpg"})`,
          }}
          item
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box width={"90%"} mb={10}>
            <Box
              display={"flex"}
              alignItems={"center"}
              alignSelf={"flex-start"}
              mb={1}
            >
              <Typography
                fontSize={{ xs: 20, sm: 26, md: 34 }}
                color={"#FFDA78"}
                mr={1}
                fontWeight={900}
              >
                WALLET
              </Typography>
              <PiWalletFill color="#FFDA78" fontSize={34} />
            </Box>

            <Box
              bgcolor={"#FFDA78"}
              display={"flex"}
              flexDirection={{ xs: "column", md: "row" }}
              width={"100%"}
              maxHeight={"100%"}
              borderRadius={6}
              // p={3}
            >
              {/* ============== control part ==================== */}

              <Box
                width={{ xs: "100%", md: "50%" }}
                borderRight={{ xs: "none", md: "1px solid #010113" }}
                borderBottom={{ md: "none", xs: "1px solid #010113" }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={{ sm: "space-around" }}
                >
                  {wallet_amount && wallet_amount !== 0 ? (
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                      ml={6}
                      mt={1}
                    >
                      {" "}
                      <Typography
                        fontWeight={600}
                        fontSize={{ xs: "15px", sm: "20px", md: "30px" }}
                      >
                        BALANCE : ₹{wallet_amount}
                      </Typography>
                      {/* <MdCurrencyRupee fontWeight={600} fontSize={20} /> */}
                      {/* <Typography
                        fontWeight={600}
                        fontSize={{ xs: "20px", sm: "30px" }}
                      >
                        ₹{wallet_amount}
                      </Typography> */}
                    </Box>
                  ) : (
                    //  {wallet_amount ===0 && (
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                      ml={5}
                      mt={1}
                    >
                      {" "}
                      <Typography
                        fontWeight={600}
                        fontSize={{ xs: "15px", sm: "20px", md: "30px" }}
                      >
                        BALANCE :₹{wallet_amount || 0}
                      </Typography>
                      {/* <MdCurrencyRupee fontWeight={600} fontSize={{xs:'20px',sm:'60px'}}/> */}
                      {/* <Typography
                        fontWeight={600}
                        fontSize={{ xs: "20px", sm: "30px" }}
                      >
                        ₹{wallet_amount || 0}
                      </Typography> */}
                    </Box>
                  )}

                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    ml={6}
                    mt={1}
                  >
                    {" "}
                    <Typography
                      fontWeight={600}
                      fontSize={{ xs: "15px", sm: "20px", md: "30px" }}
                    >
                      BONUS :₹{totalEarnings}
                    </Typography>
                    {/* <MdCurrencyRupee fontWeight={600} fontSize={20} /> */}
                    {/* <Typography
                      fontWeight={600}
                      fontSize={{ xs: "20px", sm: "30px" }}
                    >
                      ₹{totalEarnings}
                      ₹0
                    </Typography> */}
                  </Box>
                </Box>

                {/*               
                <Box  display={"flex"}
                  justifyContent={"center"}
                  mt={{ xs: 2, sm: 5 }} > */}
                <Box
                  // pl={{ xs: 1 }}
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  flexWrap={"wrap"}
                  mt={{ xs: 2, sm: 5 }}
                >
                  <Box
                    m={2}
                    className="selected-event"
                    border={activeView === "rewards" && "2px solid black"}
                    height={{ xs: 60, sm: 90, md: 120 }}
                    width={{ xs: 60, sm: 90, md: 120 }}
                    bgcolor={"FFDA78"}
                    borderRadius={2}
                    boxShadow={10}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    onClick={() => handleViewChange("rewards")}
                  >
                    <img
                      src={"https://bucketblob.iott.co.in/Icons/rewards.png"}
                      alt="rewards"
                      className="wallet-images"
                    />
                    <Typography
                      color={"#000000"}
                      fontSize={{ xs: 8, sm: 10, md: 12 }}
                      fontWeight={700}
                    >
                      Rewards
                    </Typography>
                  </Box>

                  <Box
                    m={2}
                    className="selected-event"
                    height={{ xs: 60, sm: 90, md: 120 }}
                    width={{ xs: 60, sm: 90, md: 120 }}
                    bgcolor={"#FFDA78"}
                    borderRadius={2}
                    boxShadow={10}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    onClick={() => handleViewChange("addMoney")}
                    border={activeView === "addMoney" && "2px solid black"}
                  >
                    <img
                      src={"https://bucketblob.iott.co.in/Icons/top-up.png"}
                      alt="add-money"
                      className="wallet-images"
                    />
                    <Typography
                      color={"#000000"}
                      fontSize={{ xs: 8, sm: 10, md: 12 }}
                      fontWeight={700}
                    >
                      Top-Up
                    </Typography>
                  </Box>

                  <Box
                    m={2}
                    className="selected-event"
                    border={
                      activeView === "paymentHistory" && "2px solid black"
                    }
                    height={{ xs: 60, sm: 90, md: 120 }}
                    width={{ xs: 60, sm: 90, md: 120 }}
                    bgcolor={"FFDA78"}
                    borderRadius={2}
                    boxShadow={10}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    onClick={() => handleViewChange("paymentHistory")}
                  >
                    <img
                      src={
                        "https://bucketblob.iott.co.in/Icons/Payment history.png"
                      }
                      alt="payment-history"
                      className="wallet-images"
                    />
                    <Typography
                      color={"#000000"}
                      fontSize={{ xs: 8, sm: 10, md: 12 }}
                      fontWeight={700}
                      textAlign={"center"}
                    >
                      Payment History
                    </Typography>
                  </Box>
                  <Box
                    m={2}
                    className="selected-event"
                    border={activeView === "bill" && "2px solid black"}
                    height={{ xs: 60, sm: 90, md: 120 }}
                    width={{ xs: 60, sm: 90, md: 120 }}
                    bgcolor={"FFDA78"}
                    borderRadius={2}
                    boxShadow={10}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    onClick={() => handleViewChange("bill")}
                  >
                    <img
                      src={"https://bucketblob.iott.co.in/Icons/bill.png"}
                      alt="bill"
                      className="wallet-images"
                    />
                    <Typography
                      color={"#000000"}
                      fontSize={{ xs: 8, sm: 10, md: 12 }}
                      fontWeight={700}
                    >
                      Bill
                    </Typography>
                  </Box>

                  {/* <Box
                    m={2}
                    className="selected-event"
                    border={
                      activeView === "subscriptionDetails" && "2px solid black"
                    }
                    height={{ xs: 60, sm: 90, md: 120 }}
                    width={{ xs: 60, sm: 90, md: 120 }}
                    bgcolor={"FFDA78"}
                    borderRadius={2}
                    boxShadow={10}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    onClick={() => handleViewChange("subscriptionDetails")}
                  >
                    <img
                      src={
                        "https://bucketblob.iott.co.in/Icons/Payment history.png"
                      }
                      alt="payment-history"
                      className="wallet-images"
                    />
                    <Typography
                      color={"#000000"}
                      fontSize={{ xs: 8, sm: 10, md: 12 }}
                      fontWeight={700}
                      textAlign={"center"}
                    >
                      Subscription Details
                    </Typography>
                  </Box> */}

                  <Box
                    m={2}
                    className="selected-event"
                    border={activeView === "bank" && "2px solid black"}
                    height={{ xs: 60, sm: 90, md: 120 }}
                    width={{ xs: 60, sm: 90, md: 120 }}
                    bgcolor={"FFDA78"}
                    borderRadius={2}
                    boxShadow={10}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    onClick={() => handleViewChange("bank")}
                  >
                    {/* <BsBank fontSize={{xs:'20px', sm:'30px',xs:'50px'}} /> */}
                    <BsBank className="responsive-icon" />
                    <Typography
                      color={"#000000"}
                      fontSize={{ xs: 8, sm: 10, md: 12 }}
                      fontWeight={700}
                    >
                      Bank
                    </Typography>
                  </Box>

                  <Box
                    m={2}
                    className="selected-event"
                    border={activeView === "withdraw" && "2px solid black"}
                    height={{ xs: 60, sm: 90, md: 120 }}
                    width={{ xs: 60, sm: 90, md: 120 }}
                    bgcolor={"FFDA78"}
                    borderRadius={2}
                    boxShadow={10}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    onClick={() => handleViewChange("withdraw")}
                  >
                    <FaSackDollar className="responsive-icon" />
                    <Typography
                      color={"#000000"}
                      fontSize={{ xs: 8, sm: 10, md: 12 }}
                      fontWeight={700}
                    >
                      Withdraw
                    </Typography>
                  </Box>
                </Box>

                {/* </Box> */}
              </Box>

              {/* ============== details part ==================== */}

              {activeView === "addMoney" && (
                <Box
                  height={"100%"}
                  width={{ xs: "100%", md: "50%" }}
                  p={{ xs: 2, md: 4 }}
                  pl={{ xs: 0, md: 4 }}
                  pt={0}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography
                      fontFamily={"Merriweather"}
                      fontWeight={700}
                      fontSize={{ xs: 14, sm: 30 }}
                      mr={{ xs: 0, sm: 2 }}
                    >
                      ADD MONEY{" "}
                    </Typography>
                    <img
                      src={"https://bucketblob.iott.co.in/Icons/top-up.png"}
                      alt="add-money"
                      className="icon"
                    />
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    mt={{ xs: 0, sm: 6 }}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Typography
                        fontFamily={"Merriweather"}
                        fontWeight={700}
                        fontSize={{ xs: 14, sm: 30 }}
                        mb={2}
                      >
                        Top Up
                      </Typography>
                      <input
                        type="number"
                        value={amount}
                        onChange={handleAmountChange}
                        className="enter-amount"
                        placeholder="Enter Min 30 rupees"
                        // disabled={selectedAmount !== ""}
                      />

                      {/* <Box mt={2}>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            Name="row-radio-buttons-group"
                            value={!amount ? selectedAmount : ""}
                            onChange={handleTopUpOptionChange}
                          >
                            <FormControlLabel
                              value={100}
                              control={<Radio />}
                              label="100"
                            />
                            <FormControlLabel
                              value={500}
                              control={<Radio />}
                              label="500"
                            />
                            <FormControlLabel
                              value={1000}
                              control={<Radio />}
                              label="1000"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box> */}
                      <Box mt={{ xs: 0, sm: 3 }} mb={{ xs: 0, sm: 3 }}>
                        <button className="proceed-btn" onClick={handleProceed}>
                          Proceed
                        </button>
                      </Box>

                      {validationMessage && (
                        <Typography
                          color="error"
                          fontFamily={"Quicksand"}
                          fontWeight={700}
                          textAlign={"center"}
                          width={250}
                        >
                          {validationMessage}
                        </Typography>
                      )}

                      {/* Success Message */}
                      {successMessage && (
                        <Typography
                          pt={3}
                          color="green"
                          fontFamily={"Quicksand"}
                          fontWeight={700}
                          textAlign={"center"}
                        >
                          {successMessage}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}

              {activeView === "paymentHistory" && (
                <Grid
                  width={{ xs: "100%", md: "50%" }}
                  p={{ xs: 3, md: 2 }}
                  pl={{ xs: 2, md: 4 }}
                  pt={0}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    mb={2}
                  >
                    <Typography
                      fontFamily={"Merriweather"}
                      fontWeight={700}
                      fontSize={{ xs: 14, sm: 30 }}
                      mr={2}
                    >
                      Payment History
                    </Typography>
                    <img
                      src={
                        "https://bucketblob.iott.co.in/Icons/Payment history.png"
                      }
                      alt="payment-history"
                      className="icon"
                    />
                  </Box>
                  {transactionDetailsFetchStatus === "loading" && (
                    <Grid
                      container
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <CircularProgress color="info" />
                    </Grid>
                  )}
                  {transactionDetailsFetchStatus === "success" &&
                  transaction_details?.length > 0 &&
                  !transaction_details[0]?.message ? (
                    <Grid
                      bgcolor={"#000"}
                      borderRadius={4}
                      maxHeight={{ xs: "180px", sm: "300px", md: "300px" }}
                      border={"1px solid #fff"}
                      p={4}
                      pt={0}
                      sx={{
                        overflowY: "auto",
                        overflowX: "auto",
                        "&::-webkit-scrollbar": {
                          width: "4px", // Set your custom scrollbar width here
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "#ffda78",
                          borderRadius: "2px",
                        },
                      }}
                    >
                      <Box
                        width={"100%"}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        pt={1}
                        sx={{
                          position: "sticky",
                          top: -1,
                          backgroundColor: "#000",
                          zIndex: 1,
                          borderBottom: "2px solid #ffda78",
                        }}
                        // mb={2}
                        // position= {"sticky"}
                        // top={0}
                        // bgcolor={"#ffda78"}
                        // // color={"#000 !important"}
                      >
                        <Typography
                          width={{ xs: "55%" }}
                          fontWeight={600}
                          color={"#ffda78"}
                          fontSize={{ xs: "9px", sm: "18px" }}
                        >
                          TRANSACTION DETAILS
                        </Typography>
                        <Typography
                          width={"15%"}
                          fontWeight={600}
                          color={"#ffda78"}
                          fontSize={{ xs: "9px", sm: "18px" }}
                        >
                          AMOUNT
                        </Typography>
                        <Typography
                          width={"15%"}
                          fontWeight={600}
                          color={"#ffda78"}
                          fontSize={{ xs: "9px", sm: "18px" }}
                        >
                          STATUS
                        </Typography>
                      </Box>
                      {transaction_details?.map((transaction, index) => (
                        <Box
                          key={index}
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          borderBottom={"1px solid #ffda78"}
                          pb={1}
                          pt={1}
                        >
                          <Box
                            width={"55%"}
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"flex-start"}
                          >
                            <Typography
                              color={"#ffda78"}
                              fontFamily={"Quicksand"}
                              // fontSize={12}
                              fontWeight={{ xs: 500, sm: 700 }}
                              fontSize={{ xs: "7px", sm: "15px" }}
                            >
                              OrderId:{" "}
                              <span style={{ fontWeight: 400 }}>
                                {transaction.Order_Id}
                              </span>
                            </Typography>
                            <Typography
                              color={"#ffda78"}
                              fontFamily={"Quicksand"}
                              fontSize={{ xs: "7px", sm: "15px" }}
                              fontWeight={{ xs: 500, sm: 700 }}
                            >
                              Transaction Id:{" "}
                              <span style={{ fontWeight: 400 }}>
                                {transaction.TrancId || "NA"}
                              </span>
                            </Typography>
                            <Typography
                              color={"#ffda78"}
                              fontFamily={"Quicksand"}
                              fontSize={{ xs: "7px", sm: "15px" }}
                              fontWeight={{ xs: 500, sm: 700 }}
                            >
                              Date:{" "}
                              <span style={{ fontWeight: 400 }}>
                                {getDateDifference(transaction?.DateTime) ||
                                  " --- "}
                              </span>
                            </Typography>
                          </Box>
                          <Typography
                            width={"15%"}
                            color={"#ffda78"}
                            fontFamily={"Quicksand"}
                            fontSize={{ xs: "8px", sm: "18px" }}
                            fontWeight={600}
                            textAlign={"center"}
                          >
                            ₹{transaction?.Amount}
                          </Typography>
                          <Typography
                            width={"15%"}
                            color={"#ffda78"}
                            fontFamily={"Quicksand"}
                            fontSize={12}
                            fontWeight={400}
                            textAlign={"center"}
                          >
                            {transaction.Status === "Success" ? (
                              <img
                                src={
                                  "https://bucketblob.iott.co.in/Icons/correct.png"
                                }
                                alt="Correct"
                                style={{ width: 20, height: 20 }}
                              />
                            ) : transaction.Status === "Initiated" ? (
                              <GrInProgress fontSize={18} color="#82E17B" />
                            ) : (
                              <img
                                src={
                                  "https://bucketblob.iott.co.in/Icons/failed.png"
                                }
                                alt="Failed"
                                style={{ width: 20, height: 20 }}
                              />
                            )}
                          </Typography>
                        </Box>
                      ))}
                    </Grid>
                  ) : (
                    transactionDetailsFetchStatus !== "loading" && (
                      <Grid
                        bgcolor={"#000"}
                        borderRadius={4}
                        minHeight={"300px"}
                        border={"1px solid #fff"}
                        p={2}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Typography
                          sx={{ color: "#fff" }}
                          fontSize={{ xs: "14px", sm: "23px" }}
                          fontWeight={{ xs: 600, sm: 800 }}
                          textAlign={"center"}
                        >
                          You have no Payments yet!
                        </Typography>
                      </Grid>
                    )
                  )}
                  {transactionDetailsFetchStatus === "failed" && (
                    <Typography color={"#fff"}>
                      Failed to load transaction details
                    </Typography>
                  )}
                </Grid>
              )}

              {activeView === "rewards" && isOptionChosen && !isScratched && (
                <Box
                  height={"100%"}
                  width={{ xs: "80%", md: "50%" }}
                  justifyContent={"center"}
                  p={{ xs: 3, md: 2 }}
                  pt={0}
                  pl={{ sm: "10%", md: "none" }}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography
                      fontFamily={"Merriweather"}
                      fontWeight={700}
                      fontSize={{ xs: 14, sm: 30 }}
                      mr={{ xs: 1, sm: 2 }}
                    >
                      Rewards
                    </Typography>
                    <img
                      src={"https://bucketblob.iott.co.in/Icons/rewards.png"}
                      alt="rewards"
                      className="icon"
                    />
                  </Box>

                  <Grid
                    bgcolor={"#000"}
                    borderRadius={4}
                    height={{ xs: "150px", sm: "300px", md: "300px" }}
                    maxHeight={{ xs: "150px", sm: "300px", md: "300px" }}
                    border={"1px solid #fff"}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"flex-start"}
                    gap={2}
                    p={2}
                    sx={{
                      overflowY: "auto",
                      "&::-webkit-scrollbar": {
                        width: "4px", // Set your custom scrollbar width here
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#ffda78",
                        borderRadius: "2px",
                      },
                    }}
                  >
                    {errorReward ? (
                      <Box
                        bgcolor={"#000"}
                        borderRadius={4}
                        height={{ xs: "150px", sm: "300px", md: "200px" }}
                      >
                        <Typography
                          fontFamily={"Quicksand"}
                          fontWeight={{ xs: 500, sm: 700 }}
                          fontSize={18}
                          color={"#fff"}
                          textAlign={"center"}
                        >
                          {errorReward}
                        </Typography>
                      </Box>
                    ) : rewardsData.length > 0 &&
                      (rewardsData[0]?.message == undefined ||
                        rewardsData[0]?.message == null) ? (
                      rewardsData?.map((reward, index) => (
                        <Box
                          bgcolor={"#fff"}
                          // height={60}
                          width={"100%"}
                          borderRadius={"5px"}
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"space-around"}
                          alignItems={"center"}
                        >
                          <Box display={"flex"} flexDirection={"column"}>
                            <Typography
                              fontFamily={"Quicksand"}
                              fontWeight={600}
                              fontSize={"16px"}
                              color={"#000"}
                            >
                              {index + 1} Refferal Amount Recieved from{" "}
                              {reward.UserName}
                            </Typography>
                            {/* <Typography
                            fontFamily={"Quicksand"}
                            fontWeight={600}
                            fontSize={"12px"}
                            color={"#000"}
                          >
                            Request Status:{" "}
                            <span
                              style={{
                                color: getStatusColor(withdrawal.Status),
                              }}
                            >
                              {withdrawal.Status}
                            </span>
                          </Typography> */}
                          </Box>

                          <Box display={"flex"} flexDirection={"column"}>
                            <Typography
                              fontFamily={"Quicksand"}
                              textAlign={"center"}
                              fontSize={"18px"}
                              fontWeight={700}
                              color={"green"}
                            >
                              {reward.BonusAmount}
                            </Typography>
                            <Typography
                              fontFamily={"Quicksand"}
                              fontSize={"8px"}
                              fontWeight={700}
                              color={"#000"}
                            >
                              {formatDate(reward.Date)}
                            </Typography>
                          </Box>
                        </Box>
                      ))
                    ) : (
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        minHeight={{ xs: "140px", sm: "280px" }}
                      >
                        <Typography
                          sx={{ color: "#fff" }}
                          fontSize={{ xs: "14px", sm: "23px" }}
                          fontWeight={{ xs: 600, sm: 800 }}
                          textAlign={"center"}
                        >
                          You have no Rewards..!
                        </Typography>
                      </Box>
                    )}

                    {/* <ScratchCard onScratch={() => setIsScratched(true)} /> */}
                  </Grid>
                </Box>
              )}

              {activeView === "bill" && (
                <Grid
                  width={{ xs: "100%", md: "50%" }}
                  p={{ xs: 2, md: 4 }}
                  pl={{ xs: 3, md: 4 }}
                  pt={0}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography
                      fontFamily={"Merriweather"}
                      fontWeight={700}
                      fontSize={{ xs: 14, sm: 30 }}
                      mr={{ xs: 0 }}
                    >
                      Bill
                    </Typography>
                    <img
                      src={"https://bucketblob.iott.co.in/Icons/bill.png"}
                      alt="bill"
                      className="icon"
                    />
                  </Box>
                  <Grid
                    // container
                    bgcolor={"#000"}
                    borderRadius={4}
                    minHeight={{ xs: "40vh", md: "300px" }}
                    maxHeight={{ xs: "40vh", md: "300px" }}
                    border={"1px solid #fff"}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"space-around"}
                    overflow={"hidden"}
                    p={0}
                  >
                    <Grid
                      container
                      // gap={2}
                      bgcolor={"#000"}
                      borderRadius={4}
                      // minHeight={{ xs: "40vh", md: "300px" }}
                      // maxHeight={{ xs: "40vh", md: "300px" }}
                      border={"1px solid #fff"}
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"space-around"}
                      p={0}
                      sx={{
                        border: "none",
                        overflow: "hidden",
                        overflowY: "scroll",
                        // overflowX: "auto",
                        boxShadow: "5px 4px 5px rgba(0, 0, 0, 0.5)",
                        "&::-webkit-scrollbar": {
                          width: "4px", // Set your custom scrollbar width here
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "#ffda78",
                          borderRadius: "2px",
                        },
                      }}
                      margin={0} // Ensure no margins on the Grid
                      padding={0}
                    >
                      {/* <Grid
                      xs={12}
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      position="sticky" // Use sticky position for the buttons
                      top={0} // Stick to the top
                      zIndex={2} // Ensure it stays on top of other content
                    >
                      <Grid
                        xs={12}
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        position="sticky"
                        top={0}
                        zIndex={2}
                        margin={0}
                        padding={0}
                      >
                        <Tabs
                          value={activeView}
                          onChange={(event, newValue) => setView(newValue)}
                          indicatorColor="transparent" // Optional: Remove default indicator
                          // textColor="inherit" // Customize text color if needed
                          variant="fullWidth"
                          sx={{
                            display: "flex",
                            width: "100%",
                            margin: "0",
                            padding: "0",
                            border: "none",
                            position:"sticky",
                            overflow:"hidden",
 
                         
                          }}
 
                          // sx={{ flexGrow: 1 }} // Allow tabs to take full width
                        >
                          <Tab
                            label="Movie History"
                            value="Movie History"
                            sx={{
                              bgcolor:
                                activeView === "Movie History" ? "  1px solid #FFDA78": "#FFDA78" ,
                               
                              padding: 0,
                              margin: 0,
                              // borderRadius: 1,
                              flexGrow: 1,
                              textAlign: "center",
                              cursor: "pointer",
                                 fontFamily:"700px",
                                   border:"3px solid white",
 
                              // top:"38vh"
                            }}
                          />
                          <Tab
                            label="Subscription History"
                            value="subscription"
                            sx={{
                              bgcolor:
                                activeView === "subscription"
                                  ?" white" : "#FFDA78" ,
                                  //  "#white"
                                  // : "#fff",
                              // padding: 1,
                              // borderRadius: 1,
                              textAlign: "center",
                              padding: 0, // Remove padding
                              margin: 0, // Remove margin
                              flexGrow: 1,
                              cursor: "pointer",
                              zIndex:"2",
                              fontFamily:"700px",
                              border:"3px solid white",
                              position:"sticky"
                             
                           
                              // top:"38vh"
                            }}
                          />
                        </Tabs>
                      </Grid>
                    </Grid> */}


                    
                    {/* 
                    
            const movieDateTime = movie?.DateTime;

        // Add 72 hours to the movie date
        const newMovieDateTime = movieDateTime
          ? new Date(new Date(movieDateTime).getTime() + 72 * 60 * 60 * 1000)
          : null;

        // Get the time difference after adding 72 hours
        const result = newMovieDateTime
          ? getDateDifference(newMovieDateTime)
          : "N/A";

                    */}

                      {view === "Movie History" && (
                        <>
                          {billdata?.length > 0 &&
                          billdata[0]?.message === undefined ? (
                            <>
                              {billdata?.map((movie, index) => (
                                <Box
                                  key={index}
                                  p={2}
                                  bgcolor={"#efd58f"}
                                  width={"90%"}
                                  borderRadius={3}
                                  mt={2}
                                >
                                  <Box p={2}>
                                    {/* movie name */}
                                    <Box
                                      display="flex"
                                      justifyContent="flex-start"
                                      gap={2}
                                      width="100%"
                                    >
                                      <Box
                                        display="flex"
                                        justifyContent="flex-start"
                                        flex={1}
                                      >
                                        <Typography
                                          fontFamily="Quicksand"
                                          fontWeight={600}
                                          fontSize="14px"
                                          color="#000"
                                        >
                                          Movie Name:
                                        </Typography>
                                      </Box>
                                      <Box
                                        display="flex"
                                        justifyContent="flex-start"
                                        flex={1}
                                      >
                                        <Typography style={{ fontWeight: 900 }}>
                                          {movie?.MovieName}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    {/* amount */}
                                    <Box
                                      display="flex"
                                      justifyContent="flex-start"
                                      gap={2}
                                      width="100%"
                                    >
                                      <Box
                                        display="flex"
                                        justifyContent="flex-start"
                                        flex={1}
                                      >
                                        <Typography
                                          fontFamily="Quicksand"
                                          fontWeight={600}
                                          fontSize="14px"
                                          color="#000"
                                        >
                                          Amount:
                                        </Typography>
                                      </Box>
                                      <Box
                                        display="flex"
                                        justifyContent="flex-start"
                                        flex={1}
                                      >
                                        <Typography style={{ fontWeight: 900 }}>
                                          {movie?.PurchaseAmount}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    {/* language */}

                                    <Box
                                      display="flex"
                                      justifyContent="flex-start"
                                      gap={2}
                                      width="100%"
                                    >
                                      <Box
                                        display="flex"
                                        justifyContent="flex-start"
                                        flex={1}
                                      >
                                        <Typography
                                          fontFamily="Quicksand"
                                          fontWeight={600}
                                          fontSize="14px"
                                          color="#000"
                                        >
                                          Language:
                                        </Typography>
                                      </Box>
                                      <Box
                                        display="flex"
                                        justifyContent="flex-start"
                                        flex={1}
                                      >
                                        <Typography style={{ fontWeight: 900 }}>
                                          {movie?.Language}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    {/* purchase date */}
                                    <Box
                                      display="flex"
                                      justifyContent="flex-start"
                                      gap={2}
                                      width="100%"
                                    >
                                      <Box
                                        display="flex"
                                        justifyContent="flex-start"
                                        flex={1}
                                      >
                                        <Typography
                                          fontFamily="Quicksand"
                                          fontWeight={600}
                                          fontSize="14px"
                                          color="#000"
                                        >
                                          Purchase Date:
                                        </Typography>
                                      </Box>
                                      <Box
                                        display="flex"
                                        justifyContent="flex-start"
                                        flex={1}
                                      >
                                        <Typography
                                          style={{
                                            fontWeight: 900,
                                            color: "#008000",
                                          }}
                                        >
                                          {getDateDifference(movie?.DateTime) ||
                                            "N/A"}
                                        </Typography>
                                      </Box>
                                    </Box>

                                    {/* Expiry Date Row */}
                                    <Box
                                      display="flex"
                                      justifyContent="flex-start"
                                      gap={2}
                                      width="100%"
                                    >
                                      <Box
                                        display="flex"
                                        justifyContent="flex-start"
                                        flex={1}
                                      >
                                        <Typography
                                          fontFamily="Quicksand"
                                          fontWeight={600}
                                          fontSize="14px"
                                          color="#000"
                                        >
                                          Expiry Date:
                                        </Typography>
                                      </Box>
                                      <Box
                                        display="flex"
                                        justifyContent="flex-start"
                                        flex={1}
                                      >
                                        <Typography
                                          style={{
                                            fontWeight: 900,
                                            color: "#FF0000",
                                          }}
                                        >
                                          {movieExpiryDate(movie?.DateTime) ||
                                            "N/A"}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    {/*  */}
                                  </Box>
                                </Box>

                                // <Box
                                //   p={2}
                                //   bgcolor={"#FFDA78"}
                                //   height={100}
                                //   width={"30%"}
                                //   borderRadius={3}
                                //   mt={2}
                                // >
                                //   <Box mb={2}>
                                //     <Typography
                                //       fontFamily={"Quicksand"}
                                //       fontWeight={700}
                                //       fontSize={"10px"}
                                //       color={"#000"}
                                //     >
                                //       Movie Name: {movie?.MovieName}
                                //     </Typography>
                                //   </Box>
                                //   <Typography
                                //     fontFamily={"Quicksand"}
                                //     fontWeight={700}
                                //     fontSize={"9px"}
                                //     color={"#000"}
                                //   >
                                //     Recipient Name:{" "}
                                //   </Typography>
                                //   <Typography
                                //     fontFamily={"Quicksand"}
                                //     fontWeight={700}
                                //     fontSize={"9px"}
                                //     color={"#000"}
                                //   >
                                //     Amount: {movie?.PurchaseAmount}{" "}
                                //   </Typography>
                                //   <Typography
                                //     fontFamily={"Quicksand"}
                                //     fontWeight={700}
                                //     fontSize={"9px"}
                                //     color={"#000"}
                                //   >
                                //     Date & Time:{" "}
                                //     {getDateDifference(movie.DateTime) || " - "}
                                //   </Typography>
                                // </Box>
                              ))}
                            </>
                          ) : (
                            <Grid
                              minHeight={"280px"}
                              display={"flex"}
                              flexDirection={"column"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              pb={2}
                            >
                              <Typography
                                sx={{ color: "#fff" }}
                                fontSize={{ xs: "14px", sm: "23px" }}
                                fontWeight={{ xs: 600, sm: 800 }}
                                textAlign={"center"}
                              >
                                You have no bills yet!
                              </Typography>
                            </Grid>
                          )}
                        </>
                      )}

                      {view === "subscription" && (
                        <>
                          {data && (
                            // <Box
                            //   p={2}
                            //   bgcolor={"#FFDA78"}
                            //   height={100}
                            //   width={"30%"}
                            //   borderRadius={3}
                            //   mt={2}
                            // >
                            //   <Box mb={2}>
                            //     <Typography
                            //       fontFamily={"Quicksand"}
                            //       fontWeight={700}
                            //       fontSize={"10px"}
                            //       color={"#000"}
                            //     >
                            //       Language: {subscription?.Language}
                            //     </Typography>
                            //   </Box>
                            //   <Typography
                            //     fontFamily={"Quicksand"}
                            //     fontWeight={700}
                            //     fontSize={"9px"}
                            //     color={"#000"}
                            //   >
                            //     Recipient Name:{" "}
                            //   </Typography>
                            //   <Typography
                            //     fontFamily={"Quicksand"}
                            //     fontWeight={700}
                            //     fontSize={"9px"}
                            //     color={"#000"}
                            //   >
                            //     Amount: {subscription?.PurchaseAmount}
                            //   </Typography>
                            //   <Typography
                            //     fontFamily={"Quicksand"}
                            //     fontWeight={700}
                            //     fontSize={"9px"}
                            //     color={"#000"}
                            //   >
                            //     Amount: {subscription?.SubscriptionPlan}
                            //   </Typography>

                            //   <Typography
                            //     fontFamily={"Quicksand"}
                            //     fontWeight={700}
                            //     fontSize={"9px"}
                            //     color={"#000"}
                            //   >
                            //     Date & Time:{" "}
                            //     {getDateDifference(subscription.DateTime) || " - "}
                            //   </Typography>
                            // </Box>

                            <Box
                              display={"flex"}
                              flexDirection={"column"}
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              {transactionDetailsFetchStatus === "loading" && (
                                <Typography color={"#fff"}>
                                  Loading...
                                </Typography>
                              )}
                              {transactionDetailsFetchStatus !== "loading" &&
                                data && (
                                  <>
                                    {data?.length > 0 &&
                                    data[0]?.SubscriptionPlan ? (
                                      <Box
                                        bgcolor={"#000"}
                                        borderRadius={4}
                                        width={{ xs: "80%", sm: "410px" }}
                                        maxHeight={{ xs: "200px", sm: "100%" }}
                                        border={"1px solid #000"}
                                        p={2}
                                        sx={{
                                          overflowY: "auto",
                                          overflowX: "auto",
                                          "&::-webkit-scrollbar": {
                                            width: "2px",
                                          },
                                          "&::-webkit-scrollbar-thumb": {
                                            // backgroundColor: "#ffda78",
                                            backgroundColor: "#000",
                                            borderRadius: "2px",
                                          },
                                        }}
                                      >
                                        {/* <Box
                                        width={"100%"}
                                        display={"flex"}
                                        justifyContent={"space-between"}
                                        alignItems={"center"}
                                      >
                                        <Typography
                                          width={"60%"}
                                          fontWeight={700}
                                          color={"#ffda78"}
                                          fontSize={{ xs: "10px", sm: "20px" }}
                                        >
                                          SUBSCRIPTION DETAILS
                                        </Typography>
                                        <Typography
                                          width={"15%"}
                                          fontWeight={700}
                                          color={"#ffda78"}
                                          fontSize={{ xs: "10px", sm: "20px" }}
                                        >
                                          AMOUNT
                                        </Typography>
                                      </Box> */}
                                        {data?.map((subscription, index) => (
                                          <Box
                                            key={index}
                                            p={2}
                                            bgcolor={"#efd58f"}
                                            width={"90%"}
                                            borderRadius={3}
                                            mt={2}
                                          >
                                            <Box p={2}>
                                              {/* Amount Row */}
                                              <Box
                                                display="flex"
                                                justifyContent="flex-start"
                                                gap={2}
                                                width="100%"
                                              >
                                                <Box
                                                  display="flex"
                                                  justifyContent="flex-start"
                                                  flex={1}
                                                >
                                                  <Typography
                                                    fontFamily="Quicksand"
                                                    fontWeight={600}
                                                    fontSize="14px"
                                                    color="#000"
                                                  >
                                                    Amount:
                                                  </Typography>
                                                </Box>
                                                <Box
                                                  display="flex"
                                                  justifyContent="flex-start"
                                                  flex={1}
                                                >
                                                  <Typography
                                                    style={{ fontWeight: 900 }}
                                                  >
                                                    ₹
                                                    {subscription?.PurchaseAmount ||
                                                      "N/A"}
                                                  </Typography>
                                                </Box>
                                              </Box>

                                              {/* Language Row */}
                                              <Box
                                                display="flex"
                                                justifyContent="flex-start"
                                                gap={2}
                                                width="100%"
                                              >
                                                <Box
                                                  display="flex"
                                                  justifyContent="flex-start"
                                                  flex={1}
                                                >
                                                  <Typography
                                                    fontFamily="Quicksand"
                                                    fontWeight={600}
                                                    fontSize="14px"
                                                    color="#000"
                                                  >
                                                    Language:
                                                  </Typography>
                                                </Box>
                                                <Box
                                                  display="flex"
                                                  justifyContent="flex-start"
                                                  flex={1}
                                                >
                                                  <Typography
                                                    style={{ fontWeight: 900 }}
                                                  >
                                                    {subscription?.Language ||
                                                      "N/A"}
                                                  </Typography>
                                                </Box>
                                              </Box>

                                              {/* Subscription Plan Row */}
                                              <Box
                                                display="flex"
                                                justifyContent="flex-start"
                                                gap={2}
                                                width="100%"
                                              >
                                                <Box
                                                  display="flex"
                                                  justifyContent="flex-start"
                                                  flex={1}
                                                >
                                                  <Typography
                                                    fontFamily="Quicksand"
                                                    fontWeight={600}
                                                    fontSize="14px"
                                                    color="#000"
                                                  >
                                                    Subscription Plan:
                                                  </Typography>
                                                </Box>
                                                <Box
                                                  display="flex"
                                                  justifyContent="flex-start"
                                                  flex={1}
                                                >
                                                  <Typography
                                                    style={{ fontWeight: 900 }}
                                                  >
                                                    {subscription?.SubscriptionPlan ||
                                                      "N/A"}
                                                  </Typography>
                                                </Box>
                                              </Box>

                                              {/* Purchase Date Row */}
                                              <Box
                                                display="flex"
                                                justifyContent="flex-start"
                                                gap={2}
                                                width="100%"
                                              >
                                                <Box
                                                  display="flex"
                                                  justifyContent="flex-start"
                                                  flex={1}
                                                >
                                                  <Typography
                                                    fontFamily="Quicksand"
                                                    fontWeight={600}
                                                    fontSize="14px"
                                                    color="#000"
                                                  >
                                                    Purchase Date:
                                                  </Typography>
                                                </Box>
                                                <Box
                                                  display="flex"
                                                  justifyContent="flex-start"
                                                  flex={1}
                                                >
                                                  <Typography
                                                    style={{
                                                      fontWeight: 900,
                                                      color: "#008000",
                                                    }}
                                                  >
                                                    {getDateDifference(
                                                      subscription?.DateTime
                                                    ) || "N/A"}
                                                  </Typography>
                                                </Box>
                                              </Box>

                                              {/* Expiry Date Row */}
                                              <Box
                                                display="flex"
                                                justifyContent="flex-start"
                                                gap={2}
                                                width="100%"
                                              >
                                                <Box
                                                  display="flex"
                                                  justifyContent="flex-start"
                                                  flex={1}
                                                >
                                                  <Typography
                                                    fontFamily="Quicksand"
                                                    fontWeight={600}
                                                    fontSize="14px"
                                                    color="#000"
                                                  >
                                                    Expiry Date:
                                                  </Typography>
                                                </Box>
                                                <Box
                                                  display="flex"
                                                  justifyContent="flex-start"
                                                  flex={1}
                                                >
                                                  <Typography
                                                    style={{
                                                      fontWeight: 900,
                                                      color: "#FF0000",
                                                    }}
                                                  >
                                                    {getDateDifference(
                                                      subscription?.Validity
                                                    ) || "N/A"}
                                                  </Typography>
                                                </Box>
                                              </Box>

                                              {/* Active Status Row */}
                                              <Box
                                                display="flex"
                                                justifyContent="flex-start"
                                                gap={2}
                                                width="100%"
                                              >
                                                <Box
                                                  display="flex"
                                                  justifyContent="flex-start"
                                                  flex={1}
                                                >
                                                  <Typography
                                                    fontFamily="Quicksand"
                                                    fontWeight={600}
                                                    fontSize="14px"
                                                    color="#000"
                                                  >
                                                    Active Status:
                                                  </Typography>
                                                </Box>
                                                <Box
                                                  display="flex"
                                                  justifyContent="flex-start"
                                                  flex={1}
                                                >
                                                  <Typography
                                                    style={{
                                                      fontWeight: 900,
                                                      color:
                                                        new Date() <
                                                        new Date(
                                                          subscription?.Validity
                                                        )
                                                          ? "#008000"
                                                          : "#FF0000",
                                                    }}
                                                  >
                                                    {new Date() <
                                                    new Date(
                                                      subscription?.Validity
                                                    )
                                                      ? "Active"
                                                      : "Inactive"}
                                                  </Typography>
                                                </Box>
                                              </Box>
                                            </Box>
                                          </Box>
                                        ))}
                                      </Box>
                                    ) : (
                                      <Box
                                        bgcolor={"#000"}
                                        // borderRadius={4}
                                        width={{ xs: "40%", sm: "80%" }}
                                        minHeight={"250px"}
                                        // border={"1px solid #fff"}
                                        display={"flex"}
                                        flexDirection={"column"}
                                        justifyContent={"center"}
                                        p={2}
                                        overflow={"auto"}
                                      >
                                        <Typography
                                          sx={{ color: "#fff" }}
                                          fontSize={20}
                                          fontWeight={800}
                                          textAlign={"center"}
                                        >
                                          You have no subscription plans
                                        </Typography>
                                      </Box>
                                    )}
                                  </>
                                )}
                            </Box>
                          )}
                        </>
                      )}
                    </Grid>
                    <Grid
                      // xs={12}
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      position="sticky" // Use sticky position for the buttons
                      // top={0} // Stick to the top
                      bottom={0}
                      zIndex={2} // Ensure it stays on top of other content
                    >
                      {/* <Grid
                        xs={12}
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        position="sticky"
                        // top={0}
                        bottom={0}
                        zIndex={2}
                        margin={0}
                        padding={0}
                      > */}
                      <Tabs
                        value={activeView}
                        onChange={(event, newValue) => {
                          setView(newValue);
                          // console.log(view);
                        }}
                        // onClick={(e)=>console.log(e.target)}
                        indicatorColor="transparent" // Optional: Remove default indicator
                        // textColor="inherit" // Customize text color if needed
                        variant="fullWidth"
                        sx={{
                          display: "flex",
                          width: "100%",
                          margin: "0",
                          padding: "0",
                          border: "none",
                          // position:"sticky",
                          overflow: "hidden",
                        }}

                        // sx={{ flexGrow: 1 }} // Allow tabs to take full width
                      >
                        <Tab
                          label="Movie History"
                          value="Movie History"
                          sx={{
                            bgcolor:
                              view === "Movie History" ? "#FFDA78" : "#000",
                            color: view === "Movie History" ? "#000" : "#fff",
                            // bgcolor:"black",
                            border:
                              view === "Movie History" ? "2px solid #000" : "",
                            borderRadius:
                              view === "Movie History" ? "15px" : "",
                            padding: 0,
                            margin: 0,
                            // borderRadius: 1,
                            flexGrow: 1,
                            textAlign: "center",
                            cursor: "pointer",
                            fontFamily: "700px",
                            fontWeight: "bold",
                            //  border:"3px solid white",
                            // top:"38vh"
                          }}
                          // onClick={() => handleButtonClick("Movie History")}
                        />
                        <Tab
                          label="Subscription History"
                          value="subscription"
                          sx={{
                            bgcolor:
                              view === "subscription" ? "#FFDA78" : "#000",
                            color: view === "subscription" ? "#000" : "#fff",
                            // bgcolor:"black",
                            border:
                              view === "subscription" ? "2px solid #000" : "",
                            borderRadius: view === "subscription" ? "15px" : "",
                            padding: 0,
                            margin: 0,
                            // borderRadius: 1,
                            flexGrow: 1,
                            textAlign: "center",
                            cursor: "pointer",
                            fontFamily: "700px",
                            fontWeight: "bold",
                          }}
                          // onClick={() => handleButtonClick("subscription")}
                        />
                      </Tabs>
                      {/* </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {activeView === "bank" && (
                <Grid
                  width={{ xs: "100%", md: "50%" }}
                  p={{ xs: 3, md: 2 }}
                  pl={{ xs: 2, md: 4 }}
                  pt={0}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    mb={2}
                  >
                    <Typography
                      fontFamily={"Merriweather"}
                      fontWeight={700}
                      fontSize={{ xs: 14, sm: 30 }}
                      mr={2}
                    >
                      Bank Details
                    </Typography>
                    <BsBank fontSize={"30px"} />
                  </Box>

                  <Grid
                    xs={12}
                    bgcolor={"#000"}
                    borderRadius={4}
                    maxHeight={"300px"}
                    minHeight={{ xs: "180px", sm: "300px", md: "300px" }}
                    border={"1px solid #fff"}
                    display={"flex"}
                    // justifyContent={"center"}
                    alignItems={"center"}
                    flexDirection={"column"}
                    p={2}
                    sx={{
                      overflowY: "auto",
                      overflowX: "auto",
                      "&::-webkit-scrollbar": {
                        width: "4px", // Set your custom scrollbar width here
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#ffda78",
                        borderRadius: "2px",
                      },
                    }}
                  >
                    {isDetailsSaved && !isEditMode ? (
                      // View Mode
                      <Grid display={"flex"} justifyContent={"center"}>
                        <Box
                          width={"100%"}
                          bgcolor={"#fff"}
                          borderRadius={2}
                          p={3}
                        >
                          <Box sx={{ color: "#000", fontSize: "15px", mb: 1 }}>
                            <strong>Bank Name:</strong> {savedDetails.BankName}
                          </Box>
                          <Box sx={{ color: "#000", fontSize: "15px", mb: 1 }}>
                            <strong>Account Number:</strong>{" "}
                            {savedDetails.AccountNumber}
                          </Box>
                          <Box sx={{ color: "#000", fontSize: "15px", mb: 1 }}>
                            <strong>IFSC Code:</strong> {savedDetails.IFSCCode}
                          </Box>
                          <Box sx={{ color: "#000", fontSize: "15px", mb: 1 }}>
                            <strong>Branch:</strong> {savedDetails.Branch}
                          </Box>
                          <Box sx={{ color: "#000", fontSize: "15px", mb: 1 }}>
                            <strong>Name:</strong> {savedDetails.Name}
                          </Box>
                          <Box display={"flex"} justifyContent={"flex-end"}>
                            <Button
                              variant="outlined"
                              sx={{ mt: 2, color: "#000", fontWeight: "700" }}
                              onClick={handleEdit}
                            >
                              Edit
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    ) : (
                      <>
                        <Box ml={{}} sx={{}}>
                          <InputLabel
                            sx={{
                              color: "#F2C94C",
                              fontSize: "15px",
                              fontWeight: 600,
                            }}
                          >
                            Bank Name
                          </InputLabel>
                          {/* <TextField
                            fullWidth
                            variant="outlined"
                            name="BankName"
                            value={formValues.BankName}
                          
                            // placeholder="Select or enter a bank Name"
                            onChange={handleChange}
                            helperText="" // No helper text
                            sx={{
                              mb: 1,
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "30px",
                                backgroundColor: "white",
                                width: "28ch",
                                height: 30,
                                "& fieldset": { border: "none" },
                                "& input": {
                                  color: "black",
                                  padding: "4px 6px",
                                },
                              },
                              "& .MuiInputLabel-root": {
                                color: "#F2C94C",
                              },
                              "& .MuiFormHelperText-root": {
                                position: "absolute",
                                bottom: -20, // Adjust this value as needed
                                left: 0,
                                fontSize: "14px",
                                color: "red",
                              },
                              "& .MuiFormControl-root": {
                                position: "relative",
                              },
                            }}
                          /> */}
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="BankName"
                            value={formValues.BankName}
                            onChange={handleChange}
                            helperText="" // No helper text
                            sx={{
                              mb: 1,
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "30px",
                                backgroundColor: "white",
                                width: "28ch",
                                height: 30,
                                "& fieldset": { border: "none" },
                                "& input": {
                                  color: "black",
                                  padding: "4px 6px",
                                },
                              },
                              "& .MuiInputLabel-root": {
                                color: "#F2C94C",
                              },
                              "& .MuiFormHelperText-root": {
                                position: "absolute",
                                bottom: -20, // Adjust this value as needed
                                left: 0,
                                fontSize: "14px",
                                color: "red",
                              },
                              "& .MuiFormControl-root": {
                                position: "relative",
                              },
                            }}
                          />
                          {formErrors.BankName && (
                            <Box sx={{ color: "red", fontSize: "14px", mt: 1 }}>
                              {formErrors.BankName}
                            </Box>
                          )}

                          <InputLabel
                            sx={{
                              color: "#F2C94C",
                              fontSize: "15px",
                              fontWeight: 600,
                            }}
                          >
                            Account Number
                          </InputLabel>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="AccountNumber"
                            value={formValues.AccountNumber}
                            onChange={handleChange}
                            helperText="" // No helper text
                            sx={{
                              mb: 1,
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "30px",
                                backgroundColor: "white",
                                width: "28ch",
                                height: 30,
                                "& fieldset": { border: "none" },
                                "& input": {
                                  color: "black",
                                  padding: "4px 6px",
                                },
                              },
                              "& .MuiInputLabel-root": {
                                color: "#F2C94C",
                              },
                              "& .MuiFormHelperText-root": {
                                position: "absolute",
                                bottom: -20, // Adjust this value as needed
                                left: 0,
                                fontSize: "14px",
                                color: "red",
                              },
                              "& .MuiFormControl-root": {
                                position: "relative",
                              },
                            }}
                          />

                          {formErrors.AccountNumber && (
                            <Box sx={{ color: "red", fontSize: "14px", mt: 1 }}>
                              {formErrors.AccountNumber}
                            </Box>
                          )}
                          <InputLabel
                            sx={{
                              color: "#F2C94C",
                              fontSize: "15px",
                              fontWeight: 600,
                            }}
                          >
                            IFSC Code
                          </InputLabel>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="IFSCCode"
                            value={formValues.IFSCCode}
                            onChange={handleChange}
                            // label="Name"
                            sx={{
                              mb: 1,
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "30px",
                                backgroundColor: "white",
                                width: "28ch",
                                height: 30,
                                "& fieldset": { border: "none" },
                                "& input": {
                                  color: "black",
                                  padding: "4px 6px",
                                },
                              },
                              "& .MuiInputLabel-root": {
                                color: "#F2C94C",
                              },
                            }}
                          />
                          {formErrors.IFSCCode && (
                            <Box sx={{ color: "red", fontSize: "14px", mt: 1 }}>
                              {formErrors.IFSCCode}
                            </Box>
                          )}
                          <InputLabel
                            sx={{
                              color: "#F2C94C",
                              fontSize: "15px",
                              fontWeight: 600,
                            }}
                          >
                            Branch
                          </InputLabel>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="Branch"
                            value={formValues.Branch}
                            onChange={handleChange}
                            // label="Name"
                            sx={{
                              mb: 1,
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "30px",
                                backgroundColor: "white",
                                width: "28ch",
                                height: 30,
                                "& fieldset": { border: "none" },
                                "& input": {
                                  color: "black",
                                  padding: "4px 6px",
                                },
                              },
                              "& .MuiInputLabel-root": {
                                color: "#F2C94C",
                              },
                            }}
                          />
                          {formErrors.Branch && (
                            <Box sx={{ color: "red", fontSize: "14px", mt: 1 }}>
                              {formErrors.Branch}
                            </Box>
                          )}
                          <InputLabel
                            sx={{
                              color: "#F2C94C",
                              fontSize: "15px",
                              fontWeight: 600,
                            }}
                          >
                            Name
                          </InputLabel>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="Name"
                            value={formValues.Name}
                            onChange={handleChange}
                            // label="Name"
                            sx={{
                              mb: 1,
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "30px",
                                backgroundColor: "white",
                                width: "28ch",
                                height: 30,
                                "& fieldset": { border: "none" },
                                "& input": {
                                  color: "black",
                                  padding: "4px 6px",
                                },
                              },
                              "& .MuiInputLabel-root": {
                                color: "#F2C94C",
                              },
                            }}
                          />
                          {formErrors.Name && (
                            <Box sx={{ color: "red", fontSize: "14px", mt: 1 }}>
                              {formErrors.Name}
                            </Box>
                          )}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            gap: 2,
                            mt: 3,
                          }}
                        >
                          {isEditMode ? (
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: "#F2C94C",
                                color: "black",
                                borderRadius: "20px",
                                padding: "8px 22px",
                                fontWeight: "700",

                                "&:hover": {
                                  backgroundColor: "#e1b933",
                                },
                              }}
                              onClick={handleSave}
                            >
                              Save
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: "#F2C94C",
                                color: "black",
                                borderRadius: "20px",
                                fontWeight: "700",
                                padding: "8px 24px",
                                "&:hover": {
                                  backgroundColor: "#e1b933",
                                },
                              }}
                              onClick={handleConfrim}
                            >
                              Confrim
                            </Button>
                          )}

                          {!isEditMode ? (
                            <Button
                              variant="outlined"
                              sx={{
                                borderColor: "white",
                                color: "#000",
                                borderRadius: "20px",
                                fontWeight: "700",
                                padding: "8px 24px",
                                "&:hover": {
                                  borderColor: "#e1b933",
                                  color: "#e1b933",
                                },
                              }}
                              onClick={handleCancel}
                            >
                              Cancel
                            </Button>
                          ) : (
                            <Button
                              variant="outlined"
                              sx={{
                                borderColor: "white",
                                color: "#000",
                                borderRadius: "20px",
                                fontWeight: "700",
                                padding: "8px 24px",
                                "&:hover": {
                                  borderColor: "#e1b933",
                                  color: "#e1b933",
                                },
                              }}
                              onClick={handleback}
                            >
                              cancel
                            </Button>
                          )}
                        </Box>
                      </>
                    )}

                    {addMessage && (
                      <Box sx={{ mt: 3, textAlign: "center", color: "green" }}>
                        {addMessage}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              )}

              {activeView === "withdraw" && (
                <Grid
                  width={{ xs: "100%", md: "50%" }}
                  p={{ xs: 3, md: 2 }}
                  pl={{ xs: 2, md: 4 }}
                  pt={0}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    mb={2}
                  >
                    <Typography
                      fontFamily={"Merriweather"}
                      fontWeight={700}
                      fontSize={{ xs: 14, sm: 30 }}
                      mr={2}
                    >
                      Withdraw
                    </Typography>
                    <FaSackDollar fontSize={"30px"} />
                  </Box>
                  <Grid
                    bgcolor={"#000"}
                    borderRadius={4}
                    maxHeight={{ xs: "180px", sm: "300px", md: "300px" }}
                    border={"1px solid #fff"}
                    p={2}
                    sx={{
                      overflowY: "auto",
                      overflowX: "auto",
                      "&::-webkit-scrollbar": {
                        width: "4px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#ffda78",
                        borderRadius: "2px",
                      },
                    }}
                  >
                    {withdrawHistory.length === 0 ? (
                      <Grid
                        bgcolor={"#000"}
                        borderRadius={4}
                        minHeight={{ xs: "180px", sm: "300px", md: "250px" }}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        // border={"1px solid #fff"}
                        p={2}
                      >
                        <Typography
                          color="#fff"
                          fontWeight={700}
                          fontSize={"24px"}
                          textAlign={"center"}
                          alignItems={"center"}
                        >
                          No withdrawals found.
                        </Typography>
                      </Grid>
                    ) : (
                      withdrawHistory.map((withdrawal, index) => (
                        <Grid
                          xs={8}
                          key={index}
                          // p={2}
                          display={"flex"}
                          flexDirection={"column"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          mb={2}
                        >
                          <Box
                            bgcolor={"#fff"}
                            height={60}
                            width={"100%"}
                            borderRadius={"5px"}
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={"space-around"}
                            alignItems={"center"}
                          >
                            <Box display={"flex"} flexDirection={"column"}>
                              <Typography
                                fontFamily={"Quicksand"}
                                fontWeight={600}
                                fontSize={"16px"}
                                color={"#000"}
                              >
                                Withdraw amount
                              </Typography>
                              <Typography
                                fontFamily={"Quicksand"}
                                fontWeight={600}
                                fontSize={"12px"}
                                color={"#000"}
                              >
                                Request Status:{" "}
                                <span
                                  style={{
                                    color: getStatusColor(withdrawal.Status),
                                  }}
                                >
                                  {withdrawal.Status}
                                </span>
                              </Typography>
                            </Box>
                            <Box display={"flex"} flexDirection={"column"}>
                              <Typography
                                fontFamily={"Quicksand"}
                                textAlign={"center"}
                                fontSize={"18px"}
                                fontWeight={700}
                                color={getAmountColor(withdrawal.Status)} // Apply color based on status
                              >
                                {withdrawal.WithDrawAmmount}
                              </Typography>
                              <Typography
                                fontFamily={"Quicksand"}
                                fontSize={"8px"}
                                fontWeight={700}
                                color={"#000"}
                              >
                                {formatDate(withdrawal.WithDrawDate)}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      ))
                    )}
                  </Grid>
                </Grid>
              )}
            </Box>
          </Box>
          <Snackbar
            open={snackstatus}
            autoHideDuration={4000}
            onClose={handleCloseSnackbar}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity="warning"
              sx={{ width: "100%" }}
            >
              Something went wrong! Try after some time.
            </Alert>
          </Snackbar>
        </Grid>
        <Footer />
      </div>
    </>
  );
};

export default Wallet;
