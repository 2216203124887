import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../utils/statusCodes";
import endpoints from "../../Endpoints/endpoints"; 

const aboutUsApi = `${endpoints.aboutUs}?title=About_Us`

const initial_aboutUs = { aboutUs: {}, aboutUsFetchStatus: statusCodes.IDLE };

const aboutUsReducer = createSlice({

  name: "aboutUsReducer",
  initialState: initial_aboutUs,
  
  extraReducers: (builder) => {
    builder
      .addCase(fetchAboutUs.pending, (state, action) => {
        state.aboutUsFetchStatus = statusCodes.LOADING;
        // console.log("About Us data pending");
      })
      .addCase(fetchAboutUs.fulfilled, (state, action) => {
        state.aboutUs = action.payload.data;
        state.aboutUsFetchStatus = statusCodes.SUCCESS;
        // console.log(state.aboutUs);
      })
      .addCase(fetchAboutUs.rejected, (state, action) => {
        state.aboutUsFetchStatus = statusCodes.ERROR;
        // console.log("About Us data error");
      });
  },
});


export const fetchAboutUs = createAsyncThunk("AboutUs/get", async () => {

 
  try {
    const aboutUsData = await axios.get(aboutUsApi);
    return aboutUsData;
  } catch (error) {
    const errorPayload = {
      message: error.response.data.message,
    };
    throw errorPayload;
  }


});

export default aboutUsReducer.reducer;
