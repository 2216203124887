
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../../utils/statusCodes";
 
const searchMoviesState = {
  searchResults: [],
  searchResultsStatus: statusCodes.IDLE,
};
 
// Define the async thunk
export const fetchSearchMovies = createAsyncThunk(
 
  "searchMovies/fetch",
  async (searchJSON, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `https://api.iott.co.in/api/videos/getMoviebyName?Name=${searchJSON.searchValue}`,
        {
          headers: {
            Authorization: `Bearer ${searchJSON.authToken}`,
            'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);
 
const searchReducer = createSlice({
  name: "searchReducer",
  initialState: searchMoviesState,
  reducers: {
    clearSearchResults(state) {
      state.searchResults = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSearchMovies.pending, (state) => {
        state.searchResultsStatus = statusCodes.LOADING;
        // console.log("searchResults data pending");
      })
      .addCase(fetchSearchMovies.fulfilled, (state, action) => {
        state.searchResults = action.payload;
        state.searchResultsStatus = statusCodes.SUCCESS;
        // console.log("searchResults data fulfilled", action);
      })
      .addCase(fetchSearchMovies.rejected, (state, action) => {
        state.searchResultsStatus = statusCodes.ERROR;
        // console.log("searchResults data error", action.payload);
      });
  },
});
 
export const { clearSearchResults } = searchReducer.actions;
export default searchReducer.reducer;
 
 
 