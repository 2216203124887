import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../utils/statusCodes";
import endpoints from "../../Endpoints/endpoints";

const getAllLanguagesApi = endpoints.getAllLanguages;

const initial_banners = {
  allLanguages_banners: [],
  allLanguagesBannerFetchStatus: statusCodes.IDLE,
};

const bannersByAllLanguagesReducer = createSlice({
  name: "bannersByAllLanguagesReducer",
  initialState: initial_banners,
  extraReducers: (builder) => {
    builder
      .addCase(fetchByAllLanguagesDashboardBanners.pending, (state, action) => {
        state.allLanguagesBannerFetchStatus = statusCodes.LOADING;
        // console.log("dashboard banners by all Languages data pending");
      })
      .addCase(
        fetchByAllLanguagesDashboardBanners.fulfilled,
        (state, action) => {
          state.allLanguages_banners = action.payload.data;
          state.allLanguagesBannerFetchStatus = statusCodes.SUCCESS;
          // console.log(state.allLanguages_banners);
        }
      )
      .addCase(
        fetchByAllLanguagesDashboardBanners.rejected,
        (state, action) => {
          state.allLanguagesBannerFetchStatus = statusCodes.ERROR;
          // console.log("dashboard banners by all Languages  data error");
        }
      );
  },
});

export const fetchByAllLanguagesDashboardBanners = createAsyncThunk(
  "homeDashboardAllLanguagesBanners/get",
  async () => {
    try {
      const banners = await axios.get(getAllLanguagesApi);
      return banners;
    } catch (error) {
      const errorPayload = {
        message: error.response.data.message,
      };
      throw errorPayload;
    }
  }
);

export default bannersByAllLanguagesReducer.reducer;
