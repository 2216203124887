import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../utils/statusCodes";
import endpoints from "../../Endpoints/endpoints";

import { useNavigate, useLocation } from "react-router-dom";


// 400 invalid credentilas

// 404 api error

const AuthForgotPswdState = {
  authForgotPswd: {},
  authForgotPswdStatus: statusCodes.IDLE,
  authForgotPswdLoader: false,
};

const access_token = localStorage.getItem("token")



export const authForgotPswd = createAsyncThunk(
  "authForgotPswd/post",
  async (userLoginJSON) => {

 
   
 
    // console.log("Forgot Pswd Email Data:", userLoginJSON)
    try {
      const userData = await axios.post(
        `${endpoints.ForgotPassword}`,
        userLoginJSON, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
      );
      return userData.data;
    } catch (error) {
      const errorPayload = {
        message: error.response.data[0],
      };
      // console.log(error);
      // console.log(error.message);
       throw error; // Throwing the error payload
    }
  }
);

const authForgotPswdReducer = createSlice({
  name: "authForgotPswd_redux",
  initialState: AuthForgotPswdState,

  extraReducers: (builder) => {
    builder
      .addCase(authForgotPswd.pending, (state, action) => {
        state.authForgotPswdStatus = statusCodes.LOADING;
        state.authForgotPswdLoader = true;
      })
      .addCase(authForgotPswd.fulfilled, (state, action) => {


        // console.log("success Forgot Pswd")
      
        // console.log(action.payload);
        state.authForgotPswd = action.payload[0];
        state.authForgotPswdStatus = statusCodes.SUCCESS;
        state.authForgotPswdLoader = false;
        localStorage.setItem("isSignIn", true);

      })
      .addCase(authForgotPswd.rejected, (state, action) => {
        // console.log("forgot Paswd  Failure")
        // console.log(action.error)
        state.authDetails =   action.error;
        state.authForgotPswdStatus = statusCodes.ERROR;
        state.authForgotPswdLoader = false;
      });
  },
});

 

export default authForgotPswdReducer.reducer;


