import { fetchAccessToken } from "../../Redux/reducers/tokenReducer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Header from "../../components/Header";
import MainBanners from "../../components/MainBanners";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import HighlyRated from "../../components/HomeBannerSlider";
// import HomeSliders from "../../components/HomeSliders";
import "./index.css";
// import MovieCarousel from "../../components/MovieCarousel";
import HomeGenerSlider from "../../components/HomeGenerSlider";
import HomeCategorySlider from "../../components/HomeCategorySlider";
import Footer from "../../components/Footer";
import { Dialog } from "@mui/material";
import statusCodes from "../../utils/statusCodes";
import { getAuthData } from "../../utils/auth";
import { fetchBounsAmount } from "../../Redux/reducers/wallet/getBounsAmountReducer";
import { fetchWalletAmount } from "../../Redux/reducers/wallet/getWalletAmountReducer";
import { FaAngleUp } from "react-icons/fa";
import axios from "axios";
import { getIp } from "../../utils/getIp";
import { authForcedLogin } from "../../Redux/reducers/authForcedLoginReducer";

const LandingPage = () => {
  const dispatch = useDispatch();
  const authData = getAuthData();
  const [showButton, setShowButton] = useState(false);
  const [websiteCountStatus, setwebsiteCountStatus] = useState(false);
  const webViewCount = localStorage.getItem("web-view-count");
  const ipAddress = localStorage.getItem("ipAddress");

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const Website_Count = async () => {
    if (!websiteCountStatus) {
      try {
        const res = await axios.post(
          "https://api.iott.co.in/api/user/RecordWebsiteView",
          {
            Type: "website",
          }
        );
        // console.log(res);
        setwebsiteCountStatus(true);
        localStorage.setItem("web-view-count", true);
      } catch (err) {
        // console.log(err);
        setwebsiteCountStatus(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // const getIp = async () => {
  //   const response = await fetch("https://ipapi.co/json/");
  //   const data = await response.json();
  //   localStorage.setItem("ipAddress", data.ip);
  //   // console.log(data.ip);
  // };

  useEffect(() => {
    if (ipAddress === "" || ipAddress === null || ipAddress === undefined) {
      getIp();
    }
    if (
      webViewCount === "" ||
      webViewCount === null ||
      webViewCount === undefined
    ) {
      Website_Count();
    }
    if (authData?.auth) {
      dispatch(fetchWalletAmount(authData?.auth?.Id));
      dispatch(fetchBounsAmount(authData?.auth?.Id));
      // dispatch()
    }
  }, []);



  return (
    <>
      <Header />

      <div className="main-container">
        <Grid container minHeight={{ xs: "20vh", sm: "40vh", md: "80vh" }}>
          <Grid item xs={12}>
            <MainBanners />
          </Grid>
        </Grid>
        <Grid mt={2}>
          <HomeGenerSlider gener="Comedy" title="Comedy" />
        </Grid>

        <Grid mt={2}>
          <HomeGenerSlider gener="Horror" title="Horror" />
        </Grid>
        <Grid mt={2}>
          <HomeCategorySlider
            category="Recommendedmovies"
            title="Recommended Movies"
          />
        </Grid>
        <Grid mt={2}>
          <HomeGenerSlider gener="Romance" title="Romance" />
        </Grid>
        <Grid mt={2}>
          <HomeCategorySlider category="Trending" title="Trending" />
        </Grid>
        <Grid mt={2}>
          <HomeGenerSlider gener="Action" title="Action" />
        </Grid>
        <Grid mt={2}>
          <HomeCategorySlider
            category="HighRatedMovies"
            title="Highlyrated Movies"
          />
        </Grid>
        <Grid mt={2}>
          <HomeGenerSlider gener="Drama" title="Drama" />
        </Grid>
        <Grid mt={2}>
          <HomeCategorySlider category="Freemovies" title="Free Movies" />
        </Grid>
        <Grid mt={2}>
          <HomeCategorySlider
            category="Iottpicksforyou"
            title="Iottpicks For You"
          />
        </Grid>
        <Grid mt={2}>
          <HomeCategorySlider category="MostlyWatched" title="Mostly Watched" />
        </Grid>

        {/* GenerslidersAdded By Arjun */}

        <Grid mt={2}>
          <HomeGenerSlider gener="Sci-Fi" title="Sci-Fi" />
        </Grid>

        <Grid mt={2}>
          <HomeGenerSlider gener="Adventure" title="Adventure" />
        </Grid>

        <Grid mt={2}>
          <HomeGenerSlider gener="Thriller" title="Thriller" />
        </Grid>

        <Grid mt={2}>
          <HomeGenerSlider gener="Sports" title="Sports" />
        </Grid>

        <Grid mt={2}>
          <HomeGenerSlider gener="Family" title="Family" />
        </Grid>
        <Grid mt={2}>
          <HomeGenerSlider gener="Devotional" title="Devotional" />
        </Grid>
        <Grid mt={2}>
          <HomeCategorySlider category="Webseries" title="Webseries" />
        </Grid>
        <Grid mt={2}>
          <HomeCategorySlider category="Anime" title="Anime" />
        </Grid>

        {/* {tokenFetchStatus === statusCodes.ERROR && (
          <Dialog>Error fetching token. Please try again later.</Dialog>
        )} */}
        <Footer />

        {showButton && (
          <Grid
            container
            justifyContent="flex-end"
            alignItems="flex-end"
            alignSelf={"flex-end"}
            sx={{
              position: "fixed",
              bottom: 20,
              right: 20,
              cursor: "pointer",
              zIndex: 1000, // Ensures it's above other elements
            }}
            onClick={scrollToTop}
          >
            <Box
              display={"flex"}
              alignContent={"center"}
              justifyContent={"center"}
              sx={{ backgroundColor: "#ffda78", borderRadius: "50%" }}
            >
              <FaAngleUp size={32} color="#000" />
            </Box>
          </Grid>
        )}
      </div>
    </>
  );
};

export default LandingPage;
