import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../utils/statusCodes";
import endpoints from "../../Endpoints/endpoints";

import { useNavigate, useLocation } from "react-router-dom";



const AuthVerifyLoginState = {
  authVerifyDetails: [],
  authVerifyLoginStatus: statusCodes.IDLE,
  authVerifyLoginLoader: false,
};

const access_token = localStorage.getItem("token")



export const authVerifyLogin = createAsyncThunk(
  "authVerifyLogin/post",
  async (userLoginJSON) => {

    // let userLoginObj;
    // const userLoginJSONlength = Object.keys(userLoginJSON).length
    // // console.log(userLoginJSONlength)

    // if (userLoginJSONlength === 5) {
    //   userLoginObj = {
    //     Email: userLoginJSON.Email,
    //     Password: userLoginJSON.Password,
    //     PhoneNo: userLoginJSON.PhoneNo,
    //     DeviceName: userLoginJSON.DeviceName,
    //   }
    // }else{
    //   userLoginObj = userLoginJSON
    // }

    // console.log("Login Details:", userLoginJSON);



    // console.log("Login Data:", userLoginJSON)
    try {
      const userData = await axios.post(
        `${endpoints.VerifyUser}`,
        userLoginJSON, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
      );
      return userData.data;
    } catch (error) {
      
      // console.log(error.response.data);
      throw error; // Throwing the error payload
    }
  }
);

const authVerifyLoginReducer = createSlice({
  name: "authVerifyLogin_redux",
  initialState: AuthVerifyLoginState,

  extraReducers: (builder) => {
    builder
      .addCase(authVerifyLogin.pending, (state, action) => {
        state.authVerifyLoginStatus = statusCodes.LOADING;
        state.authVerifyLoginLoader = true;
      })
      .addCase(authVerifyLogin.fulfilled, (state, action) => {


        // console.log("success Login verify")
        // console.log(action.payload);
        state.authVerifyDetails = action.payload;
        state.authVerifyLoginStatus = statusCodes.SUCCESS;
        state.authVerifyLoginLoader = false;
        localStorage.setItem("isSignIn", true);



      })
      .addCase(authVerifyLogin.rejected, (state, action) => {
        // console.log("Login Failure verify")
        state.authVerifyDetails = action.error;
        state.authVerifyLoginStatus = statusCodes.ERROR;
        state.authVerifyLoginLoader = false;
      });
  },
});

 

export default authVerifyLoginReducer.reducer;


