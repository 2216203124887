export const getAuthData = () => {
  const auth = JSON.parse(localStorage.getItem("loginUser"));
  const authToken = JSON.parse(localStorage.getItem("iottToken"));
  if (!auth) {
    logoutUser();
    return null;
  }
  return { auth, authToken };
};

export const logoutUser = () => {
  localStorage.removeItem("loginUser");
  localStorage.removeItem("web-view-count");
  // window.location.href = "/login";
};
