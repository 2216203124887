import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import statusCodes from "../../utils/statusCodes";
import endpoints from "../../Endpoints/endpoints";

const allVideosApi = endpoints.getAllVideos;

const initial_allVideos = {
  allVideos: [],
  allVideosFetchStatus: statusCodes.IDLE,
};

const allVideosReducer = createSlice({
  name: "allVideosReducer",
  initialState: initial_allVideos,
  extraReducers: (builder) => {
    builder
      .addCase(fetchallVideos.pending, (state, action) => {
        state.allVideosFetchStatus = statusCodes.LOADING;
        // console.log("allVideos Data pending");
      })
      .addCase(fetchallVideos.fulfilled, (state, action) => {
        state.allVideos = action.payload.data;
        state.allVideosFetchStatus = statusCodes.SUCCESS;
        // console.log(state.allVideos);
      })
      .addCase(fetchallVideos.rejected, (state, action) => {
        state.allVideosFetchStatus = statusCodes.ERROR;
        // console.log("allVideos  data error");
      });
  },
});

export const fetchallVideos = createAsyncThunk("allVideoss/get", async () => {
  try {
    const allVideosData = await axios.get(allVideosApi);
    console.log(allVideosData)
    return allVideosData;
  } catch (error) {
    const errorPayload = {
      message: error.response.data.message,
    };
    throw errorPayload;
  }
});

export default allVideosReducer.reducer;
