import React, { useEffect } from "react";
import Slider from "react-slick";
import Grid from "@mui/material/Grid";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeDashboardBanners } from "../../Redux/reducers/dashboardBannersReducer";

const HomeBannerSlider = ({ onClickHomeBannerSliderImg }) => {
  const dispatch = useDispatch();
  const { dashboard_banners } = useSelector(
    (state) => state.rootReducer.dashboardBannersReducer
  );

  useEffect(() => {
    dispatch(fetchHomeDashboardBanners());
  }, [dispatch]);

  let slidesToShow = 4;

  const SampleNextArrow = (props) => {
    const { className, style, onClick, currentSlide, slideCount } = props;
    return (
      currentSlide !== slideCount - slidesToShow && (
        <div
          className={className}
          style={{
            ...style,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#151829",
            height: "84%",
            width: "20px",
            fontSize: "12px",
            color: "#fff",
            borderRadius: "3px",
            marginRight: "20px !important",
          }}
          onClick={onClick}
        >
          <ArrowForwardIosIcon style={{ fontSize: "14px" }} />
        </div>
      )
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick, currentSlide } = props;
    return (
      currentSlide !== 0 && (
        <div
          className={className}
          style={{
            ...style,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#151829",
            height: "84%",
            width: "20px",
            fontSize: "12px",
            color: "#fff",
            borderRadius: "4px",
          }}
          onClick={onClick}
        >
          <ArrowBackIosNewIcon style={{ fontSize: "14px" }} />
        </div>
      )
    );
  };

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    infinite: false,
  };

  const onClickImg = (movieDetails) => {
    onClickHomeBannerSliderImg(movieDetails);
  };

  return (
    <Grid item width={"50%"} display={{ xs: "none", md: "block" }}>
      <Slider {...settings}>
        {dashboard_banners.map((each, index) => {
          return (
            <div key={index} className="image-cont">
              <div className="short-cont">
                <img
                  src={each.URL}
                  className="slide-image"
                  alt={index}
                  onClick={() => onClickImg(each)}
                />
              </div>
            </div>
          );
        })}
      </Slider>
    </Grid>
  );
};

export default HomeBannerSlider;
