import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import "./index.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getAuthData } from "../../utils/auth";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { getAllLanguageFunction } from "../../Redux/reducers/subscription/getAllLanguageReducer";
import endpoints from "../../Endpoints/endpoints";
import { subscriptionCheckFunction } from "../../Redux/reducers/subscription/checkSubscriptionReducer";
import getWalletAmountReducer, {
  fetchWalletAmount,
} from "../../Redux/reducers/wallet/getWalletAmountReducer";
import { fetchSubscriptions } from "../../Redux/reducers/mySubscriptionsReducer";
import Check from "./Check";
import MySubscriptions from "./MySubscriptions";
import { subscribeUser } from "../../Redux/reducers/subscription/subscribeUserReducer";
import { purchaseSubscriptionPlan } from "../../Redux/reducers/subscription/purchaseSubscriptionReducer";
import { red } from "@mui/material/colors";
 
 
 
export const SubscriptionCard = ({
  plan,
  checkWalletAmount,
  // clickingFunction,
  purchaseStatus,
}) => {
  const authData = getAuthData();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
 
 
 
 
 
  // const mySubscriptionsValues = location.state;
  // console.log(mySubscriptionsValues);
  // console.log(plan);
 
  // const [SubscriptionPlanStatus, setSubscriptionPlanStatus] = useState(false);
 
  const planId = plan?.Id;
 
  const { wallet_amount } = useSelector(
    (state) => state.rootReducer.getWalletAmountReducer
  );
 
  useEffect(() => {
    dispatch(subscriptionCheckFunction(authData?.auth?.Id));
  }, [plan, purchaseStatus]);
 
  const checkSubscriptionStatus = (plan) => {
    // clickingFunction();
    let user = authData?.auth ? authData?.auth?.Id : null;
 
    if (user === null || user === undefined) {
      navigate("/Login");
    } else {
      checkWalletAmount(plan);
    }
  };
 
  return (
    <Card
      sx={{
        backgroundColor: "#fff",
        color: "black",
        borderRadius: 2,
        maxHeight: 420,
        border: "5px solid 'rgba(1, 1, 19, 0.8)'",
        "&:hover": {
          borderColor: "#FE9B06",
          border: "5px solid #FE9B06 ",
 
          borderColor: "#FE9B06",
        },
        width:{xs:'100%'}
      }} 
      className="subscription-card"
    >
      <Box
        borderRadius={1}
        bgcolor={"#FFDA78"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        margin={"auto"}
        height={40}
        flexDirection={"row"}
        mb={3}
        sx={{ width: { xs: "50%" } }}
      >
        <Typography gutterBottom fontFamily={"Quicksand"} fontWeight={700}>
          {plan?.SubscriptionPlan}
        </Typography>
      </Box>
      <CardContent>
        <Box display={"flex"} justifyContent={"center"}>
          <Typography
            variant="h4"
            gutterBottom
            fontFamily={"Quicksand"}
            fontWeight={700}
          >
            ₹ {plan?.Cost}{" "}
            <span
              style={{
                textDecoration: "line-through",
                fontFamily: "Quicksand",
                fontSize: "0.45em",
                fontWeight: 500,
              }}
            >
              {plan?.ActualCost}
            </span>
          </Typography>
        </Box>
        <Typography
          variant="body2"
          gutterBottom
          fontFamily="Quicksand"
          fontWeight={600}
          textAlign={"center"}
          width={{ xs: '100%', sm: "auto" }}
          boxShadow={"content-box"}
        >
          {/* <ul > */}
          <li style={{ textAlign: "center", padding: 1 }}>
            {plan?.Description}
          </li>
          {/* </ul> */}
        </Typography>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
        >
          <Button
            variant="contained"
            // onClick={checkSubscriptionStatus}
            onClick={() => {
              checkSubscriptionStatus(plan); // Call this function
            }}
            sx={{
              mt: 2,
              fontSize: { xs: 13, sm: 10 },
              fontFamily: "Quicksand",
              fontWeight: 600,
              bgcolor: "#FFDA78",
              color: "#000000",
              "&:hover": {
                backgroundColor: "red",
              },
              borderRadius: 1,
            }}
          >
            Get Subscription
          </Button>
          {/* {SubscriptionPlanStatus && (
            <Typography
              variant="body2"
              textAlign={"center"}
              color={"#000"}
              fontWeight={"bold"}
              pt={1}
            >
              {" "}
              subscribed already!
            </Typography>
          )} */}
        </Box>
      </CardContent>
    </Card>
  );
};
//const ccav = require("../Wallet/ccavutil");
 
const Subscription = () => {
  // window.scrollTo(0, 0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authData = getAuthData();
  const location=useLocation();
 
 
 
 
 
 
  const [language, setLanguage] = useState("Telugu");
  const [loading, setLoading] = useState(true);
  const [presentSub, setPresentSub] = useState("");
 
  // const [OrderId, setOrderId] = useState("");
  const [singleLanguagePlans, setsingleLanguagePlans] = useState([]);
  const [allLanguagePlans, setallLanguagePlans] = useState([]);
  const [openErrorDialog, setOpenErrorDialog] = useState(false); // State for error dialog
  const [purchaseStatus, setpurchaseStatus] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
 
  // this is for useEffect only
  const [clicking, updateClicking] = useState(false);
 
  const { allLanguage } = useSelector(
    (state) => state.rootReducer.getAllLanguageReducer
  );
 
  const { wallet_amount } = useSelector(
    (state) => state.rootReducer.getWalletAmountReducer
  );
 
  const { bouns_amount } = useSelector(
    (state) => state.rootReducer.getBounsAmountReducer
  );
 
  useEffect(() => {
    fetchPlans();
 
    if (allLanguage.length < 1) {
      dispatch(getAllLanguageFunction());
    }
  }, [language]);
 
  const { subscriptions } = useSelector(
    (state) => state.rootReducer.subscriptionsReducer
  );
 
  // console.log(subscriptions);
 
 
  // const { status } = useSelector(
  //   (state) => state.rootReducer.subscribeUserReducer
  // );
  // // console.log(status);
 
  const { subscribeddata, checkIsSubscriptionStatus } = useSelector(
    (state) => state.rootReducer.subscriptionCheckFunction
  );
 
  // const { presentpurchaseStatus } = useSelector(
  //   (state) => state.rootReducer.purchaseSubscriptionReducer
  // );
  function handleNavigateWallet() {
    navigate("/wallet");
  }
  // console.log(subscribeddata);
 
  useEffect(() => {
    fetchPlans();
 
    if (allLanguage.length < 1) {
      dispatch(getAllLanguageFunction());
    }
  }, [language]);
 
  useEffect(() => {
    dispatch(fetchWalletAmount(authData?.auth?.Id));
    dispatch(fetchSubscriptions(authData?.auth?.Id));
  }, [purchaseStatus]);
 
  const handleChange = (event) => {
    setLanguage(event.target.value);
  };
 
  const handleClickToOpen = () => {
    setDialogOpen(true);
  };
 
  const handleToClose = () => {
    setDialogOpen(false);
  };
 
  const handlePurchasePlan = async (plan) => {
    const UserId = authData?.auth?.Id;
    const Plan = plan;
    const PlanId = plan.Id;
    const WalletAmount = wallet_amount;
    const BonusAmount = 0;
 
    const UserDetails = {
      userId: UserId,
      planId: PlanId,
      plan: Plan,
      wallet_amount: WalletAmount,
      bonus_amount: BonusAmount,
    };
 
    try {
      await dispatch(purchaseSubscriptionPlan(UserDetails)).unwrap();
      await dispatch(subscribeUser(UserDetails)).unwrap();
 
      // Fetch updated subscriptions after successful purchase
      dispatch(fetchSubscriptions(UserId));
 
      // Update the purchase status message
      // console.log("Subscription purchased successfully");
      setpurchaseStatus("Subscription Purchased successfully");
      // navigate('my_subscriptions', { state: { checkWalletAmount } });
 
      // navigate('my_subscriptions')
    } catch (error) {
      console.error("Error purchasing subscription:", error);
      setpurchaseStatus("Failed to purchase subscription");
    }
  };
 
  // const handlePurchasePlan = async (plan) => {
  //   const UserId = authData?.auth?.Id;
  //   const Plan = plan;
  //   const PlanId = plan.Id;
  //   const WalletAmount = wallet_amount;
  //   const BonusAmount = 0;
 
  //   const UserDetails = {
  //     userId: UserId,
  //     planId: PlanId,
  //     plan: Plan,
  //     wallet_amount: WalletAmount,
  //     bonus_amount: BonusAmount,
  //   };
 
  //   dispatch(purchaseSubscriptionPlan(UserDetails));
  //   dispatch(subscribeUser(UserDetails));
  //   dispatch(fetchSubscriptions(authData?.auth?.Id));
  //   if (presentpurchaseStatus === "Subscription Purchased succesfully") {
  //     console.log('entr SubscribeUser')
  //     setpurchaseStatus("Subscription Purchased succesfully");
  //   }
  // };
 
  const handleCheckSubscription = (plan) => {
    handlePurchasePlan(plan);
  };
 
  // const handleUseEfect = () => {
  //   updateClicking(true);
  // };
 
  const checkWalletAmount = (plan) => {
 
 
    // console.log('checking this che')
    const userId = authData.auth.Id;
    const totalAmount = wallet_amount;
    let isSubscribedValid = false;
 
    if (checkIsSubscriptionStatus === "User is not subscribed.") {
      isSubscribedValid = false;
    } else {
      setOpenErrorDialog(true);
      setpurchaseStatus("Subscribing to Plan... Wait a moment !");
      const isSubscribedLanguage = subscribeddata.some((subscription) => {
        const isLanguageMatch =
          subscription.Language === plan.Language &&
          subscription.SubscriptionId === plan.Id;
        const isActive = subscription.IsSubscribed;
        return isLanguageMatch && isActive;
      });
 
      isSubscribedValid = isSubscribedLanguage;
    }
 
    if (totalAmount >= plan.Cost && !isSubscribedValid) {
      handleCheckSubscription(plan);
    } else if (isSubscribedValid) {
      setOpenErrorDialog(true);
      setpurchaseStatus("Subscribed Already");
    } else {
      handleClickToOpen();
      setOpenErrorDialog(false);
    }
  };
 
  const fetchPlans = async () => {
    try {
      const responseSingle = await axios.get(
        `https://api.iott.co.in/api/videos/GetSubscriptionPlans?language=${language}`
      );
      const dataSingle = responseSingle.data;
      setsingleLanguagePlans(dataSingle);
      // console.log(dataSingle);
      setLoading(false);
    } catch (error) {
      // console.error("Error fetching single language plans:", error);
      setsingleLanguagePlans([]);
      setLoading(false);
    }
 
    try {
      const responseAll = await axios.get(
        `https://api.iott.co.in/api/videos/GetSubscriptionPlans?language=alllanguages`
      );
      const dataAll = responseAll.data;
      setallLanguagePlans(dataAll);
      setLoading(false);
    } catch (error) {
      // console.error("Error fetching all language plans:", error);
      setallLanguagePlans([]);
      setLoading(false);
    }
  };
 
  return (
    <Box bgcolor={"rgba(1, 1, 19, 0.8)"}>
      <Header />
      <Box
        sx={{
          color: "white",
          p: 2,
        }}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          color={"#FFDA78"}
          fontFamily={"Quicksand"}
          fontWeight={800}
        >
          Subscription Plans
        </Typography>
 
        <Grid container spacing={4}>
          <Grid
            xs={12}
            display={"flex"}
            flexDirection={"column"}
            gap={5}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid item xs={10} md={11} lg={8} pt={6}>
              <Grid
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                sx={{
                  justifyContent: { xs: "none", sm: "space-between" },
                  flexDirection: { xs: "column", sm: "row " },
                  gap: { xs: 2, sm: 10 },
                }}
              >
                <Box sx={{ display: { xs: "none", sm: "block" } }}>
                  <Typography
                    sx={{ fontSize: { xs: "15px", sm: "20px", md: "25px" } }}
                    gutterBottom
                    textAlign={"left"}
                    color={"#FFDA78"}
                    fontFamily={"Quicksand"}
                    fontWeight={700}
                    pb={3}
                  >
                    Single Language Plans
                  </Typography>
                </Box>
 
                {allLanguage.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: { xs: "center", sm: "flex-end" },
                      mb: { xs: 0, sm: 4 },
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Typography
                      fontFamily={"Quicksand"}
                      fontWeight={400}
                      sx={{ fontSize: { xs: "13px", sm: "18px", md: "20px" } }}
                    >
                      Selected Languge :
                    </Typography>
 
                    <FormControl sx={{ width: { xs: "25vw", sm: "15vw" } }}>
                      <Select
                        value={language}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          bgcolor: "#FFDA78",
                          borderRadius: 2,
                          height: {
                            xs: "5vh",
                            md: "5vh",
                            lg: "6vh",
                          },
 
                          width: { xs: "35vw", sm: "15vw" },
                        }}
                      >
                        {allLanguage.map((each, index) => (
                          <MenuItem
                            key={index}
                            value={each.Language}
                            sx={{
                              "&:hover": {
                                backgroundColor: "#FFDA78",
                                color: "#000",
                              },
                            }}
                          >
                            {each.Language}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                )}
 
                <Box sx={{ display: { xs: "block", sm: "none" } }}>
                  <Typography
                    // variant="h5"
                    gutterBottom
                    textAlign={"left"}
                    color={"#FFDA78"}
                    fontFamily={"Quicksand"}
                    fontWeight={700}
                    pb={3}
                    pl={2}
                  >
                    Single Language Plans
                  </Typography>
                </Box>
              </Grid>
 
              {loading ? (
                <Grid
                  item
                  minHeight={"40vh"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <CircularProgress color="warning" />
                </Grid>
              ) : (
                <Grid container spacing={6}>
                  {singleLanguagePlans?.length > 0 ? (
                    singleLanguagePlans?.map((plan, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"center"}
                        sx={{ ms: { xs: 5, sm: 0 } }}
                        key={index} 
                      >
                        {/* {console.log()} */}
                        <SubscriptionCard
                          plan={plan}
                          checkWalletAmount={checkWalletAmount}
                          purchaseStatus={purchaseStatus}
                          // clickingFunction={handleUseEfect}
                        />
                      </Grid>
                    ))
                  ) : (
                    <Grid
                      container
                      xs={10}
                      minHeight={"20vh"}
                      minWidth={"100%"}
                      display={"flex"}
                      justifyContent={"center"}
                      alignContent={"center"}
                    >
                      <Typography
                        pt={6}
                        fontFamily={"Quicksand"}
                        fontWeight={700}
                        fontSize={{xs:'12px',sm:'18px'}}
                        color={"red"}
                      >
                        Error fetching single language plans.
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
 
            <Grid item xs={12} sm={10} md={11} lg={8} pt={2}>
              <Grid>
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: { xs: "15px", sm: "20px", md: "25px" },
                    pl: { xs: 4, sm: 0 },
                  }}
                  // sx={{
                  //   fontSize: { xs: "13px", sm: "18px", md: "2px" },
 
                  // }}
                  color={"#FFDA78"}
                  fontFamily={"Quicksand"}
                  fontWeight={700}
                  pb={3}
                >
                  All Languages Plans
                </Typography>
              </Grid>
 
              {loading ? (
                <Grid
                  container
                  minHeight={"40vh"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <CircularProgress color="warning" />
                </Grid>
              ) : (
                <Grid container spacing={6}>
                  {allLanguagePlans.length > 0 ? (
                    allLanguagePlans.map((plans, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        sx={{ ml: { xs: 6, sm: 0 }, mr: { xs: 3, sm: 0 } }}
                        key={index}
                      >
                        <SubscriptionCard
                          plan={plans}
                          checkWalletAmount={checkWalletAmount}
                          // clickingFunction={handleUseEfect}
                          purchaseStatus={purchaseStatus}
                        />
                      </Grid>
                    ))
                  ) : (
                    <Grid minHeight={"20vh"} alignContent={"center"}>
                      <Typography
                        pt={6}
                        fontFamily={"Quicksand"}
                        fontWeight={700}
                        fontSize={{xs:'12px',sm:'18px'}}
                        color={"red"}
                      >
                        Error fetching all languages plans.
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
 
        <Dialog
          open={openErrorDialog}
          onClose={() => setOpenErrorDialog(false)}
          aria-labelledby="error-dialog-title"
          aria-describedby="error-dialog-description"
        >
          <DialogTitle
            id="error-dialog-title"
            sx={{
              fontFamily: "Quicksand",
              fontWeight: 400,
              fontSize: 24,
              color: "#000000",
              textAlign: "center",
            }}
          >
            Subscription Status
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="error-dialog-description"
              sx={{
                fontFamily: "Quicksand",
                fontWeight: 700,
                fontSize: 20,
                textAlign: "center",
                color: "green",
              }}
            >
              {purchaseStatus}
              {/* {console.log(purchaseStatus)} */}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenErrorDialog(false)}
              sx={{ borderRadius: 1, fontWeight: 500 }}
            >
              Close
            </Button>
            {/* {purchaseStatus == "Subscription Purchased succesfully" && ( */}
              <Button
 
                // onClick={() => {
                //   setOpenErrorDialog(false);
                //   navigate("/my_subscriptions",state: {repeatPlan},
                //     });
                // }}
 
 
                onClick={()=>{navigate("/my_subscriptions")}}
             
               
                sx={{ borderRadius: 1, fontWeight: 500 }}
              >
                myplans
              </Button>
            {/* )} */}
          </DialogActions>
        </Dialog>
 
        {/* // Dialog added here for Payment // */}
 
        <Dialog
          open={dialogOpen}
          onClose={handleToClose}
          maxWidth={{ xs: "sm", sm: "md" }}
        >
          <DialogContent>
            <Box p={3}>
              <Typography
                fontFamily={"Quicksand"}
                fontWeight={700}
                fontSize={23}
                gutterBottom
              >
                Insufficient Balance in your Wallet!
              </Typography>

              <Grid 
  container 
  sx={{
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection:'column',
    backgroundColor: '#FFDA78',
    padding: '20px 10px',
    borderRadius:'10px'
    // justifyContent:'center',
    // alignItems:'center'
    
  }}
>
  <Typography
    sx={{
      fontFamily: 'Quicksand',
      fontWeight: 600,
      fontSize: 20,
      textAlign: 'center',
      padding: '5px ',
      marginBottom: '16px',
      backgroundColor: '#ffff',
      borderRadius:'10px',

    }}
  >
    Wallet Balance: {wallet_amount ? wallet_amount : 0}
  </Typography>

  <Typography
    sx={{
      fontFamily: 'Quicksand',
      fontWeight: 600,
      fontSize: 20,
      textAlign: 'center',
      padding: '5px',
      marginBottom: '16px',
      backgroundColor: '#ffff',
      borderRadius:'10px',

 
    }}
  >
    Bonus Balance: {bouns_amount ? bouns_amount.BonusAmount : 0}
  </Typography>
</Grid>


              {/* <Grid container 
              backgroundColor={red}
              padding={10}
              >


              <Typography
                fontFamily={"Quicksand"}
                fontWeight={600}
                fontSize={20}
                align="center"
                gutterBottom
                >
                Wallet Balance: {wallet_amount ? wallet_amount : 0}
              </Typography>
              <Typography
                fontFamily={"Quicksand"}
                fontWeight={600}
                fontSize={20}
                align="center"
                gutterBottom
                >
                Bonus Balance: {bouns_amount ? bouns_amount.BonusAmount : 0}
              </Typography>

                </ Grid> */}

              <Grid container justifyContent="space-around" mt={2}>
                <Grid item>
                  <Button
                    onClick={handleNavigateWallet}
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: 13,
                      borderRadius: 2,
                      fontWeight: 600,
                      fontFamily: "Quicksand",
                    }}
                    autoFocus
                  >
                    + Add Money
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={handleToClose}
                    variant="outlined"
                    color="secondary"
                    sx={{
                      fontSize: 13,
                      borderRadius: 2,
                      fontWeight: 600,
                      fontFamily: "Quicksand",
                    }}
                    autoFocus
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </Dialog>
      </Box> 
      <Footer />
    </Box>
  );
};
 
export default Subscription;
 
 