import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  movieType: "",
  seasonNo: null,
  episodeNo: null,
};

const playerStateReducer = createSlice({
  name: "player",
  initialState,
  reducers: {
    setPlayerState(state, action) {
      return { ...state, ...action.payload };
    },
    resetPlayerState(state) {
      return initialState;
    },
  },
});

export const { setPlayerState, resetPlayerState } = playerStateReducer.actions;
export default playerStateReducer.reducer;
